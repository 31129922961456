import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IModalShareController} from "views/components/modals/modal_share/modal_share.controller";
import {ReactComponent as ModalIcon} from "assets/img/icons/modalGreen.svg";
import {
	ModalBase,
	ModalDesc,
	ModalTitle,
} from "views/components/modals/modal_base/modal_base.component";
import styled from "@emotion/styled";
import {Exist} from "views/components/exist/exist.component";
import {Button, Stack} from "@mui/material";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 18px;
	max-width: 380px;
	width: 100%;
`;

const SModalTitle = styled(ModalTitle)`
	margin-bottom: 6px;
`;

export const ModalShare: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, shareViaFB, shareViaTW, close} =
		useViewController<IModalShareController>(Bindings.ModalShareController);

	return (
		<ModalBase isOpen={isOpen} closeHandler={close} className="confirm-modal">
			<Container>
				<ModalIcon />

				<div>
					<SModalTitle>
						{i18n.t(modalContent?.title ?? "modal.share.title", "Share")}
					</SModalTitle>

					<Exist when={modalContent?.message}>
						<ModalDesc>{modalContent?.message}</ModalDesc>
					</Exist>
				</div>

				<Stack direction="row" justifyContent="center" gap={3} width="100%">
					<Button fullWidth variant="contained" onClick={shareViaFB}>
						{i18n.t("modal.share.facebook", "Facebook")}
					</Button>
					<Button fullWidth variant="contained" onClick={shareViaTW}>
						{i18n.t("modal.share.twitter", "Twitter")}
					</Button>
				</Stack>
			</Container>
		</ModalBase>
	);
});
