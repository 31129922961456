import React from "react";
import {observer} from "mobx-react";
import {Modal} from "@mui/material";
import Slider, {Settings} from "react-slick";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {IModalGameStatsController} from "./modal_game_stats.controller";
import {GameStatsCard} from "./game_stats_card/game_stats_card.component";

const ModalContainer = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	outline: none;
	max-width: 600px;
	width: 100%;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}

	@media screen and (max-width: 425px) {
		left: 8px;
		right: 8px;
	}
`;

const ModalContent = styled.div`
	position: relative;
	width: 100%;
`;

const Dots = styled.ul`
	li {
		border-radius: 50%;
		background: ${({theme}) => theme.palette.tournamentStatsModal.dots};
		height: 15px;
		width: 15px;
		margin: 0 7px;

		&.slick-active {
			background: ${({theme}) => theme.palette.tournamentStatsModal.activeDots};
		}

		button:before {
			content: none;
		}
	}
`;

const settings: Settings = {
	dots: true,
	infinite: false,
	arrows: false,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	appendDots: (dots) => (
		<div>
			<Dots> {dots} </Dots>
		</div>
	),
};

export const ModalGameStats: React.FC = observer(() => {
	const {isOpen, close, sliderRef, tournaments, afterChange, initialIndex} =
		useViewController<IModalGameStatsController>(Bindings.ModalGameStatsController);

	return (
		<Modal open={isOpen} onClose={close}>
			<ModalContainer>
				<ModalContent>
					<Slider
						{...settings}
						ref={sliderRef}
						afterChange={afterChange}
						initialSlide={initialIndex}>
						{tournaments.map((tournament) => (
							<GameStatsCard
								key={tournament.id}
								tournament={tournament}
								closeHandler={close}
							/>
						))}
					</Slider>
				</ModalContent>
			</ModalContainer>
		</Modal>
	);
});
