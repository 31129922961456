import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {ITournament, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";

export interface ITipsController extends ViewController {
	readonly i18n: ILocalizationStore;

	get tournaments(): ITournament[];
}

@injectable()
export class TipsController implements ITipsController {
	get tournaments() {
		return this._roundsStore.selectedRound?.tournaments || [];
	}

	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}
}
