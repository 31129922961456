import React, {FC, Fragment} from "react";
import {Outlet} from "react-router-dom";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {footerLinks} from "data/constants/navigation";
import {Header} from "views/components/header/header/header.component";
import {Footer} from "views/components/footer/footer.component";
import {ITippingLayoutController} from "./tipping_layout.controller";
import {ReactComponent as LogoIcon} from "assets/img/nrlwTipping.svg";
import styled from "@emotion/styled";

export const StyledLogoIcon = styled(LogoIcon)`
	width: 110px;
	height: 32px;

	path {
		fill: #ffffff;
	}
`;

export const TippingLayout: FC = observer(() => {
	const {subNavItems, burgerNavItems} = useViewController<ITippingLayoutController>(
		Bindings.TippingLayoutController
	);

	return (
		<Fragment>
			<Header
				LogoIcon={StyledLogoIcon}
				subNavItems={subNavItems}
				burgerNavItems={burgerNavItems}
			/>
			<Outlet />
			<Footer links={footerLinks} />
		</Fragment>
	);
});
