import {get} from "lodash";
import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type ISquadsStore} from "data/stores/squads/squads.store";
import {SquadMatchResult} from "data/enums";
import {type ITournamentStatsStore} from "data/stores/tournament_stats/tournament_stats.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type ITournamentStore} from "data/stores/tournament/tournament.store";

interface IProps {
	tournamentId: number;
}

export interface IFormGuideController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get homeColor(): string;
	get awayColor(): string;
	get isShowFormGuide(): boolean;
	get awayRecentList(): SquadMatchResult[];
	get homeRecentList(): SquadMatchResult[];
}

@injectable()
export class FormGuideController implements IFormGuideController {
	@observable private _tournamentId: number = 0;

	private get stats() {
		return this._tournamentStatsStore.getTournamentStats(this._tournamentId);
	}

	get tournament() {
		return this._tournamentStore.getTournamentById(this._tournamentId);
	}

	get awayRecentList() {
		return this.stats?.away.formList || [];
	}

	get homeRecentList() {
		return this.stats?.home.formList || [];
	}

	get homeColor() {
		const id = get(this.tournament, "homeSquadId", 0);
		return this._squadsStore.getSquadById(id)?.color || "#80004d";
	}

	get awayColor() {
		const id = get(this.tournament, "awaySquadId", 0);
		return this._squadsStore.getSquadById(id)?.color || "#048245";
	}

	get isShowFormGuide() {
		return Boolean(this.homeRecentList.length && this.awayRecentList.length);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.TournamentStatsStore) private _tournamentStatsStore: ITournamentStatsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.TournamentStore) private _tournamentStore: ITournamentStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this._tournamentId = param.tournamentId;
	}
}
