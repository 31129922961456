import {orderBy} from "lodash";
import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {ChangeEvent} from "react";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {IRound, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type ILeagueRankingsStore} from "data/stores/league_rankings/league_rankings.store";
import {MatchStatus} from "data/enums";

interface IProps {
	isMobile: boolean;
	startRoundId: number;
}

export interface ILeagueTableFiltersController extends ViewController<IProps> {
	i18n: ILocalizationStore;

	get rounds(): IRound[];
	get isShowViewTipsSwitch(): boolean;
	get viewTipsSwitchVal(): boolean;

	changeViewTipsHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class LeagueTableFiltersController implements ILeagueTableFiltersController {
	@observable private _isMobile = false;
	@observable private _startRoundId = 1;

	private filterRounds(rounds: IRound[]) {
		return rounds.filter(({id}) => id >= this._startRoundId);
	}

	get rounds() {
		if (this.viewTipsSwitchVal) {
			return this.filterRounds(this._roundsStore.viewTipsRounds);
		}

		return this.filterRounds(orderBy(this._roundsStore.completedRounds, "id", "desc"));
	}

	get isShowViewTipsSwitch() {
		const isFinishAnyTournament = this.filterRounds(this._roundsStore.viewTipsRounds).some(
			({tournaments}) => tournaments.some(({status}) => status === MatchStatus.Complete)
		);

		if (!isFinishAnyTournament) {
			return false;
		}

		return !this._isMobile;
	}

	get viewTipsSwitchVal() {
		return this._leagueRankingsStore.isViewTips;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.LeagueRankingsStore) private _leagueRankingsStore: ILeagueRankingsStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this.onChange(param);
	}

	onChange(param: IProps) {
		this._isMobile = param.isMobile;
		this._startRoundId = param.startRoundId;
	}

	changeViewTipsHandler = (e: ChangeEvent<HTMLInputElement>) => {
		this._leagueRankingsStore.updateViewTips(e.target.checked);
	};
}
