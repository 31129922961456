import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IJSONProvider} from "data/providers/json/json.provider";
import {type IUserStore} from "data/stores/user/user.store";
import {IPrediction, type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {type ITournamentStore} from "data/stores/tournament/tournament.store";

export enum SportsbetType {
	Logo = "logo",
	MultibetCalculator = "multibetCalculator",
	MatchOdds = "matchOdds",
	AutoPick = "autoPick",
}

export interface ISportsbet {
	[SportsbetType.Logo]: boolean;
	[SportsbetType.MultibetCalculator]: boolean;
	[SportsbetType.MatchOdds]: boolean;
	[SportsbetType.AutoPick]: boolean;
}

export interface ISportsbetStore {
	get calculatorValue(): number;
	get integrationData(): ISportsbet;

	getCalculatorValueByPrediction: (prediction: IPrediction[]) => number;
	getIsShow: (type: SportsbetType) => boolean;
	fetch: () => Promise<void>;
}

@injectable()
export class SportsbetStore implements ISportsbetStore {
	@observable private _data: ISportsbet = {
		logo: false,
		matchOdds: false,
		multibetCalculator: false,
		autoPick: false,
	};

	get integrationData() {
		return this._data;
	}

	get calculatorValue() {
		return this.getCalculatorValueByPrediction(this._predictionsStore.predictions);
	}

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.TournamentStore) private _tournamentStore: ITournamentStore
	) {
		makeAutoObservable(this);
	}

	getIsShow(type: SportsbetType) {
		const user = this._userStore.user;

		// If there is no user, then show Betting depending on the data from BE
		if (!user) {
			return this._data[type];
		}

		return Boolean(user.isBettingVisible) && this._data[type];
	}

	fetch = async () => {
		const {data} = await this._jsonProvider.sportsbet();

		runInAction(() => {
			this._data = data;
		});
	};

	getCalculatorValueByPrediction = (predictions: IPrediction[]) => {
		return predictions.reduce((acc, {tournamentId, squadId}) => {
			const tournament = this._tournamentStore.getTournamentById(tournamentId);
			const isHome = tournament?.homeSquadId === squadId;

			if (!tournament || !tournament.homeOdds || !tournament.awayOdds) {
				return acc;
			}

			let price;

			if (isHome) {
				price = tournament.homeOdds.price;
			} else {
				price = tournament.awayOdds.price;
			}

			if (acc) {
				return acc * price;
			}

			return price;
		}, 0);
	};
}
