import {forEach} from "lodash";

export const toFormData = (data: Record<string, number | string | boolean | Blob>) => {
	const form_data = new FormData();

	forEach(data, (value, key) => {
		if (typeof value === "boolean") {
			form_data.set(key, value ? "1" : "0");
		} else {
			form_data.set(key, value as string);
		}
	});

	return form_data;
};
