import {AxiosError} from "axios";
import {isNumber} from "lodash";
import {ChangeEvent, MouseEvent, MutableRefObject, useRef} from "react";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {extractErrorMessage} from "data/utils";
import {GameBannerType, MatchStatus, MatchType, ModalType} from "data/enums";
import {IApiResponse} from "data/services/http";
import {type ISquadsStore} from "data/stores/squads/squads.store";
import {type ISportsbetStore, SportsbetType} from "data/stores/sportsbet/sportsbet.store";
import type {ITournamentData, ITournamentStore} from "data/stores/tournament/tournament.store";

interface IInit {
	tournamentId: number;
}

export interface ITippingCardController extends ViewController<IInit> {
	readonly i18n: ILocalizationStore;
	inputRef: MutableRefObject<HTMLInputElement | null>;

	get tournament(): ITournamentData | null;
	get isShowMargin(): boolean;
	get margin(): number | undefined;
	get isMarginDisabled(): boolean;
	get isLeftMarginArrowBlocked(): boolean;
	get isRightMarginArrowBlocked(): boolean;
	get isShowMarginArrows(): boolean;
	get isShowOdds(): boolean;
	get gameBannerType(): GameBannerType | null;
	get className(): string;

	changeHandler: (squadId: number) => void;
	changeMargin: (direction: "left" | "right") => void;
	onChangeMargin: (e: ChangeEvent<HTMLInputElement>) => void;
	onClickMargin: (e: MouseEvent<HTMLDivElement>) => void;
	openStatsHandler: () => void;
}

@injectable()
export class TippingCardController implements ITippingCardController {
	@observable _tournamentId: number | null = null;
	@observable public inputRef = useRef<HTMLInputElement>(null);

	get prediction() {
		return this._predictionsStore.getPredictionByTournamentId(this._tournamentId);
	}

	get selectedRound() {
		return this._roundsStore.selectedRound;
	}

	get tournament(): ITournamentData | null {
		return this._tournamentStore.getTournamentDataById(this._tournamentId, this.prediction);
	}

	get isShowMargin() {
		return Boolean(this.tournament?.isMargin);
	}

	get isMarginDisabled() {
		return this.tournament?.status !== MatchStatus.Scheduled;
	}

	get isLeftMarginArrowBlocked() {
		return !this.margin || this.isMarginDisabled;
	}

	get isRightMarginArrowBlocked() {
		if (this.isMarginDisabled) {
			return true;
		}

		return Boolean(this.margin && this.margin >= 99);
	}

	get margin() {
		const margin = this.prediction?.margin;
		return isNumber(margin) ? margin : undefined;
	}

	get isShowOdds() {
		const isShowMatchOdds = this._sportsbetStore.getIsShow(SportsbetType.MatchOdds);

		return this._tournamentStore.getIsShowOddsTournament(this.tournament, isShowMatchOdds);
	}

	get gameBannerType() {
		if (this.tournament?.type === MatchType.So) {
			return GameBannerType.STATE_OF_ORIGIN;
		}

		return null;
	}

	get className() {
		return this.gameBannerType !== null ? "with-banner" : "";
	}

	get isShowMarginArrows() {
		return this.tournament?.status === MatchStatus.Scheduled;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.SportsbetStore) private _sportsbetStore: ISportsbetStore,
		@inject(Bindings.TournamentStore) private _tournamentStore: ITournamentStore
	) {
		makeAutoObservable(this);
	}

	init(params: IInit) {
		this._tournamentId = params.tournamentId;
	}

	changeHandler = async (squadId: number) => {
		try {
			if (this.tournament?.isDisabled) {
				return;
			}

			await this._predictionsStore.setPredictionSquad(this._tournamentId!, squadId);

			const isShowModal =
				this._predictionsStore.hasAllPredictions &&
				!this._predictionsStore.allPredictionWereSaved;

			if (isShowModal) {
				this._modalsStore.showModal(ModalType.PREDICTIONS_SAVED);
			}

			const roundId = this._roundsStore.selectedRound?.id;

			if (roundId) {
				this._predictionsStore.updateAllPredictionWereSaved(
					roundId,
					this._predictionsStore.predictions
				);
			}
		} catch (error) {
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error as AxiosError<IApiResponse>),
			});
		}
	};

	changeMargin = async (direction: "left" | "right") => {
		try {
			const margin = Number(this.margin || 0);

			if (direction === "left" && margin > 0) {
				await this._predictionsStore.setPredictionMargin(this._tournamentId!, margin - 1);
			}

			// Max margin is 99
			if (direction === "right" && margin < 99) {
				await this._predictionsStore.setPredictionMargin(this._tournamentId!, margin + 1);
			}
		} catch (error) {
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error as AxiosError<IApiResponse>),
			});
		}
	};

	onChangeMargin = async (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		// if value is not blank, then test the regex
		if (value === "" || /^\d*$/.test(value)) {
			await this._predictionsStore.setPredictionMargin(this._tournamentId!, +value);
		}
	};

	onClickMargin = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		this.inputRef.current?.focus();
	};

	openStatsHandler = () => {
		if (!this.selectedRound?.id || !this.tournament?.id || !this.tournament.isScheduled) {
			return;
		}

		this._modalsStore.showModal(ModalType.GAME_STATS, {
			message: "",
			roundId: this.selectedRound.id,
			tournamentId: this.tournament.id,
		});
	};
}
