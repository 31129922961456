import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {AxiosError} from "axios";
import {ViewController} from "data/types/structure";
import {ITournamentData, type ITournamentStore} from "data/stores/tournament/tournament.store";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {type IStreakPredictionsStore} from "data/stores/streak_predictions/streak_predictions.store";
import {GameBannerType, MatchType, ModalType} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {IApiResponse} from "data/services/http";

interface IProps {
	tournamentId: number;
}

export interface IStreakCardController extends ViewController<IProps> {
	get tournament(): ITournamentData | null;
	get hasPrediction(): boolean;
	get gameBannerType(): GameBannerType | null;

	changeHandler: (squadId: number) => void;
	clearPredictionLogic: () => void;
}

@injectable()
export class StreakCardController implements IStreakCardController {
	@observable _tournamentId: number = 0;

	private get prediction() {
		return this._streakPredictionsStore.getPredictionByTournamentId(this._tournamentId);
	}

	get tournament() {
		return this._tournamentStore.getStreakTournamentDataById(
			this._tournamentId,
			this.prediction
		);
	}

	get hasPrediction() {
		return Boolean(this.prediction?.squadId);
	}

	get gameBannerType() {
		if (this.tournament?.type === MatchType.So) {
			return GameBannerType.STATE_OF_ORIGIN;
		}

		return null;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.StreakPredictionsStore)
		private _streakPredictionsStore: IStreakPredictionsStore,
		@inject(Bindings.TournamentStore) private _tournamentStore: ITournamentStore
	) {
		makeAutoObservable(this);
	}

	init(params: IProps) {
		this._tournamentId = params.tournamentId;
	}

	changeHandler = async (squadId: number) => {
		try {
			if (this.tournament?.isDisabled) {
				return;
			}

			await this._streakPredictionsStore.setPredictionSquad(this._tournamentId, squadId);
		} catch (error) {
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error as AxiosError<IApiResponse>),
			});
		}
	};

	clearPredictionLogic = async () => {
		try {
			if (!this.tournament?.isScheduled || !this.prediction?.squadId) {
				return;
			}

			await this._streakPredictionsStore.removePrediction(this._tournamentId);
		} catch (error) {
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error as AxiosError<IApiResponse>),
			});
		}
	};
}
