/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions, css} from "@mui/material/styles";
import {paletteTheme} from "assets/theming/palette.theme";

export const componentsTheme: ThemeOptions = {
	components: {
		MuiPaper: {
			variants: [
				{
					props: {variant: "elevation"},
					style: {
						boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.07)",
						borderRadius: "4px",
					},
				},
			],
		},
		MuiTabs: {
			styleOverrides: {
				flexContainer: {
					gap: "12px",
				},
				indicator: {
					display: "none",
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					minHeight: "30px",
					padding: "12px 20px",
					borderRadius: "4px",
					background: "#fff",
					fontWeight: 500,
					color: "#1a1a1a",
					boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.07)",
					transition: "all 0.2s ease-in-out",
					"&:hover": {
						background: paletteTheme.palette?.leagues.primary,
					},
					"&.active": {
						background: paletteTheme.palette?.leagues.primary,
					},
				},
			},
		},
	},
};
