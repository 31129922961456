import {first, get} from "lodash";
import {DateTime} from "luxon";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {BannerType, RoundStatus} from "data/enums";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {type IStreakPredictionsStore} from "data/stores/streak_predictions/streak_predictions.store";

export interface IBannerStore {
	get tippingBannerType(): BannerType | null;
	get streakBannerType(): BannerType | null;

	onComplete: () => void;
	clearStore: () => void;
}

@injectable()
export class BannerStore implements IBannerStore {
	@observable private _disposer?: ReturnType<typeof reaction>;
	@observable private _isTimerComplete: boolean = false;

	private get round() {
		return this._roundsStore.selectedRound;
	}

	private get tippingScheduledRoundBannerType(): BannerType | null {
		const isTimerHasExpired =
			DateTime.fromISO(get(first(this.round?.tournaments), "date", "")) < DateTime.now();

		if (this._predictionsStore.hasAllPredictions) {
			return BannerType.TipsSaved;
		}

		if (this._predictionsStore.hasPredictions) {
			return BannerType.TipsSaving;
		}

		if (!this._isTimerComplete && !isTimerHasExpired) {
			return BannerType.Timer;
		}

		// When round start date is past but round status scheduled
		return null;
	}

	get tippingBannerType() {
		const roundStatus = this.round?.status;

		if (roundStatus === RoundStatus.Playing) {
			return BannerType.Live;
		}

		if (roundStatus === RoundStatus.Scheduled) {
			return this.tippingScheduledRoundBannerType;
		}

		if (roundStatus === RoundStatus.Complete) {
			return BannerType.NextRoundTimer;
		}

		return null;
	}

	private get streakScheduledRoundBannerType(): BannerType | null {
		const isTimerHasExpired =
			DateTime.fromISO(get(first(this.round?.tournaments), "date", "")) < DateTime.now();

		if (this._streakPredictionsStore.hasAllPredictions) {
			return BannerType.TipsSaved;
		}

		if (this._streakPredictionsStore.hasPredictions) {
			return BannerType.TipsSaving;
		}

		if (!this._isTimerComplete && !isTimerHasExpired) {
			return BannerType.Timer;
		}

		// When round start date is past but round status scheduled
		return null;
	}

	get streakBannerType() {
		const roundStatus = this.round?.status;

		if (roundStatus === RoundStatus.Playing) {
			return BannerType.Live;
		}

		if (roundStatus === RoundStatus.Scheduled) {
			return this.streakScheduledRoundBannerType;
		}

		if (roundStatus === RoundStatus.Complete) {
			return BannerType.NextRoundTimer;
		}

		return null;
	}

	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.StreakPredictionsStore)
		private _streakPredictionsStore: IStreakPredictionsStore
	) {
		makeAutoObservable(this);
	}

	onComplete = () => {
		runInAction(() => {
			this._isTimerComplete = true;
		});

		this._disposer = reaction(
			() => {
				const round = this._roundsStore.selectedRound;

				if (!round) {
					return false;
				}

				return `${round?.id} ${round?.status}`;
			},
			() => {
				runInAction(() => {
					this._isTimerComplete = false;
				});
			}
		);
	};

	clearStore = () => {
		this._disposer?.();
	};
}
