import {useContext, useEffect, useRef} from "react";
import {interfaces} from "inversify";
import {ViewController} from "data/types/structure";
import {InjectionContext} from "data/services/locator/locator_provider.service";
import {isEqual} from "lodash";

type MaybeCleanUpFn = void | (() => void);

function useCustomCompareMemo<T>(value: T, equal: typeof isEqual): T {
	const ref = useRef<T>(value);

	if (!equal(value, ref.current)) {
		ref.current = value;
	}

	return ref.current;
}

function useDeepCompareEffect<T = unknown[]>(
	create: () => MaybeCleanUpFn,
	input: T,
	equal: typeof isEqual = isEqual
) {
	/**
	 * We don't want to call the "create" callback one more time if parameter wasn't changed.
	 * In this way we're emulates the default behaviour of the useEffect hook.
	 */
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(create, [useCustomCompareMemo(input, equal)]);
}

/**
 * React hook that used to receive ViewModel(aka ViewController) from the DI container.
 * @param identifier - The first parameter is a ViewModel identifier by which a class was registered.
 * We highly recommend to use "Symbol" for this.
 * @param param - The second parameter is an optional parameter that will be passed to the "init" method on instance creation.
 * The parameter type is inferred from a generic type.
 *
 * @description A ViewModel(aka ViewController) has 3 lifecycle methods:
 * - init - calls once a component is mounted
 * - onChange - calls everytime if some of the props passed to init method were changed. It doesn't call on initial render.
 * - dispose - call once when a component become destroyed.
 *
 * @example
 * const {decrement, increment, counter} = useViewController<IController>(IController, {
 *     initialCounter: 5
 * });
 */
export function useViewController<
	T extends ViewController<T2>,
	T2 = T extends ViewController<infer TParam> ? TParam : undefined
>(
	identifier: interfaces.ServiceIdentifier<T>,
	...param: T extends ViewController<infer TParam> ? [TParam] : []
): Omit<T, "init" | "dispose" | "onChange"> {
	const {container} = useContext(InjectionContext);

	if (!container) {
		throw new Error("InversifyJS Container must be provided to <InversifyProvider> component");
	}

	const vmRef = useRef<T>(container.get<T>(identifier));
	const isMountedRef = useRef(false);
	const isMounted = isMountedRef.current;
	const payload = param[0];

	useEffect(() => {
		const vm = vmRef.current;

		vm.init?.(payload);
		isMountedRef.current = true;

		return () => {
			vm.dispose?.();
		};

		/**
		 * We don't want to call the "init" method one more time if parameter was changed.
		 * This effect should be called once, on a component initialization
		 */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useDeepCompareEffect(() => {
		if (!isMounted) return;
		vmRef.current.onChange?.(payload);
	}, [payload]);

	return vmRef.current;
}
