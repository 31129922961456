import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {type IPredictionApiProvider} from "data/providers/api/prediction.api.provider";
import {IPrediction} from "data/stores/predictions/predictions.store";
import {type IRankingApiProvider} from "data/providers/api/ranking.api.provider";
import {type IGameBar} from "data/types/game";

export interface IModalCompleteRoundStore {
	get isOpen(): boolean;
	get roundId(): number | null;
	get isPerfect(): boolean;
	get predictions(): IPrediction[];
	get gameBar(): IGameBar;

	showModal(): void;
	hideModal(): void;
	setRoundId(roundId: number): void;
	fetchPredictions(): Promise<void>;
	fetchGameBar(): Promise<void>;
}

@injectable()
export class ModalCompleteRoundStore implements IModalCompleteRoundStore {
	@observable private _isOpen: boolean = false;
	@observable private _roundId: number | null = null;
	@observable private _predictions: IPrediction[] = [];
	@observable private _gameBar: IGameBar = {
		roundRank: null,
		roundPoints: null,
		overallPoints: null,
		overallRank: null,
		prevOverallRank: null,
		tips: 0,
		tipsCount: 0,
		rankRoundId: 0,
		correctTips: 0,
	};

	get isOpen() {
		return this._isOpen;
	}

	get roundId() {
		return this._roundId;
	}

	get isPerfect() {
		if (!this._predictions.length) {
			return false;
		}

		return this._predictions.every(({isCorrect}) => isCorrect);
	}

	get predictions() {
		return this._predictions;
	}

	get gameBar() {
		return this._gameBar;
	}

	constructor(
		@inject(Bindings.PredictionApiProvider) private _predictionApi: IPredictionApiProvider,
		@inject(Bindings.RankingApiProvider) private _rankingApi: IRankingApiProvider
	) {
		makeAutoObservable(this);
	}

	@action hideModal(): void {
		this._isOpen = false;
	}

	@action showModal(): void {
		this._isOpen = true;
	}

	@action setRoundId = (roundId: number = 1) => {
		this._roundId = roundId;
	};

	@action fetchPredictions = async () => {
		if (!this._roundId) {
			return;
		}

		const {data} = await this._predictionApi.get({
			roundId: this._roundId,
		});

		runInAction(() => {
			this._predictions = data.success.predictions;
		});
	};

	@action fetchGameBar = async () => {
		if (!this._roundId) {
			return;
		}

		const {data} = await this._rankingApi.gameBar({
			roundId: this._roundId,
		});

		runInAction(() => {
			this._gameBar = data.success;
		});
	};
}
