import * as React from "react";
import {FC} from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
	transform: rotate(-90deg);
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
`;

const Content = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(90deg);
	color: ${({theme}) => theme.palette.tipsCard.color};
	text-align: center;
	font-weight: 500;
	line-height: 8px;
	font-size: 9px;

	display: flex;
	align-items: center;
	justify-content: center;
	width: 28px;
	height: 28px;
	border-radius: 50%;

	&,
	* {
		transition: all 0.2s ease-in-out;
	}

	@media (min-width: 769px) {
		width: 36px;
		height: 36px;
		font-size: 12px;
	}
`;

const defaultValues = {
	percents: 50,
	width: 60,
	strokeWidth: 10,
	homeColor: "#003E78",
	awayColor: "#FFC124",
};

interface IProps {
	text: string;
	percents?: number;
	width?: number;
	strokeWidth?: number;
	homeColor?: string;
	awayColor?: string;
	Icon?: FC;
}

export const RoundProgress: FC<IProps> = (props) => {
	const {
		percents = defaultValues.percents,
		width = defaultValues.width,
		strokeWidth = defaultValues.strokeWidth,
		homeColor = defaultValues.homeColor,
		awayColor = defaultValues.awayColor,
		text,
		Icon,
	} = props;

	const HUNDRED = 100;
	const ONE = 1;
	const TWO = 2;

	const cxcy = +width / TWO;
	const RADIUS = +width / TWO - +strokeWidth / TWO;
	const PLACE = TWO * Math.PI * RADIUS;
	const progress = percents / HUNDRED;
	const offset = PLACE * (ONE - progress);

	return (
		<Wrapper>
			<svg width={width} height={width} viewBox={`0 0 ${width} ${width}`}>
				<circle
					cx={cxcy}
					cy={cxcy}
					r={RADIUS}
					fill="none"
					stroke={homeColor}
					strokeWidth={strokeWidth}
				/>
				<line
					x1={width}
					y1={width / 2}
					x2={width - strokeWidth}
					y2={width / 2}
					strokeWidth="2"
					stroke="white"
				/>
				<circle
					cx={cxcy}
					cy={cxcy}
					r={(RADIUS / 100) * 99} // Radius is less than the circle to remove the white border around the circle
					fill="none"
					stroke="white"
					strokeWidth={strokeWidth}
					strokeDasharray={PLACE}
					strokeDashoffset={offset - 0.5}
				/>
				<circle
					cx={cxcy}
					cy={cxcy}
					r={RADIUS}
					fill="none"
					stroke={awayColor}
					strokeWidth={strokeWidth}
					strokeDasharray={PLACE}
					strokeDashoffset={offset + 0.5}
				/>
			</svg>

			<Content tabIndex={0} className="content">
				{Icon ? <Icon /> : text}
			</Content>
		</Wrapper>
	);
};
