import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {RoundProgress} from "views/components/round_progress/round_progress";
import {ITournamentStatsCircleController} from "./tornament_stats_circle.controller";

const Wrapper = styled.div`
	width: 100%;
	padding: 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	background: ${({theme}) => theme.palette.statsModal.statsItemBg};
	box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
	white-space: nowrap;
	font-size: 11px;
	font-weight: 500;
	line-height: 12px;
	gap: 12px;
	position: relative;
`;

const Title = styled.div`
	text-align: center;
	font-size: 11px;
	font-weight: 500;
	line-height: 12px;
`;

const SmallText = styled.div`
	font-size: 14px;
`;

const Score = styled.div`
	top: 50%;
	font-size: 16px;
	transform: translate(-50%, -50%);
	position: absolute;
	font-weight: 400;

	&.left {
		left: calc(50% - 60px);
	}

	&.right {
		left: calc(50% + 60px);
	}

	&.win {
		font-size: 20px;
		font-weight: 500;
	}
`;

interface ITournamentStatsCircle {
	tournamentId: number;
}

export const TournamentStatsCircle: React.FC<ITournamentStatsCircle> = observer(
	({tournamentId}) => {
		const {
			i18n,
			homeColor,
			awayColor,
			percents,
			played,
			homeWins,
			awayWins,
			drawn,
			homeCN,
			awayCN,
		} = useViewController<ITournamentStatsCircleController>(
			Bindings.TournamentStatsCircleController,
			{tournamentId}
		);

		if (!played) {
			return null;
		}

		return (
			<Wrapper>
				<Title>{i18n.t("modal.game_stats.played", "Played")}</Title>

				<SmallText>{played}</SmallText>

				<Score className={`left ${homeCN}`}>{homeWins}</Score>

				<RoundProgress
					width={80}
					strokeWidth={8}
					awayColor={awayColor}
					homeColor={homeColor}
					percents={percents}
					text={i18n.t("modal.game_stats.won", "Won")}
				/>

				<Score className={`right ${awayCN}`}>{awayWins}</Score>

				<Title>{i18n.t("modal.game_stats.drawn", "Drawn")}</Title>

				<SmallText>{drawn}</SmallText>
			</Wrapper>
		);
	}
);
