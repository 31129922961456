import {first, last, orderBy, sortBy} from "lodash";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {MatchStatus, MatchType, RoundStatus} from "data/enums";

export interface ITournamentOdds {
	linkId: number;
	price: number;
}

export interface ITournament {
	id: number;
	venueName: string;
	venueId: number;
	date: string;
	homeSquadId: number;
	awaySquadId: number;
	homeSquadName: string;
	awaySquadName: string;
	homeScore: number | null;
	awayScore: number | null;
	status: MatchStatus;
	homeSquadAbbr: string;
	awaySquadAbbr: string;
	type: MatchType | null;
	awayPicksPercentage: number;
	homePicksPercentage: number;
	isMargin: boolean;
	homeOdds?: ITournamentOdds;
	awayOdds?: ITournamentOdds;
}

export interface IRound {
	id: number;
	status: RoundStatus;
	startDate: string;
	endDate: string;
	tournaments: ITournament[];
	isCurrent: boolean;
}

export interface IRoundsStore {
	get list(): IRound[];
	get actualRound(): IRound | undefined;
	get currentRound(): IRound | undefined;
	get currentRoundStartDate(): string | undefined;
	get scheduleRounds(): IRound[];
	get selectedRound(): IRound | undefined;
	get completedRounds(): IRound[];
	get completeOrActiveRounds(): IRound[];
	get viewTipsRounds(): IRound[];
	get lastCompleteRound(): IRound | undefined;
	get isSeasonFinished(): boolean;
	get isSeasonStarted(): boolean;
	get isFinishAnyTournament(): boolean;
	get isFinishFirstRound(): boolean;

	fetchRounds(): Promise<void>;
	setSelectedRoundId: (roundId: number) => void;
	getRoundById: (id: number) => IRound | undefined;
	clearStore: () => void;
}

@injectable()
export class RoundsStore implements IRoundsStore {
	@observable private _selectedRoundId: number = 0;
	@observable private _list: IRound[] = [];

	get list() {
		return this._list;
	}

	get selectedRoundId() {
		return this._selectedRoundId;
	}

	get scheduleRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Scheduled);
	}

	get completedRounds() {
		return this._list.filter((e) => e.status === RoundStatus.Complete);
	}

	get completeOrActiveRounds() {
		return this.list.filter((e) => e.status !== RoundStatus.Scheduled);
	}

	// Rounds with any finished tournament
	get viewTipsRounds() {
		return orderBy(
			this.list.filter(({tournaments}) =>
				tournaments.some(({status}) => status === MatchStatus.Complete)
			),
			"id",
			"desc"
		);
	}

	get playingRound() {
		return this._list.find((e) => e.status === RoundStatus.Playing);
	}

	get actualRound() {
		return (
			this._list.find(({status}) =>
				[RoundStatus.Playing, RoundStatus.Scheduled].includes(status)
			) || last(this._list)
		);
	}

	get currentRoundStartDate() {
		return first(this.actualRound?.tournaments)?.date;
	}

	get selectedRound() {
		return this._list.find((round) => round.id === this._selectedRoundId) || this.actualRound;
	}

	get lastCompleteRound() {
		return last(this.completedRounds);
	}

	get isSeasonFinished() {
		return this._list.every(({status}) => status === RoundStatus.Complete);
	}

	get isSeasonStarted() {
		return this._list.some(({status}) => status !== RoundStatus.Scheduled);
	}

	get isFinishFirstRound() {
		return this._list.some(({status}) => status === RoundStatus.Complete);
	}

	get isFinishAnyTournament() {
		return this._list.some(({tournaments}) =>
			tournaments.some(({status}) => status === MatchStatus.Complete)
		);
	}

	get currentRound() {
		return this._list.find(({isCurrent}) => isCurrent);
	}

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@action setSelectedRoundId(roundId: number) {
		this._selectedRoundId = roundId;
	}

	@action
	async fetchRounds() {
		const {data} = await this._jsonProvider.rounds();

		runInAction(() => {
			this._list = sortBy(data, "id");
		});
	}

	getRoundById(id: number) {
		return this._list.find((round) => round.id === id);
	}

	clearStore = () => {
		this._selectedRoundId = 0;
		this._list = [];
	};
}
