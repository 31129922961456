import {action, computed, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ChangeEvent, ReactNode} from "react";
import type {SelectChangeEvent} from "@mui/material";
import type {IUpdateLeaguePayload} from "data/providers/api/leagues.api.provider";
import type {IUserStore} from "data/stores/user/user.store";
import type {IApiResponse} from "data/services/http";
import type {AxiosError} from "axios";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {chain, identity, isEqual, partial} from "lodash";
import {LeaguePrivacy, LeagueStatus, ModalType, RequestState, RoundStatus} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {MAX_FILE_SIZE} from "data/constants";
import {useNavigate} from "react-router";
import {getLeagueAvatarUrl} from "data/utils/avatar";

interface IProps {
	leagueId: number;
	navigate: ReturnType<typeof useNavigate>;
}

interface IForm extends HTMLFormElement {
	leagueName: HTMLInputElement;
	startId: HTMLInputElement;
	privacy: HTMLInputElement;
}

interface IFormValue {
	avatar: File | null;
	leagueName: string;
	startId: number;
	privacy: LeaguePrivacy;
}

const defaultForm: IFormValue = {
	avatar: null,
	leagueName: "",
	startId: 0,
	privacy: LeaguePrivacy.PRIVATE,
};

export interface ILeagueSettingsController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get league(): ILeague | null;
	get rounds(): IRound[];
	get isPageLoading(): boolean;
	get isLoading(): boolean;
	get isLeaguePresenceRequestLoading(): boolean;
	get isLeagueUsersRequestLoading(): boolean;
	get isDeleteLeagueDisabled(): boolean;
	get isDeleteUsersRequestLoading(): boolean;
	get isUpdateButtonDisabled(): boolean;
	get isLeagueNameFieldDisabled(): boolean;
	get isLeagueStarted(): boolean;
	get isFormDisabled(): boolean;
	get formValue(): IFormValue;
	get avatarError(): string;
	get isCommissioner(): boolean;
	get avatarIcon(): string | undefined;

	handleUpdateLeague: () => void;
	handleFormChange: (event: ChangeEvent<IForm>) => void;
	handleAvatarChange: (e: ChangeEvent<HTMLInputElement>) => void;
	startRoundChange: (event: SelectChangeEvent<unknown>, _: ReactNode) => void;
	leaveLeague: () => void;
	joinLeague: () => void;
	removeLeague: () => void;
}

@injectable()
export class LeagueSettingsController implements ILeagueSettingsController {
	@observable private _leagueId?: number;
	@observable private _leagueDisposer?: ReturnType<typeof reaction>;
	@observable private _navigate?: ReturnType<typeof useNavigate>;

	@observable private _requestStates = {
		general: RequestState.PENDING,
		update: RequestState.IDLE,
		leaguePresence: RequestState.IDLE,
		leagueUsers: RequestState.IDLE,
		removeUser: RequestState.IDLE,
		removeLeague: RequestState.IDLE,
	};

	@observable private _formValue: IFormValue = defaultForm;

	@observable private _avatarError: string = "";

	private get leagueID() {
		return this._leagueId!;
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get leagueValues() {
		if (this.league) {
			return {
				avatar: null,
				leagueName: this.league.name,
				startId: this.league.startId,
				privacy: this.league.privacy,
			};
		}
		return defaultForm;
	}

	@computed get rounds() {
		return this._roundsStore.list.filter((round) => {
			const isScheduled = isEqual(round.status, RoundStatus.Scheduled);
			const isLeagueStartRound = isEqual(this.league?.startId, round.id);

			return isScheduled || isLeagueStartRound;
		});
	}

	get formValue() {
		return this._formValue;
	}

	get isPageLoading() {
		return isEqual(this._requestStates.general, RequestState.PENDING);
	}

	get isLoading() {
		return isEqual(this._requestStates.update, RequestState.PENDING);
	}

	get isLeaguePresenceRequestLoading() {
		return isEqual(this._requestStates.leaguePresence, RequestState.PENDING);
	}

	get isLeagueUsersRequestLoading() {
		return isEqual(this._requestStates.leagueUsers, RequestState.PENDING);
	}

	get isDeleteLeagueDisabled() {
		if (this.league?.status !== LeagueStatus.SCHEDULED) {
			return true;
		}

		return this.isDeleteUsersRequestLoading;
	}

	get isDeleteUsersRequestLoading() {
		return isEqual(this._requestStates.removeLeague, RequestState.PENDING);
	}

	get isFormDisabled() {
		return this.isLoading || this.isLeagueStarted;
	}

	get isLeagueNameFieldDisabled() {
		return this.isLoading;
	}

	get isLeagueStarted() {
		return !isEqual(this.league?.status, LeagueStatus.SCHEDULED);
	}

	get isUpdateButtonDisabled() {
		if (this.isLeagueStarted) {
			return !this.isFormValid || this.isLoading;
		}

		return this.isFormDisabled || !this.isFormValid;
	}

	get hasFormChanged() {
		return !isEqual(this.leagueValues, this.formValue);
	}

	@computed
	private get isFormValid() {
		if (!this.hasFormChanged) {
			return false;
		}
		return chain(this.formValue).omit("avatar").values().every(identity).value();
	}

	get avatarError() {
		return this._avatarError;
	}

	get isCommissioner() {
		return isEqual(this.league?.leagueManager?.userId, this._userStore.user!.id);
	}

	get avatarIcon() {
		if (!this.league?.avatarVersion) {
			return;
		}

		return getLeagueAvatarUrl(this.league.id, this.league.avatarVersion);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	private createSuccessHandlerOf = (type: keyof typeof this._requestStates) => {
		this._requestStates[type] = RequestState.SUCCESS;
	};

	private createErrorHandlerOf = (
		type: keyof typeof this._requestStates,
		error: AxiosError<IApiResponse>
	) => {
		this._requestStates[type] = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	private setPendingState = (type: keyof typeof this._requestStates) => {
		this._requestStates[type] = RequestState.PENDING;
	};

	handleUpdateLeague = () => {
		this.setPendingState("update");

		const {leagueName, startId, privacy, avatar} = this._formValue;

		const payload: IUpdateLeaguePayload = {
			name: leagueName,
			leagueId: this.leagueID,
			avatar,
		};

		if (!this.isLeagueStarted) {
			payload.privacy = privacy;
			payload.startId = startId;
		}

		this._leaguesStore
			.updateLeague(payload)
			.then(() => this.createSuccessHandlerOf("update"))
			.catch(partial(this.createErrorHandlerOf, "update"));
	};

	@action handleFormChange = (event: ChangeEvent<IForm>) => {
		const {leagueName, privacy} = event.currentTarget;

		this._formValue.leagueName = leagueName.value;
		this._formValue.privacy = privacy.value as LeaguePrivacy;
	};

	@action handleAvatarChange = (e: ChangeEvent<HTMLInputElement>) => {
		this._avatarError = "";
		const target = e.target as HTMLInputElement;
		const files = target.files || [];
		const file = files[0];

		target.value = "";

		if (file?.size > MAX_FILE_SIZE) {
			this._avatarError = this.i18n.t("league_create.step_1.avatar_error", "File is too big");
			return;
		}

		this._formValue.avatar = file;
	};

	@action startRoundChange = (event: SelectChangeEvent<unknown>, _: ReactNode) => {
		this._formValue.startId = Number(event.target.value);
	};

	@action leaveLeague = () => {
		this._modalsStore.showModal(ModalType.CONFIRM, {
			message: this.i18n.t(
				"league.leave.confirm",
				"Do you really want to leave this league?"
			),
			callback: () => {
				this._modalsStore.hideModal();
				this.setPendingState("leaguePresence");

				this._leaguesStore
					.leaveLeague({leagueId: this.leagueID})
					.then(() => this._leaguesStore.fetchLeagueUsers({leagueId: this.leagueID}))
					.then(() => this.createSuccessHandlerOf("leaguePresence"))
					.catch(partial(this.createErrorHandlerOf, "leaguePresence"));
			},
		});
	};

	@action joinLeague = () => {
		const code = this.league?.code;

		if (!code) return;

		this.setPendingState("leaguePresence");

		this._leaguesStore
			.joinToLeague({code})
			.then(() => this._leaguesStore.fetchLeagueUsers({leagueId: this.leagueID}))
			.then(() => this.createSuccessHandlerOf("leaguePresence"))
			.catch(partial(this.createErrorHandlerOf, "leaguePresence"));
	};

	@action init({leagueId, navigate}: IProps) {
		this._leagueId = leagueId;
		this._navigate = navigate;

		Promise.all([
			this._roundsStore.fetchRounds(),
			this._leaguesStore.fetchLeagueUsers({leagueId}),
		])
			.then(() => this.createSuccessHandlerOf("general"))
			.catch(partial(this.createErrorHandlerOf, "general"));

		this._leagueDisposer = reaction(
			() => this.league,
			() => {
				if (!this.league) return;

				const {startId, name, privacy} = this.league;

				this._formValue.startId = startId;
				this._formValue.leagueName = name;
				this._formValue.privacy = privacy;
			},
			{fireImmediately: true}
		);
	}

	@action removeLeague = () => {
		this._modalsStore.showModal(ModalType.CONFIRM, {
			message: this.i18n.t(
				"league.remove.confirm",
				"Do you really want to remove this league?"
			),
			callback: () => {
				this._modalsStore.hideModal();
				this.setPendingState("removeLeague");

				this._leaguesStore
					.removeLeague({leagueId: this.leagueID})
					.then(() => this.createSuccessHandlerOf("removeLeague"))
					.then(() => {
						runInAction(() => {
							this._navigate?.(`/comps`);
						});
					})
					.catch(partial(this.createErrorHandlerOf, "removeLeague"));
			},
		});
	};

	dispose() {
		this._leagueDisposer?.();
	}
}
