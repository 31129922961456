import {observer} from "mobx-react";
import styled from "@emotion/styled";
import React, {FC, Fragment} from "react";
import {SportbetCurrency, SportsbetMultiBuilder} from "data/constants";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {IOddsController} from "./odds.controller";
import sportbetExternalLink from "assets/img/icons/sportbetExternalLink.svg";
import sportsBetLogo from "assets/img/icons/sportsBet.png";

const OddsContainer = styled.a`
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	padding: 6px 4px 6px 12px;
	background: ${({theme}) => theme.palette.sportsbetCalculator.bg};
	border-radius: 4px;
	gap: 12px;
`;

const SupportText = styled.div`
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	color: ${({theme}) => theme.palette.modal.color};
`;

const MultiContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: 5px;
	gap: 8px;
	font-size: 14px;
	font-weight: 500;
	line-height: 12px;
	letter-spacing: 0.2px;
	border-radius: 4px;
	color: ${({theme}) => theme.palette.sportsbetCalculator.color};
	background: ${({theme}) => theme.palette.sportsbetCalculator.multiBg};
	flex-grow: 1;
`;

const SponsorLogo = styled.img`
	width: 48px;
`;

const ExternalIcon = styled.img`
	right: 4px;
	top: 0;
	position: absolute;
`;

const StakeContainer = styled.div`
	border-radius: 4px;
	color: ${({theme}) => theme.palette.sportsbetCalculator.stakeColor};
	background: ${({theme}) => theme.palette.sportsbetCalculator.stakeBg};
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 5px 5px 3px 5px;
	gap: 4px;
	font-size: 14px;
	font-weight: 500;
	line-height: 12px;
	letter-spacing: 0.2px;
	flex-grow: 1;
`;

const Input = styled.input`
	border: none;
	outline: none;
	padding: 0;
	height: 18px;
	color: ${({theme}) => theme.palette.sportsbetCalculator.stakeColor};
	width: 18px;

	&::placeholder {
		color: ${({theme}) => theme.palette.sportsbetCalculator.stakeColor};
		opacity: 1; /* Firefox */
	}
`;

export const Odds: FC = observer(() => {
	const {i18n, stake, onChangeStake, odds, onClickStake, inputRef} =
		useViewController<IOddsController>(Bindings.OddsController);

	return (
		<Fragment>
			<OddsContainer href={SportsbetMultiBuilder} target="_blank" rel="noopener noreferrer">
				<StakeContainer onClick={onClickStake}>
					<div>{i18n.t("tips.summary.stake", "Stake")}</div>

					<div>
						<span>{SportbetCurrency}</span>{" "}
						<Input
							ref={inputRef}
							type="text"
							value={stake}
							onChange={onChangeStake}
							placeholder="0"
							maxLength={5}
						/>
					</div>
				</StakeContainer>

				<MultiContainer>
					<div>{i18n.t("tips.summary.calculator", "Multi")}</div>
					<div>
						{SportbetCurrency}
						{odds.toFixed(2)}
					</div>
				</MultiContainer>

				<ExternalIcon src={sportbetExternalLink} alt="Sportbet link" />

				<SponsorLogo src={sportsBetLogo} alt="sportbet" />
			</OddsContainer>

			<SupportText>
				<div>
					{i18n.t(
						"tips.summary.support_text",
						"Chances are you’re about to lose. For free and confidential support call 1800 858 858 or visit gamblinghelponline.org.au"
					)}
				</div>
			</SupportText>
		</Fragment>
	);
});
