import {isNumber} from "lodash";
import {RankStatus} from "data/enums";
import {IRanking} from "data/stores/rankings/rankings.store";
import {ILeagueRanking} from "data/stores/league_rankings/league_rankings.store";

const getRankStatusData = (ranking: IRanking | ILeagueRanking | null, isOverall: boolean) => {
	const roundRank = ranking?.rank;
	const lastRoundRank = ranking?.lastRoundData?.rank;
	const overallRank = ranking?.overallRank;
	const lastOverallRank = ranking?.lastRoundData?.overallRank;

	const rank = isOverall ? overallRank : roundRank;
	const lastRank = isOverall ? lastOverallRank : lastRoundRank;

	return {rank, lastRank};
};

interface IGetRankStatus {
	ranking: IRanking | ILeagueRanking | null;
	isShowRankStatus: boolean;
	isOverall: boolean;
}

export const getRankStatus = ({isShowRankStatus = false, ranking, isOverall}: IGetRankStatus) => {
	const {rank, lastRank} = getRankStatusData(ranking, isOverall);
	// For first round, we don't show any status
	if (!isShowRankStatus) {
		return null;
	}
	// if user don't have rank, we show SAME status
	if (!isNumber(rank)) {
		return RankStatus.SAME;
	}
	// if user don't have lastRank, but have rank, we show UP status
	if (!isNumber(lastRank)) {
		return RankStatus.UP;
	}

	if (rank === lastRank) {
		return RankStatus.SAME;
	}

	return lastRank > rank ? RankStatus.UP : RankStatus.DOWN;
};
