import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IJSONProvider} from "data/providers/json/json.provider";

export interface IMonth {
	id: number;
	name: string;
	status: string;
}

export interface IMonthsStore {
	list: IMonth[];

	fetchMonths(): Promise<void>;
}

@injectable()
export class MonthsStore implements IMonthsStore {
	@observable private _list: IMonth[] = [];

	get list() {
		return this._list;
	}

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@action async fetchMonths() {
		const {data} = await this._jsonProvider.months();

		runInAction(() => {
			this._list = data;
		});
	}
}
