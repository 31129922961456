import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {isEmpty, isFunction} from "lodash";
import {DateTime} from "luxon";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type IUserStore} from "data/stores/user/user.store";
import {AdsAgeLimit} from "data/constants/game";
import type {ISquadsStore} from "data/stores/squads/squads.store";

interface IProps {
	id: string;
	path: string;
	size: [number, number] | [number, number][];
}

export interface IAdvertController extends ViewController<IProps> {
	init(param: IProps): void;
}

@injectable()
export class AdvertController implements IAdvertController {
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	async init(param: IProps) {
		try {
			await this._squadsStore.safeFetchSquads();
		} catch (e) {
			console.error(e);
		}

		const GT = window.googletag;
		const user = this._userStore.user;
		const supportedClub = this._squadsStore.getSquadById(user?.supportedSquadId)?.name || "";

		if (GT && isFunction(GT.pubads)) {
			GT.cmd.push(function () {
				if (user) {
					const birthYear = DateTime.fromFormat(
						user.birthday,
						"yyyy-MM-dd"
					).year.toString();
					const over18 =
						DateTime.fromFormat(user.birthday, "yyyy-MM-dd").diffNow("years").years <
						-AdsAgeLimit;
					const age = over18 ? "over18" : "under18";
					const gender = user.gender;

					GT.defineSlot(param.path, param.size, param.id)
						?.setTargeting("gen", gender)
						.setTargeting("clubfav", supportedClub)
						.setTargeting("birthYear", birthYear)
						.setTargeting("age", age)
						.addService(GT.pubads());
				} else {
					GT.defineSlot(param.path, param.size, param.id)?.addService(GT.pubads());
				}

				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore enableAsyncRendering present in PubAdsService
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call
				GT.pubads()?.enableAsyncRendering?.();
				GT.pubads().enableSingleRequest();
				GT.pubads().disableInitialLoad(); // Every page calls googletag.pubads().refresh(), so wait for that before displaying
				GT.enableServices();
				GT.display(param.id);
			});

			if (!isEmpty(GT.pubads()?.getSlots())) {
				GT.pubads().refresh();
			}
		}
	}
}
