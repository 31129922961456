import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import type {IWebViewGateStore} from "data/stores/web_view_gate/web_view_gate.store";
import {Bindings} from "data/constants/bindings";

export interface IFooterController extends ViewController {
	get isFromApp(): boolean;
}

@injectable()
export class FooterController implements IFooterController {
	constructor(@inject(Bindings.WebViewGateStore) private _webViewGateStore: IWebViewGateStore) {
		makeAutoObservable(this);
	}

	get isFromApp() {
		return this._webViewGateStore.isFromApp;
	}
}
