import {AxiosError} from "axios";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {SelectChangeEvent} from "@mui/material";
import {identity, pickBy} from "lodash";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type IRankingsStore} from "data/stores/rankings/rankings.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {ModalType, OrderField, RequestState, SortOrder} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {IApiResponse} from "data/services/http";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {type IStatesStore} from "data/stores/states/states.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type ISquadsStore} from "data/stores/squads/squads.store";
import {type IMonthsStore} from "data/stores/months/months.store";
import {type ISortData} from "data/types/game";

export interface IFilters {
	state: string;
	supportedSquadId: string;
	roundId: number;
	monthId: number;
}

const RANK_LIMIT = 20;

export interface IRankingsController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isLoading(): boolean;
	get filters(): IFilters;
	get isOverall(): boolean;
	get isShowRankStatus(): boolean;
	get sortData(): ISortData;
	get hasRankings(): boolean;
	get isSeasonStarted(): boolean;

	loadMoreRankings: () => void;
	changeOrderHandler: (field: OrderField) => void;
	filtersChangeHandler: (name: keyof IFilters) => (e: SelectChangeEvent<unknown>) => void;
}

@injectable()
export class RankingsController implements IRankingsController {
	@observable _requestState = RequestState.PENDING;
	@observable _filters: IFilters = {
		state: "",
		supportedSquadId: "",
		roundId: 0,
		monthId: 0,
	};
	@observable _sortData: ISortData = {
		field: OrderField.RANK,
		order: SortOrder.ASC,
	};

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get filters() {
		return this._filters;
	}

	private get rankings() {
		return this._rankingsStore.list;
	}

	get isOverall() {
		return Boolean(!this._filters.roundId && !this._filters.monthId);
	}

	get isShowRankStatus() {
		return this._roundsStore.actualRound?.id !== 1;
	}

	get sortData() {
		return this._sortData;
	}

	get hasRankings() {
		return Boolean(this.rankings.length);
	}

	get isSeasonStarted() {
		return this._roundsStore.isSeasonStarted;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.StatesStore) private _statesStore: IStatesStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.MonthsStore) private _monthsStore: IMonthsStore
	) {
		makeAutoObservable(this);
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	private get rankingParams() {
		return pickBy(
			{
				roundId: this._filters.roundId,
				state: this._filters.state,
				supportedSquadId: this._filters.supportedSquadId,
				sortBy: this._sortData.field,
				sortOrder: this._sortData.order,
				limit: RANK_LIMIT,
				month: this._filters.monthId,
			},
			identity
		);
	}

	async init() {
		try {
			await Promise.all([
				this._rankingsStore.fetchRankings({limit: RANK_LIMIT}),
				this._roundsStore.fetchRounds(),
				this._statesStore.fetchStates(),
				this._squadsStore.fetchSquads(),
				this._monthsStore.fetchMonths(),
			]);

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (error) {
			this.onError(error as AxiosError<IApiResponse>);
		}
	}

	loadMoreRankings = async () => {
		try {
			this._requestState = RequestState.PENDING;
			await this._rankingsStore.loadMoreRankings(this.rankingParams);

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (error) {
			this.onError(error as AxiosError<IApiResponse>);
		}
	};

	private fetchRankings = async () => {
		try {
			this._requestState = RequestState.PENDING;
			await this._rankingsStore.fetchRankings(this.rankingParams);

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (error) {
			this.onError(error as AxiosError<IApiResponse>);
		}
	};

	filtersChangeHandler = (name: keyof IFilters) => (e: SelectChangeEvent<unknown>) => {
		if (name === "supportedSquadId") {
			this._filters = {
				...this._filters,
				state: "",
				supportedSquadId: e.target.value as string,
			};
		}

		if (name === "state") {
			this._filters = {
				...this._filters,
				supportedSquadId: "",
				state: e.target.value as string,
			};
		}

		if (name === "roundId") {
			this._filters = {
				...this._filters,
				monthId: 0,
				roundId: e.target.value as number,
			};
		}

		if (name === "monthId") {
			this._filters = {
				...this._filters,
				roundId: 0,
				monthId: e.target.value as number,
			};
		}

		void this.fetchRankings();
	};

	changeOrderHandler = async (field: OrderField) => {
		if (this._sortData.field === field) {
			this._sortData.order =
				this._sortData.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
		} else {
			this._sortData = {
				order: SortOrder.ASC,
				field,
			};
		}

		try {
			this._requestState = RequestState.PENDING;
			await this._rankingsStore.fetchRankings(this.rankingParams);

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (error) {
			this.onError(error as AxiosError<IApiResponse>);
		}
	};

	dispose() {
		this._rankingsStore.clearStore();
	}
}
