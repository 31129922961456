import {ChangeEvent, MouseEvent, MutableRefObject, useRef} from "react";
import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {type ISportsbetStore} from "data/stores/sportsbet/sportsbet.store";

export interface IOddsController extends ViewController {
	i18n: ILocalizationStore;
	inputRef: MutableRefObject<HTMLInputElement | null>;

	get stake(): string;
	get odds(): number;

	onChangeStake: (e: ChangeEvent<HTMLInputElement>) => void;
	onClickStake: (e: MouseEvent<HTMLDivElement>) => void;
}

@injectable()
export class OddsController implements IOddsController {
	@observable _stake: string = "10";
	@observable public inputRef = useRef<HTMLInputElement>(null);

	get stake() {
		return this._stake;
	}

	get odds() {
		return this._sportsbetStore.calculatorValue * Number(this._stake);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.SportsbetStore) private _sportsbetStore: ISportsbetStore
	) {
		makeAutoObservable(this);
	}

	onChangeStake = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;

		// if value is not blank, then test the regex
		if (e.target.value === "" || /^\d*$/.test(value)) {
			this._stake = value;
			e.target.style.width = `${(value.length + 1) * 8}px`;
		}
	};

	onClickStake = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		this.inputRef.current?.focus();
	};
}
