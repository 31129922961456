import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";

export interface INotificationBar {
	isEnabled: boolean;
	message: string;
}

export interface INotificationBarStore {
	get notificationBar(): INotificationBar;
	get isEnabled(): boolean;
	clear(): void;

	fetchNotif(): Promise<INotificationBar>;
}

const _defaultNotificationBar = {
	isEnabled: false,
	message: "",
};

@injectable()
export class NotificationBarStore implements INotificationBarStore {
	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _notificationBar: INotificationBar = _defaultNotificationBar;

	get notificationBar(): INotificationBar {
		return this._notificationBar;
	}

	get isEnabled(): boolean {
		return this._notificationBar.isEnabled;
	}
	clear() {
		this._notificationBar = {
			..._defaultNotificationBar,
		};
	}

	@action
	async fetchNotif(): Promise<INotificationBar> {
		const {data} = await this._jsonProvider.fetchNotif();
		if (data) {
			runInAction(() => {
				this._notificationBar = data;
			});
		}
		return data;
	}
}
