import React, {FC, Fragment} from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {Button} from "@mui/material";
import {partial} from "lodash";
import {SocialNetwork} from "data/enums";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {tournamentIcons} from "data/constants/game";
import {getSquadAvatarUrl} from "data/utils/avatar";
import {useIsMobileShare} from "data/hooks/useIsMobileShare";
import {
	ModalBase,
	ModalDesc,
	ModalTitle,
} from "views/components/modals/modal_base/modal_base.component";
import {Exist} from "views/components/exist/exist.component";
import {Preloader} from "views/components/preloader";
import {IModalPredictionsSavedController} from "./modal_predictions_saved.controller";
import {ReactComponent as ModalIcon} from "assets/img/icons/modalProgress.svg";
import {ReactComponent as ShareIcon} from "assets/img/icons/share.svg";
import {ReactComponent as DownloadIcon} from "assets/img/icons/download.svg";
import {ReactComponent as FacebookIcon} from "assets/img/icons/facebook.svg";
import {ReactComponent as TwitterIcon} from "assets/img/icons/twitter.svg";
import {Odds} from "./odds/odds.component";
import {getRoundName} from "data/utils";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
	max-width: 380px;
	width: 100%;

	@media (min-width: 769px) and (min-height: 850px) {
		gap: 18px;
	}
`;

export const SModalTitle = styled(ModalTitle)`
	margin-bottom: 6px;
`;

export const List = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1px;
	overflow: auto;
	border-radius: 4px;
	max-height: 150px;

	&.max-h-250 {
		max-height: 250px;
	}

	@media (min-height: 750px) {
		max-height: 250px;
	}

	@media (min-width: 769px) and (min-height: 850px) {
		max-height: 50vh;
	}
`;

export const Tournament = styled.div`
	display: grid;
	align-items: center;
	padding: 0 2px;
	grid-template-columns: 40px 38px 1fr 1fr 38px 40px;
	background: #f6f6f6;
`;

export const TournamentIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;

	svg {
		width: 20px;
		height: 20px;
	}
`;

export const SquadLogo = styled.img`
	width: 32px;
	height: 32px;
	object-fit: contain;

	&:first-of-type {
		margin-right: 6px;
	}

	&:last-of-type {
		margin-left: 6px;
	}
`;

export const SquadName = styled.div`
	font-size: 12px;
	font-weight: 500;
	line-height: 16px;
	text-align: left;

	&.reverse {
		text-align: right;
	}
`;

const Buttons = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px;
	width: 100%;

	& > *:first-child {
		grid-column: 1 / 5;
	}

	& > *:last-child {
		grid-column: span 2 / 5;
	}

	button,
	a {
		gap: 4px;
	}

	&.mobile-share {
		& > *:nth-child(2) {
			grid-column: span 2 / 3;
		}

		& > *:last-child {
			grid-column: span 2 / 5;
		}
	}
`;

const shareKey = "modal.picks_saved.share_btn";
const shareKeyDftValue = "Share";

export const ModalPredictionsSaved: FC = observer(() => {
	const {
		i18n,
		isOpen,
		closeHandler,
		tournaments,
		roundId,
		isShowOdds,
		downloadImage,
		shareHandler,
		emailHandler,
		isLoadingEmail,
		isSentEmail,
		isIdleEmail,
		className,
		isMobileShare,
	} = useViewController<IModalPredictionsSavedController>(
		Bindings.ModalPredictionsSavedController,
		{isMobileShare: useIsMobileShare().isMobileShare}
	);

	const roundName = getRoundName(roundId, i18n);
	return (
		<ModalBase
			isOpen={isOpen}
			closeHandler={closeHandler}
			className="confirm-modal small-mb-pd">
			<Container>
				<ModalIcon />

				<div>
					<SModalTitle>
						{i18n.t("tips.summary.title", "Your Round {{X}} Summary", {X: roundName})}
					</SModalTitle>

					<ModalDesc>
						{i18n.t(
							"tips.summary.body",
							"You can make edits to your round picks up until the start of each game"
						)}
					</ModalDesc>
				</div>

				<List>
					{tournaments.map(
						({
							id,
							awayIconType,
							homeIconType,
							homeSquadId,
							awaySquadId,
							homeSquadName,
							awaySquadName,
							isSelectedAwaySquad,
							isSelectedHomeSquad,
						}) => {
							const HomeIcon = tournamentIcons[homeIconType];
							const AwayIcon = tournamentIcons[awayIconType];

							return (
								<Tournament key={id}>
									<TournamentIcon>
										<Exist when={isSelectedHomeSquad}>
											<HomeIcon />
										</Exist>
									</TournamentIcon>

									<SquadLogo
										src={getSquadAvatarUrl(homeSquadId)}
										alt={homeSquadName}
									/>

									<SquadName>{homeSquadName}</SquadName>

									<SquadName className="reverse">{awaySquadName}</SquadName>

									<SquadLogo
										src={getSquadAvatarUrl(awaySquadId)}
										alt={awaySquadName}
									/>

									<TournamentIcon>
										<Exist when={isSelectedAwaySquad}>
											<AwayIcon />
										</Exist>
									</TournamentIcon>
								</Tournament>
							);
						}
					)}
				</List>

				<Exist when={isShowOdds}>
					<Odds />
				</Exist>

				<Buttons className={className}>
					<Button
						color="secondary"
						variant="outlined"
						fullWidth
						onClick={emailHandler}
						disabled={isLoadingEmail}>
						<Exist when={isIdleEmail}>
							{i18n.t("modal.picks_saved.email_btn", "Email me my tips")}
						</Exist>
						<Exist when={isLoadingEmail}>
							<Preloader />
						</Exist>
						<Exist when={isSentEmail}>
							{i18n.t("modal.picks_saved.email_sent_btn", "Email sent")}
						</Exist>
					</Button>

					{isMobileShare ? (
						<Button
							color="primary"
							variant="contained"
							fullWidth
							onClick={partial(shareHandler, SocialNetwork.Mobile)}>
							{i18n.t(shareKey, shareKeyDftValue)}

							<ShareIcon />
						</Button>
					) : (
						<Fragment>
							<Button
								color="primary"
								variant="contained"
								fullWidth
								onClick={partial(shareHandler, SocialNetwork.Facebook)}>
								<FacebookIcon />
							</Button>
							<Button
								color="primary"
								variant="contained"
								fullWidth
								onClick={partial(shareHandler, SocialNetwork.Twitter)}>
								<TwitterIcon />
							</Button>
						</Fragment>
					)}

					<Button
						color="primary"
						variant="contained"
						fullWidth
						component="a"
						href={downloadImage}
						download="share.png"
						disabled={!downloadImage}>
						{i18n.t("modal.picks_saved.download_btn", "Download")}

						<DownloadIcon />
					</Button>
				</Buttons>
			</Container>
		</ModalBase>
	);
});
