import React from "react";
import styled from "@emotion/styled";
import {Link} from "react-router-dom";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {footerLinks} from "data/constants/navigation";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {ReactComponent as FacebookIcon} from "assets/img/footer/facebook.svg";
import {ReactComponent as TwitterIcon} from "assets/img/footer/twitter.svg";
import {ReactComponent as InstagramIcon} from "assets/img/footer/instagram.svg";
import {ReactComponent as GeniusLogo} from "assets/img/geniusLogo.svg";
import {type IFooterController} from "./footer.controller";

const Wrapper = styled.footer`
	padding: 32px 16px 20px;
	background: ${({theme}) => theme.palette.footer.bg};
	color: ${({theme}) => theme.palette.footer.color};
	margin: 0 auto;
	width: 100%;

	@media (min-width: 769px) {
		padding: 32px;
	}
`;

const Flex = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;

	@media (min-width: 769px) {
		gap: 16px;
	}
`;

const Title = styled.div`
	font-family: "RL2", sans-serif;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0.875px;
	margin-right: auto;

	@media (min-width: 769px) {
		margin-right: 0;
	}
`;

const SocialLink = styled.a`
	&:last-of-type {
		margin-right: 12px;

		@media (min-width: 769px) {
			margin-right: 0;
		}
	}
`;

SocialLink.defaultProps = {
	target: "_blank",
	rel: "noreferrer noopener",
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 0;
	width: 100%;
	gap: 24px;
	margin-top: 16px;

	@media (min-width: 769px) {
		gap: 52px;
		flex-direction: row;
	}
`;

const Links = styled.div`
	padding-top: 14px;
	display: flex;
	margin-top: 14px;
	border-top: 1px solid rgba(255, 255, 255, 0.4);
	flex-grow: 1;
	gap: 10px;
	font-family: "Source Sans Pro", sans-serif;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.47px;
	width: 100%;
	justify-content: center;

	@media (min-width: 769px) {
		justify-content: flex-start;
		padding-top: 7px;
	}
`;

const SLink = styled(Link)`
	display: block;
	color: ${({theme}) => theme.palette.footer.linkColor};
`;

interface IFooter {
	links: typeof footerLinks;
}

export const Footer: React.FC<IFooter> = ({links}) => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	const {isFromApp} = useViewController<IFooterController>(Bindings.FooterController);

	if (isFromApp) {
		return null;
	}

	return (
		<Wrapper>
			<Flex>
				<Title>{i18n.t("footer.title", "FOLLOW NRLW TIPPING")}</Title>

				<SocialLink
					href="https://www.facebook.com/nrl"
					aria-label="Follow NRLW on Facebook">
					<FacebookIcon />
				</SocialLink>

				<SocialLink href="https://x.com/nrl" aria-label="Follow NRLW on Twitter">
					<TwitterIcon />
				</SocialLink>

				<SocialLink
					href="https://www.instagram.com/nrl"
					aria-label="Follow NRLW on Instagram">
					<InstagramIcon />
				</SocialLink>
			</Flex>

			<Container>
				<Links>
					{links.map(({locoKey, path, defaultValue}) => (
						<SLink to={path} key={locoKey}>
							{i18n.t(locoKey, defaultValue)}
						</SLink>
					))}
				</Links>

				<a
					href="https://geniussports.com/media-engagement/"
					rel="noreferrer"
					target="_blank"
					aria-label="Powered by Genius Sports">
					<GeniusLogo />
				</a>
			</Container>
		</Wrapper>
	);
};
