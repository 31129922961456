import {ChangeEvent} from "react";
import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {IRound, type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";

export interface IRoundSelectorController extends ViewController {
	readonly i18n: ILocalizationStore;

	get selectedRoundId(): number;
	get isLeftDisabled(): boolean;
	get isRightDisabled(): boolean;
	get rounds(): IRound[];

	handleRoundChangeClick: (direction: "left" | "right") => void;
	roundSelectHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

@injectable()
export class RoundSelectorController implements IRoundSelectorController {
	get isLeftDisabled() {
		return this.selectedRoundId === 1;
	}

	get isRightDisabled() {
		const roundsLength = this._roundStore.list.length;
		return roundsLength === this.selectedRoundId;
	}

	get selectedRoundId() {
		return this._roundStore.selectedRound?.id || 1;
	}

	get rounds() {
		return this._roundStore.list;
	}

	constructor(
		@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	handleRoundChangeClick = (direction: "left" | "right") => {
		if (direction === "left") {
			this._roundStore.setSelectedRoundId(this.selectedRoundId - 1);
		} else {
			this._roundStore.setSelectedRoundId(this.selectedRoundId + 1);
		}
	};

	roundSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
		const roundId = +e.target.value;
		this._roundStore.setSelectedRoundId(roundId);
	};
}
