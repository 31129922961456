import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {ISquad, type ISquadsStore} from "data/stores/squads/squads.store";
import {ILastMeetings} from "data/stores/tournament_stats/tournament_stats.store";

interface IProps {
	homeSquadId: number;
	awaySquadId: number;
}

export interface IPreviousMeetingsController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get homeSquad(): ISquad | undefined;
	get awaySquad(): ISquad | undefined;

	getScoreCN: (match: ILastMeetings, type: "home" | "away") => string;
}

@injectable()
export class PreviousMeetingsController implements IPreviousMeetingsController {
	@observable private _homeSquadId: number = 0;
	@observable private _awaySquadId: number = 0;

	get homeSquad() {
		return this._squadsStore.getSquadById(this._homeSquadId);
	}

	get awaySquad() {
		return this._squadsStore.getSquadById(this._awaySquadId);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this._homeSquadId = param.homeSquadId;
		this._awaySquadId = param.awaySquadId;
	}

	getScoreCN = (match: ILastMeetings, type: "home" | "away") => {
		const awayScore = match.awayScore ?? 0;
		const homeScore = match.homeScore ?? 0;

		if (type === "home" && homeScore > awayScore) {
			return "bold";
		}

		if (type === "away" && awayScore > homeScore) {
			return "bold";
		}

		return "";
	};
}
