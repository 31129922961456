import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {Button} from "@mui/material";
import {useViewController} from "data/services/locator";
import type {IModalErrorController} from "views/components/modals/modal_error/modal_error.controller";
import {Bindings} from "data/constants/bindings";
import {ReactComponent as ErrorIcon} from "assets/img/icons/modalRedIcon.svg";
import {Exist} from "views/components/exist/exist.component";
import {
	ModalBase,
	ModalDesc,
	ModalTitle,
} from "views/components/modals/modal_base/modal_base.component";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 18px;
	max-width: 380px;
	width: 100%;
`;

const SModalTitle = styled(ModalTitle)`
	margin-bottom: 6px;
`;

export const ModalError: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalErrorController>(
		Bindings.ModalErrorController
	);

	return (
		<ModalBase isOpen={isOpen} closeHandler={close} className="error-modal">
			<Container>
				<ErrorIcon />

				<div>
					<SModalTitle>
						{i18n.t(modalContent?.title ?? "modal.error.title", "Error")}
					</SModalTitle>

					<Exist when={modalContent?.message}>
						<ModalDesc>{modalContent?.message}</ModalDesc>
					</Exist>
				</div>

				<Button color="secondary" variant="outlined" onClick={close}>
					{i18n.t("modal.error.button", "Close")}
				</Button>
			</Container>
		</ModalBase>
	);
});
