import React, {FC, Fragment} from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {IconButton} from "@mui/material";
import {Link, NavLink} from "react-router-dom";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {HEADER_HEIGHT, HEADER_WITH_SUB_NAV_HEIGHT} from "data/constants";
import {IBurgerNavItem, ISubNavItem} from "data/types/navigation";
import {useIsMobile} from "data/hooks/useIsMobile";
import {SubNav} from "views/components/sub_nav/sub_nav.component";
import {type IHeaderController} from "views/components/header/header/header.controller";
import {BurgerNav} from "views/components/header/burger_nav/burger_nav.component";
import {MobileHeader} from "views/components/header/mobile_header/mobile_header.component";
import {Exist} from "views/components/exist/exist.component";
import {ReactComponent as MenuIcon} from "assets/img/icons/menu.svg";
import {ReactComponent as AccountIcon} from "assets/img/icons/blackAccount.svg";
import LogoutIcon from "@mui/icons-material/Logout";

const Wrapper = styled.header<{isHideSubNav: boolean}>`
	width: 100%;
	display: flex;
	flex-direction: column;
	position: sticky;
	top: 0;
	z-index: 10;
	background: ${({theme}) => theme.palette.header.bg};
	height: ${({isHideSubNav}) => (isHideSubNav ? HEADER_HEIGHT : HEADER_WITH_SUB_NAV_HEIGHT)}px;
`;

const Container = styled.div`
	display: flex;
	align-items: center;
	max-width: 1280px;
	margin: 0 auto;
`;

const Navigation = styled.div`
	padding: 10px 16px 14px;
	background: linear-gradient(
			90deg,
			#000000 0%,
			rgba(0, 0, 0, 0.5) 25%,
			rgba(255, 255, 255, 0.5) 75%,
			#ffffff 100%
		),
		#00dcba;
`;

const MenuButton = styled(IconButton)`
	margin-right: 6px;

	path {
		fill: #ffffff;
	}
`;

const NavItems = styled.div`
	display: none;
	margin-left: auto;
	gap: 16px;

	@media (min-width: 769px) {
		display: flex;
	}
`;

const NavItem = styled(NavLink)`
	display: flex;
	align-items: center;
`;

export interface IHeader {
	LogoIcon?: FC;
	subNavItems: ISubNavItem[];
	burgerNavItems: IBurgerNavItem[];
	/**
	 * Only the sticky burger menu will be displayed on the phone
	 */
	isHideMobileMenu?: boolean;
	isHideSubNav?: boolean;
}

export const Header: React.FC<IHeader> = observer(
	({LogoIcon, subNavItems, burgerNavItems, isHideMobileMenu = false, isHideSubNav = false}) => {
		const {
			isOpen,
			toggleMenu,
			closeHandler,
			isAuthorized,
			i18n,
			logoutHandler,
			isFromApp,
			isShowSideMenuOnApp,
		} = useViewController<IHeaderController>(Bindings.HeaderController);

		const {isMobile} = useIsMobile();

		if (isHideMobileMenu && isMobile) {
			return (
				<MobileHeader
					isOpen={isOpen}
					closeHandler={closeHandler}
					burgerNavItems={burgerNavItems}
					toggleMenu={toggleMenu}
				/>
			);
		}

		return (
			<>
				<Exist when={!isFromApp}>
					<Wrapper isHideSubNav={isHideSubNav || isMobile}>
						<Fragment>
							<Navigation>
								<Container>
									<MenuButton onClick={toggleMenu} aria-label="Menu">
										<MenuIcon />
									</MenuButton>

									{LogoIcon && (
										<Link to="/" aria-label="NRLW Tipping Home">
											<LogoIcon />
										</Link>
									)}

									<Exist when={isAuthorized}>
										<NavItems>
											<NavItem to="/my-account">
												<AccountIcon />

												<div>
													{i18n.t(
														"navigation.my_account.copy",
														"My Account"
													)}
												</div>
											</NavItem>

											<IconButton aria-label="Logout" onClick={logoutHandler}>
												<LogoutIcon />
											</IconButton>
										</NavItems>
									</Exist>
								</Container>
							</Navigation>

							<Exist when={!isHideSubNav && !isMobile}>
								<SubNav items={subNavItems} />
							</Exist>
						</Fragment>
						<BurgerNav
							isOpen={isOpen}
							closeHandler={closeHandler}
							items={burgerNavItems}
						/>
					</Wrapper>
				</Exist>
				<Exist when={isFromApp}>
					<Exist when={isShowSideMenuOnApp}>
						<BurgerNav
							isOpen={isOpen}
							closeHandler={closeHandler}
							items={burgerNavItems}
						/>
					</Exist>
				</Exist>
			</>
		);
	}
);
