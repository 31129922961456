import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {isNumber} from "lodash";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type IUserStore} from "data/stores/user/user.store";
import {type IStreakRanking} from "data/stores/streak_rankings/streak_rankings.store";
import {RankStatus} from "data/enums";

interface IProps {
	ranking: IStreakRanking;
	isShowRankStatus: boolean;
}

export interface IStreakRankingsTableRowController extends ViewController<IProps> {
	get className(): string;
	get rankStatus(): RankStatus | null;
	get lastNameInitial(): string;

	getValue: (value: number | null) => string | number;
	getRankValue: (value: number | null) => string;
}

@injectable()
export class StreakRankingsTableRowController implements IStreakRankingsTableRowController {
	@observable private _isShowRankStatus: boolean = false;
	@observable private _ranking: IStreakRanking | null = null;

	get className() {
		return this.isMe ? "me" : "";
	}

	private get isMe() {
		return this._userStore.user?.id === this._ranking?.userId;
	}

	get rankStatus() {
		const longestStreak = this._ranking?.longestStreak;
		const previousLongestStreak = this._ranking?.previousLongestStreak;
		// For first round, we don't show any status
		if (!this._isShowRankStatus) {
			return null;
		}
		// if user don't have rank, we show SAME status
		if (!isNumber(longestStreak)) {
			return RankStatus.SAME;
		}
		// if user don't have lastRank, but have rank, we show UP status
		if (!isNumber(previousLongestStreak)) {
			return RankStatus.UP;
		}

		if (longestStreak === previousLongestStreak) {
			return RankStatus.SAME;
		}

		return previousLongestStreak < longestStreak ? RankStatus.UP : RankStatus.DOWN;
	}

	get lastNameInitial() {
		return this._ranking?.lastName?.at(0) || "";
	}

	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this.onChange(param);
	}

	onChange(param: IProps) {
		this._ranking = param.ranking;
		this._isShowRankStatus = param.isShowRankStatus;
	}

	getValue = (value: number | null) => value ?? "-";

	getRankValue = (value: number | null) =>
		isNumber(value) ? value.toLocaleString("en-US") : "-";
}
