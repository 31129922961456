import React from "react";
import {Avatar as AvatarComponent} from "@mui/material";
import {getLeagueAvatarUrl} from "data/utils/avatar";
import defaultAvatar from "assets/img/icons/avatar.svg";

interface IAvatar {
	leagueId: number;
	avatarVersion: number;
	className?: string;
	alt?: string;
}

export const LeagueAvatar: React.FC<IAvatar> = ({
	className,
	alt = "Avatar",
	leagueId,
	avatarVersion,
}) => {
	if (avatarVersion) {
		return (
			<AvatarComponent
				alt={alt}
				src={getLeagueAvatarUrl(leagueId, avatarVersion)}
				className={className}
				sx={{width: 52, height: 52}}>
				<img src={defaultAvatar} alt="" />
			</AvatarComponent>
		);
	}

	return (
		<AvatarComponent
			alt={alt}
			src={defaultAvatar}
			className={className}
			sx={{width: 52, height: 52}}
		/>
	);
};
