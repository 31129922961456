import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {type IStreakPredictionsStore} from "data/stores/streak_predictions/streak_predictions.store";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RoundStatus} from "data/enums";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type IBannerStore} from "data/stores/banner/banner.store";

interface IProps {
	isMobile: boolean;
	isLoading: boolean;
}

export interface IStreakTopActionsController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get roundStatus(): RoundStatus | undefined;
	get isScheduled(): boolean;
	get isShowShareButton(): boolean;
	get isShowReviewDesktopTipsButton(): boolean;
	get isShowReviewMobileTipsButton(): boolean;
	get isMobile(): boolean;
	get isShowBanner(): boolean;
	get isDisabledReviewTipsButton(): boolean;

	reviewTipsHandler: () => void;
}

@injectable()
export class StreakTopActionsController implements IStreakTopActionsController {
	@observable private _isMobile = false;
	@observable private _isLoading = false;

	get roundStatus() {
		return this._roundStore.selectedRound?.status;
	}

	get isScheduled() {
		return this.roundStatus === RoundStatus.Scheduled;
	}

	get isShowShareButton() {
		// return !this.isScheduled; // TODO update logic
		return false;
	}

	get isDisabledReviewTipsButton() {
		return this._isLoading;
	}

	get isShowReviewDesktopTipsButton() {
		return this._streakPredictionsStore.hasPredictions && !this._isMobile; // TODO update logic
	}

	get isShowReviewMobileTipsButton() {
		return this._streakPredictionsStore.hasPredictions && this._isMobile; // TODO update logic
	}

	get isMobile() {
		return this._isMobile;
	}

	get isShowBanner() {
		return this._bannerStore.streakBannerType !== null;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.StreakPredictionsStore)
		private _streakPredictionsStore: IStreakPredictionsStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore,
		@inject(Bindings.BannerStore) private _bannerStore: IBannerStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this.onChange(param);
	}

	onChange(param: IProps) {
		this._isMobile = param.isMobile;
		this._isLoading = param.isLoading;
	}

	reviewTipsHandler = () => {
		this._modalsStore.showModal(ModalType.STREAK_PREDICTIONS_SAVED);
	};
}
