import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITutorialStore} from "data/stores/tutorial/tutorial.store";
import {type IUserStore} from "data/stores/user/user.store";

export interface ITutorialStepController extends ViewController {
	readonly i18n: ILocalizationStore;

	close: () => void;
	next: () => void;
	prev: () => void;
	reset: () => void;
}

@injectable()
export class TutorialStepController implements ITutorialStepController {
	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isOpen() {
		return this._tutorialStore.isOpen;
	}

	get activeStep() {
		return this._tutorialStore.activeStep;
	}

	setIsOpen = (isOpen: boolean) => {
		this._tutorialStore.setIsOpen(isOpen);
	};

	setActiveStep = (step: number) => {
		this._tutorialStore.setActiveStep(step);
	};

	close = () => {
		this._tutorialStore.setIsOpen(false);
	};

	next = () => {
		this._tutorialStore.setActiveStep(this.activeStep + 1);
	};

	prev = () => {
		this._tutorialStore.setActiveStep(this.activeStep - 1);
	};

	reset = () => {
		this._tutorialStore.setActiveStep(1);
	};
}
