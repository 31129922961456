import React, {FC, Fragment} from "react";
import styled from "@emotion/styled";
import {NavLink} from "react-router-dom";
import {Collapse, IconButton} from "@mui/material";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {IBurgerNavSubItem} from "data/types/navigation";
import {Exist} from "views/components/exist/exist.component";
import {IBurgerNavLinkController} from "./burger_nav_link.controller";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const Link = styled(NavLink)`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	color: ${({theme}) => theme.palette.header.burgerMenuLinkColor};
	min-height: 40px;
	padding: 0 8px;
	font-family: "RL2", sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 12px;
	letter-spacing: 3px;
`;

const SubLinks = styled.div`
	display: flex;
	flex-direction: column;
`;

const SubLink = styled(NavLink)`
	display: flex;
	align-items: center;
	color: ${({theme}) => theme.palette.header.burgerMenuLinkColor};
	min-height: 40px;
	padding: 0 8px 0 16px;
	font-family: "RL2", sans-serif;
	font-size: 14px;
	line-height: 12px;
	letter-spacing: 3px;
`;

const ArrowButton = styled(IconButton)`
	color: ${({theme}) => theme.palette.header.burgerMenuLinkColor};
`;

interface IBurgerNavLink {
	locoKey: string;
	path: string;
	subLinks?: IBurgerNavSubItem[];
	defaultValue: string;
	closeHandler: () => void;
}

export const BurgerNavLink: FC<IBurgerNavLink> = observer(
	({locoKey, path, subLinks = [], closeHandler, defaultValue}) => {
		const {i18n, isOpen, toggleClick} = useViewController<IBurgerNavLinkController>(
			Bindings.BurgerNavLinkController
		);

		return (
			<Fragment>
				<Link to={path} key={locoKey} onClick={closeHandler}>
					<div>{i18n.t(locoKey, defaultValue)}</div>

					<Exist when={subLinks.length}>
						<ArrowButton onClick={toggleClick} aria-label="Show more Options">
							{isOpen ? <ExpandLess /> : <ExpandMore />}
						</ArrowButton>
					</Exist>
				</Link>

				<Exist when={subLinks.length}>
					<SubLinks>
						<Collapse in={isOpen} timeout="auto" unmountOnExit>
							{subLinks.map(({locoKey, path, defaultValue}) => (
								<SubLink to={path} key={locoKey} onClick={closeHandler}>
									{i18n.t(locoKey, defaultValue)}
								</SubLink>
							))}
						</Collapse>
					</SubLinks>
				</Exist>
			</Fragment>
		);
	}
);
