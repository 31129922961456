import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {type IGameBar} from "data/types/game";
import {Bindings} from "data/constants/bindings";
import {type IRankingApiProvider} from "data/providers/api/ranking.api.provider";

export interface IGameBarStore {
	get gameBar(): IGameBar;

	fetchGameBar: (roundId?: number) => Promise<void>;
	clearStore: () => void;
}

@injectable()
export class GameBarStore implements IGameBarStore {
	@observable private _gameBar: IGameBar = {
		roundRank: null,
		roundPoints: null,
		overallPoints: null,
		overallRank: null,
		prevOverallRank: null,
		tips: 0,
		tipsCount: 0,
		rankRoundId: 0,
		correctTips: 0,
	};

	get gameBar() {
		return this._gameBar;
	}

	constructor(@inject(Bindings.RankingApiProvider) private _rankingApi: IRankingApiProvider) {
		makeAutoObservable(this);
	}

	fetchGameBar = async (roundId?: number) => {
		const {data} = await this._rankingApi.gameBar({roundId});

		runInAction(() => {
			this._gameBar = data.success;
		});
	};

	clearStore = () => {
		this._gameBar = {
			roundRank: null,
			roundPoints: null,
			overallPoints: null,
			overallRank: null,
			prevOverallRank: null,
			tips: 0,
			tipsCount: 0,
			rankRoundId: 0,
			correctTips: 0,
		};
	};
}
