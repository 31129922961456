import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ISquad, ISquadsStore} from "data/stores/squads/squads.store";
import type {IState, IStatesStore} from "data/stores/states/states.store";
import type {IMonth, IMonthsStore} from "data/stores/months/months.store";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {sortBy} from "lodash";

export interface IRankingsFiltersController extends ViewController {
	readonly i18n: ILocalizationStore;

	get rounds(): IRound[];
	get squads(): ISquad[];
	get states(): IState[];
	get months(): IMonth[];
}

@injectable()
export class RankingsFiltersController implements IRankingsFiltersController {
	get rounds() {
		return this._roundsStore.completedRounds;
	}

	get squads() {
		return sortBy(this._squadsStore.regList, "name");
	}

	get states() {
		return this._statesStore.list;
	}

	get months() {
		return this._monthsStore.list;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.StatesStore) private _statesStore: IStatesStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.MonthsStore) private _monthsStore: IMonthsStore
	) {
		makeAutoObservable(this);
	}
}
