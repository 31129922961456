import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType, SocialNetwork} from "data/enums";
import type {IModalsStore, IModalPayload} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

export interface IModalShareController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get modalContent(): IModalPayload | null;

	shareViaFB: () => void;
	shareViaTW: () => void;
	close: () => void;
}

@injectable()
export class ModalShareController implements IModalShareController {
	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.SHARE;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	shareViaFB = () => {
		if (this.modalContent?.callback) {
			this.modalContent.callback(SocialNetwork.Facebook);
		}
	};

	shareViaTW = () => {
		if (this.modalContent?.callback) {
			this.modalContent.callback(SocialNetwork.Twitter);
		}
	};

	close = () => {
		this._modalsStore.hideModal();
	};
}
