import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {IUser, type IUserStore} from "data/stores/user/user.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";

export interface ITipsProfileController extends ViewController {
	readonly i18n: ILocalizationStore;

	get user(): IUser;
}

@injectable()
export class TipsProfileController implements ITipsProfileController {
	get user() {
		return this._userStore.user!;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}
}
