import {AxiosRequestConfig} from "axios";
import {HttpClientService} from "data/services/http/http_client.service";

export abstract class HttpClientFactory {
	static createApiClient(config: AxiosRequestConfig) {
		// You can add interceptors here to adjust or modify any of requests/responses values.
		return new HttpClientService(config);
	}

	static createJSONClient(config: AxiosRequestConfig) {
		// You can add interceptors here to adjust or modify any of requests/responses values.
		return new HttpClientService(config);
	}
}
