import React from "react";
import {Button} from "@mui/material";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {
	ModalBase,
	ModalDesc,
	ModalTitle,
} from "views/components/modals/modal_base/modal_base.component";
import {LeagueAvatar} from "views/components/league_avatar/league_avatar.component";
import {IModalJoinLeagueInviteController} from "./modal_join_league_invite.controller";
import {ReactComponent as ModalIcon} from "assets/img/icons/modalProgress.svg";
import {Exist} from "views/components/exist/exist.component";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 18px;
	max-width: 380px;
	width: 100%;
`;

const SModalTitle = styled(ModalTitle)`
	margin-bottom: 6px;
`;

const Details = styled.div`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 100%;
	padding: 16px 12px 22px;
	gap: 16px;
	border-radius: 4px;
	background: #f6f6f6;
	margin-top: 6px;
`;

const DetailsLabel = styled.div`
	color: #000;
	text-align: center;
	font-size: 16px;
	font-weight: 500;
	line-height: 18px;
`;

const DetailsText = styled.div`
	margin-bottom: 6px;
`;

export const ModalJoinLeagueInvite: React.FC = observer(() => {
	const {isOpen, i18n, close, league, joinHandler, isDisabled, isJoined, viewLadderHandler} =
		useViewController<IModalJoinLeagueInviteController>(
			Bindings.ModalJoinLeagueInviteController,
			{navigate: useNavigate()}
		);

	if (!league) {
		return null;
	}

	return (
		<ModalBase isOpen={isOpen} closeHandler={close} disableEnforceFocus>
			<Container>
				<ModalIcon />

				<div>
					<Exist when={!isJoined}>
						<SModalTitle>
							{i18n.t(
								"modal.league_invite.title",
								"Congrats, you’ve been invited to join a tipping league!"
							)}
						</SModalTitle>
					</Exist>
					<Exist when={isJoined}>
						<SModalTitle>
							{i18n.t(
								"modal.league_invite_join.title",
								"Congrats, you have already been added to this League"
							)}
						</SModalTitle>
					</Exist>

					<ModalDesc>{league.name}</ModalDesc>

					<Details>
						<DetailsLabel>
							{i18n.t("modal.league_invite.league_details", "League Details")}
						</DetailsLabel>
						<LeagueAvatar leagueId={league.id} avatarVersion={league.avatarVersion} />

						<DetailsLabel>
							{i18n.t("modal.league_invite.manager", "Manager")}
						</DetailsLabel>
						<DetailsText>{league?.leagueManager?.displayName}</DetailsText>

						<DetailsLabel>
							{i18n.t("modal.league_invite.league_privacy", "Privacy Settings")}
						</DetailsLabel>
						<DetailsText>
							{i18n.t(
								`modal.league_invite.league_privacy_${league.privacy}`,
								league.privacy
							)}
						</DetailsText>

						<DetailsLabel>
							{i18n.t("modal.league_invite.league_start_round", "Start Round")}
						</DetailsLabel>
						<DetailsText>
							{i18n.t("modal.league_invite.league_round", "Round")} {league.startId}
						</DetailsText>
					</Details>
				</div>

				<Exist when={!isJoined}>
					<Button
						color="primary"
						variant="contained"
						onClick={joinHandler}
						disabled={isDisabled}>
						{i18n.t("modal.league_invite.button", "Join Now")}
					</Button>
				</Exist>
				<Exist when={isJoined}>
					<Button color="primary" variant="contained" onClick={viewLadderHandler}>
						{i18n.t("modal.league_invite_join.button", "View Ladder")}
					</Button>
				</Exist>
			</Container>
		</ModalBase>
	);
});
