import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {IBurgerNavItem, ISubNavItem} from "data/types/navigation";
import {burgerMenuLinks, streakSubNavigation} from "data/constants/navigation";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IUserStore} from "data/stores/user/user.store";

export interface IStreakLayoutController extends ViewController {
	subNavItems: ISubNavItem[];
	burgerNavItems: IBurgerNavItem[];
}

@injectable()
export class StreakLayoutController implements IStreakLayoutController {
	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get subNavItems() {
		return streakSubNavigation.filter(({isPrivate}) => (isPrivate ? this.isAuthorized : true));
	}

	get burgerNavItems() {
		return burgerMenuLinks.filter(({isPrivate}) => (isPrivate ? this.isAuthorized : true));
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}
}
