import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeagues, ILeaguesStore, IRecoverLeague} from "data/stores/leagues/leagues.store";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState, RoundStatus} from "data/enums";
import {isEmpty, isEqual, partial} from "lodash";
import {extractErrorMessage} from "data/utils";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";

const PAGE_LIMIT = 20;

export interface IMyLeaguesController extends ViewController {
	readonly i18n: ILocalizationStore;

	get myLeagues(): ILeagues;
	get hasMyLeagues(): boolean;
	get isLoadingMyLeagues(): boolean;
	get leaguesForJoin(): ILeagues;
	get hasLeaguesForJoin(): boolean;
	get isLoadingLeaguesForJoin(): boolean;
	get leagueInvites(): ILeagues;
	get isLoadingLeagueInvites(): boolean;
	get hasLeagueInvites(): boolean;
	get recoverLeagues(): IRecoverLeague[];
	get isLoading(): boolean;
	get hasRecover(): boolean;
	get isScoring(): boolean;

	loadMoreMyLeagues: () => void;
	loadMoreLeaguesForJoin: () => void;
	loadMoreLeagueInvites: () => void;
}

@injectable()
export class MyLeaguesController implements IMyLeaguesController {
	@observable _requestStates = {
		general: RequestState.PENDING,
		joinLeagues: RequestState.IDLE,
		myLeagues: RequestState.IDLE,
		invites: RequestState.IDLE,
	};

	get myLeagues() {
		return this._leaguesStore.myLeagues;
	}

	get hasMyLeagues() {
		return !isEmpty(this.myLeagues.leagues);
	}

	get isLoadingMyLeagues() {
		return isEqual(this._requestStates.myLeagues, RequestState.PENDING);
	}

	get leaguesForJoin() {
		return this._leaguesStore.leaguesForJoin;
	}

	get hasLeaguesForJoin() {
		return !isEmpty(this.leaguesForJoin.leagues);
	}

	get isLoadingLeaguesForJoin() {
		return isEqual(this._requestStates.joinLeagues, RequestState.PENDING);
	}

	get leagueInvites() {
		return this._leaguesStore.leagueInvites;
	}

	get hasLeagueInvites() {
		return !isEmpty(this.leagueInvites.leagues);
	}

	get isLoadingLeagueInvites() {
		return isEqual(this._requestStates.invites, RequestState.PENDING);
	}

	get recoverLeagues() {
		return this._leaguesStore.recoverLeagues;
	}

	get hasRecover() {
		return !isEmpty(this.recoverLeagues);
	}

	get isLoading() {
		return isEqual(this._requestStates.general, RequestState.PENDING);
	}

	get isScoring() {
		return Boolean(this._roundsStore.list.find(({status}) => status !== RoundStatus.Scheduled));
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	loadMoreMyLeagues = () => {
		this.setPendingState("myLeagues");

		this._leaguesStore
			.fetchMoreMyLeagues({limit: PAGE_LIMIT})
			.then(() => this.createSuccessHandlerOf("myLeagues"))
			.catch(partial(this.createErrorHandlerOf, "myLeagues"));
	};

	loadMoreLeaguesForJoin = () => {
		this.setPendingState("joinLeagues");

		this._leaguesStore
			.fetchMoreLeaguesForJoin()
			.then(() => this.createSuccessHandlerOf("joinLeagues"))
			.catch(partial(this.createErrorHandlerOf, "joinLeagues"));
	};

	loadMoreLeagueInvites = () => {
		this.setPendingState("invites");

		this._leaguesStore
			.fetchMoreLeagueInvites()
			.then(() => this.createSuccessHandlerOf("invites"))
			.catch(partial(this.createErrorHandlerOf, "invites"));
	};

	private setPendingState = (type: keyof typeof this._requestStates) => {
		this._requestStates[type] = RequestState.PENDING;
	};

	private createSuccessHandlerOf = (type: keyof typeof this._requestStates) => {
		this._requestStates[type] = RequestState.SUCCESS;
	};

	private createErrorHandlerOf = (
		type: keyof typeof this._requestStates,
		error: AxiosError<IApiResponse>
	) => {
		this._requestStates[type] = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action init() {
		Promise.all([
			this._leaguesStore.fetchMyLeagues({limit: PAGE_LIMIT}),
			this._leaguesStore.fetchLeagueInvites(),
			// No need for first season - TODO: uncomment for next season
			// this._leaguesStore.fetchRecoverLeagues(),
			this._roundsStore.fetchRounds(),
		])
			.then(() => this.createSuccessHandlerOf("general"))
			.catch(partial(this.createErrorHandlerOf, "general"));
	}

	dispose() {
		this._leaguesStore.clearStore();
	}
}
