import React, {Fragment, ReactNode} from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IWebViewGateController} from "views/components/web_view_gate/web_view_gate.controller";
import {useNavigate, useSearchParams} from "react-router-dom";
import {PagePreloader} from "views/components/preloader";

export const WebViewGate: React.FC<{children: ReactNode}> = observer(({children}) => {
	const [searchParams] = useSearchParams();

	const {checked} = useViewController<IWebViewGateController>(Bindings.WebViewGateController, {
		searchParams,
		navigate: useNavigate(),
	});

	return checked ? <Fragment>{children}</Fragment> : <PagePreloader />;
});
