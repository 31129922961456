import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {IAutoPick} from "data/types/game";
import {AutoPickSponsorType} from "data/enums";
import {autoPickList} from "data/constants/game";
import {Bindings} from "data/constants/bindings";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IAutoPickStore {
	get autoPickList(): IAutoPick[];
	get registrationList(): IAutoPick[];

	getAutoPickList: (list: IAutoPick[], isShowSponsor: boolean) => IAutoPick[];
}

@injectable()
export class AutoPickStore implements IAutoPickStore {
	constructor(@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore) {
		makeAutoObservable(this);
	}

	get autoPickList() {
		return autoPickList.filter(({isSeasonStarted}) => {
			if (!this._roundsStore.isSeasonStarted) {
				return !isSeasonStarted;
			}

			return true;
		});
	}

	get registrationList() {
		return autoPickList;
	}

	getAutoPickList(list: IAutoPick[], isShowSponsor: boolean) {
		if (isShowSponsor) {
			return list.filter(({sponsorType}) => sponsorType !== AutoPickSponsorType.NO_SPONSOR);
		}

		return list.filter(({sponsorType}) => sponsorType !== AutoPickSponsorType.SPORTS_BET);
	}
}
