import React from "react";
import {Navigate, Outlet, RouteProps} from "react-router-dom";
import {useLocation} from "react-router";
import {useViewController} from "data/services/locator";
import {IAuthController} from "views/controllers/auth/auth.controller";
import {observer} from "mobx-react";
import {Bindings} from "data/constants/bindings";
import {FooterNav} from "views/components/footer_nav/footer_nav.component";
import {ITippingLayoutController} from "views/components/layouts/tipping_layout/tipping_layout.controller";
import {StyledLogoIcon} from "views/components/layouts/tipping_layout/tipping_layout.component";
import styled from "@emotion/styled";

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
`;

export const PrivateRoute: React.FC<RouteProps> = observer(() => {
	const {isAuthorized, wasLoggedOut} = useViewController<IAuthController>(
		Bindings.AuthController
	);
	const {subNavItems, burgerNavItems} = useViewController<ITippingLayoutController>(
		Bindings.TippingLayoutController
	);
	const location = useLocation();

	if (isAuthorized) {
		return (
			<Wrapper>
				<Outlet />
				<FooterNav
					LogoIcon={StyledLogoIcon}
					subNavItems={subNavItems}
					burgerNavItems={burgerNavItems}
				/>
			</Wrapper>
		);
	}

	const state = wasLoggedOut ? {} : {from: location};

	return <Navigate to="/" state={state} replace />;
});
