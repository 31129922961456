import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IUserStore} from "data/stores/user/user.store";
import {LOGOUT_LINK} from "data/constants";
import {burgerMenuLinks} from "data/constants/navigation";
import {IBurgerNavItem} from "data/types/navigation";
import {type IWebViewGateStore} from "data/stores/web_view_gate/web_view_gate.store";

export interface IBurgerNavController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isAuthorized(): boolean;
	get isFromApp(): boolean;
	get navItems(): IBurgerNavItem[];

	logout(): Promise<void>;

	nrlTippingRedirect(): void;

	nrlDashboardRedirect(): void;
}

@injectable()
export class BurgerNavController implements IBurgerNavController {
	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get navItems() {
		return burgerMenuLinks.filter(({isPrivate}) =>
			isPrivate ? this._userStore.isAuthorized : true
		);
	}

	get isFromApp() {
		return this._webViewGateStore.isFromApp;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.WebViewGateStore) private _webViewGateStore: IWebViewGateStore
	) {
		makeAutoObservable(this);
	}

	logout = async () => {
		if (this._webViewGateStore.isFromApp) {
			const appMessage = {
				action: "navigation",
				path: "nrlw/logout",
			};

			this._webViewGateStore.sendPostMessage(appMessage);
		}

		await this._userStore.logout();
		window.location.assign(LOGOUT_LINK as string);
	};

	nrlTippingRedirect = () => {
		if (this._webViewGateStore.isFromApp) {
			const appMessage = {
				action: "navigation",
				path: "nrl/tipping/tips",
			};

			this._webViewGateStore.sendPostMessage(appMessage);
		} else {
			window.location.assign("https://tipping.nrl.com/my-tips");
		}
	};

	nrlDashboardRedirect = () => {
		if (this._webViewGateStore.isFromApp) {
			const appMessage = {
				action: "navigation",
				path: "nrl/dashboard",
			};

			this._webViewGateStore.sendPostMessage(appMessage);
		} else {
			window.location.assign("https://tipping.nrl.com/");
		}
	};
}
