import {isEqual} from "lodash";
import type {AxiosError} from "axios";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type ISquadsStore} from "data/stores/squads/squads.store";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {type IUserStore} from "data/stores/user/user.store";
import {type IModalCompleteRoundStore} from "data/stores/modals/modal_complete_round.store";
import {type IGameBarStore} from "data/stores/game_bar/game_bar.store";
import {TUTORIAL_STORAGE_KEY} from "data/constants/game";
import {type ITutorialStore} from "data/stores/tutorial/tutorial.store";
import {type ITournamentStatsStore} from "data/stores/tournament_stats/tournament_stats.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";

export interface IMyTipsController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isLoading(): boolean;
	get isPredictionLoading(): boolean;
}

@injectable()
export class MyTipsController implements IMyTipsController {
	@observable _requestState = RequestState.IDLE;
	@observable _predictionRequestState = RequestState.IDLE;
	@observable _fetchPredictionDisposer?: ReturnType<typeof reaction>;

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isPredictionLoading() {
		return isEqual(this._predictionRequestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.ModalCompleteRoundStore)
		private _modalCompleteRoundStore: IModalCompleteRoundStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.GameBarStore) private _gameBarStore: IGameBarStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.TournamentStatsStore) private _tournamentStatsStore: ITournamentStatsStore
	) {
		makeAutoObservable(this);
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private showErrorModal = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this.showErrorModal(error);
	};

	@action private showRoundResultModal = async () => {
		const lastCompleteRoundId = this._roundsStore.lastCompleteRound?.id;
		const userId = this._userStore.user?.id;

		if (!lastCompleteRoundId || !userId) {
			return;
		}

		const isModalShowed = localStorage.getItem(`result-modal-${userId}-${lastCompleteRoundId}`);

		if (isModalShowed) {
			return;
		}

		this._modalCompleteRoundStore.setRoundId(lastCompleteRoundId);

		await Promise.all([
			this._modalCompleteRoundStore.fetchPredictions(),
			this._modalCompleteRoundStore.fetchGameBar(),
		]);

		const userHasManualTips = this._modalCompleteRoundStore.predictions.some(
			({isAuto}) => !isAuto
		);

		if (!userHasManualTips) {
			return;
		}

		this._modalCompleteRoundStore.showModal();

		localStorage.setItem(`result-modal-${userId}-${lastCompleteRoundId}`, "true");

		return true;
	};

	private showTutorialModal = () => {
		const userId = this._userStore.user?.id || "";

		const isUserViewedTeamTutorial = JSON.parse(
			localStorage.getItem(`${TUTORIAL_STORAGE_KEY}-${userId}`) || "false"
		) as boolean;

		if (isUserViewedTeamTutorial) return;

		this._tutorialStore.setIsOpen(true);
		localStorage.setItem(`${TUTORIAL_STORAGE_KEY}-${userId}`, JSON.stringify(true));
	};

	@action
	async init() {
		try {
			await Promise.all([this._squadsStore.fetchSquads(), this._roundsStore.fetchRounds()]);

			this._fetchPredictionDisposer = reaction(
				() => this._roundsStore.selectedRound?.id,
				(roundId) => {
					if (!roundId || this.isLoading) return;

					// Scroll to top of page on round change
					window.scrollTo(0, 0);

					this._predictionsStore.clearStore();
					this._predictionRequestState = RequestState.PENDING;

					this._gameBarStore.fetchGameBar(roundId).catch((error) => {
						this.showErrorModal(error as AxiosError<IApiResponse>);
					});

					Promise.all([
						this._roundsStore.fetchRounds(),
						this._predictionsStore.fetchPredictions(roundId),
					])
						.then(([_, isSuccess]) => {
							if (isSuccess) {
								runInAction(() => {
									this._predictionRequestState = RequestState.SUCCESS;
								});
							}
						})
						.catch((error) => {
							this._predictionRequestState = RequestState.ERROR;
							this.showErrorModal(error as AxiosError<IApiResponse>);
						});
				},
				{fireImmediately: true}
			);

			this.onSuccess();

			const isShow = await this.showRoundResultModal();

			if (!isShow) {
				this.showTutorialModal();
			}
		} catch (err) {
			this.onError(err as AxiosError<IApiResponse>);
		}
	}

	dispose() {
		this._fetchPredictionDisposer?.();
		this._predictionsStore.clearStore();
		this._modalCompleteRoundStore.hideModal();
		this._gameBarStore.clearStore();
		this._tournamentStatsStore.clearStore();
		this._roundsStore.clearStore();
		this._tutorialStore.clearStore();
	}
}
