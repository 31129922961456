import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {DateTime} from "luxon";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {RoundStatus} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {type IUserStore} from "data/stores/user/user.store";
import {type ILandingController} from "views/pages/landing/landing.controller";
import {ITippingGameBarNRLT} from "data/types/game";
import {IStreakGameBar} from "data/stores/streak_game_bar/streak_game_bar.store";

export interface IStreakGameController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isAuthorized(): boolean;
	get currentRoundId(): number;
	get isShowScheduledBlock(): boolean;
	get currentRoundStartDate(): string;
	get isPlaying(): boolean;
	get isComplete(): boolean;
	get tippingNRLTGamebar(): ITippingGameBarNRLT;
	get isUserRegistered(): boolean;
	get streakGameBar(): IStreakGameBar;
}

@injectable()
export class StreakGameController implements IStreakGameController {
	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get currentRoundId() {
		return this.tippingNRLTGamebar.rankRoundId || 1;
	}

	get currentRoundStartDate() {
		return this.tippingNRLTGamebar.rankRoundStartDate || "";
	}

	get isScheduled() {
		return this.tippingNRLTGamebar?.rankRoundStatus === RoundStatus.Scheduled;
	}

	get isShowScheduledBlock() {
		if (!this.currentRoundStartDate) {
			return false;
		}

		const startDate = DateTime.fromISO(this.currentRoundStartDate);
		return this.isScheduled && startDate > DateTime.now();
	}

	get isPlaying() {
		return this.tippingNRLTGamebar?.rankRoundStatus === RoundStatus.Playing;
	}

	get isComplete() {
		return this.tippingNRLTGamebar?.rankRoundStatus === RoundStatus.Complete;
	}

	get tippingNRLTGamebar() {
		return this._landController.tippingNRLTGamebar;
	}

	get isUserRegistered() {
		return Boolean(this._landController.isUserRegistered);
	}

	get streakGameBar() {
		return this._landController.streakGameBar;
	}

	constructor(
		@inject(Bindings.RoundsStore) private _roundStore: IRoundsStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LandingController) private _landController: ILandingController
	) {
		makeAutoObservable(this);
	}
}
