import React from "react";
import {debounce, partial} from "lodash";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {ButtonPure} from "views/components/buttons/buttons.component";
import {Exist} from "views/components/exist/exist.component";
import {CardPredictorStats} from "views/components/tipping_card/card_predictor/card_predictor_stats/card_predictor_stats.component";
import {ITippingCardController} from "./tipping_card.controller";
import {Odds} from "./odds/odds.component";
import {ReactComponent as ArrowLeft} from "assets/img/icons/arrowLeft.svg";
import {ReactComponent as ArrowRight} from "assets/img/icons/arrowRight.svg";
import {CardPredictor} from "./card_predictor/card_predictor.component";
import {CardBanner} from "./card_banner/card_banner.component";

const Wrapper = styled.div`
	width: 100%;
`;

export const CardWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 6px;
	background: ${({theme}) => theme.palette.tipsCard.bg};
	padding: 10px 0 6px;
	border-radius: 4px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);

	@media (min-width: 375px) {
		padding: 10px 8px 6px;
	}

	@media (min-width: 769px) {
		padding: 10px 24px 8px;
	}

	&.with-banner {
		border-radius: 0 0 4px 4px;
	}
`;

export const DateSection = styled.div`
	width: 100%;
	color: ${({theme}) => theme.palette.tipsCard.dateColor};
	text-align: center;
	font-size: 11px;
	font-weight: 500;
	line-height: 14px;

	@media (min-width: 769px) {
		font-size: 14px;
	}
`;

export const Container = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 122px 1fr;
	align-items: center;
	justify-content: space-between;

	@media (min-width: 769px) {
		grid-template-columns: 1fr 150px 1fr;
	}
`;

const MarginWrapper = styled.div`
	width: 100%;
	padding: 12px 16px;
	background: ${({theme}) => theme.palette.tipsCard.marginBg};
	border-radius: 0 0 4px 4px;
`;

const MarginContainer = styled.div`
	display: flex;
	align-items: center;
	background: ${({theme}) => theme.palette.tipsCard.bg};
	border-radius: 4px;
	overflow: hidden;
	border: 1px solid var(--secondary-grey-3, #e6e6e6);
	max-width: 368px;
	margin: 0 auto;
`;

const Margin = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	height: 48px;
`;

const MarginValue = styled.input`
	font-size: 16px;
	font-weight: 500;
	text-align: center;
	border: none;
	outline: none;
	padding: 0;
	height: 18px;
	color: #000;
	width: 20px;

	&::placeholder {
		color: #000;
		opacity: 1; /* Firefox */
	}
`;

const ArrowButton = styled(ButtonPure)`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 48px;
	width: 48px;
	transition: background-color 0.3s ease-in-out;

	&:first-child {
		border-right: 1px solid var(--secondary-grey-3, #e6e6e6);
	}

	&:last-child {
		border-left: 1px solid var(--secondary-grey-3, #e6e6e6);
	}

	&:disabled {
		cursor: initial;
	}

	&:not(:disabled):hover {
		background: #f7f7f7;
	}
`;

interface ITippingCard {
	tournamentId: number;
}

export const TippingCard: React.FC<ITippingCard> = observer(({tournamentId}) => {
	const {
		i18n,
		tournament,
		changeHandler,
		isShowMargin,
		margin,
		isLeftMarginArrowBlocked,
		isRightMarginArrowBlocked,
		changeMargin,
		isShowOdds,
		onChangeMargin,
		isMarginDisabled,
		inputRef,
		onClickMargin,
		openStatsHandler,
		gameBannerType,
		className,
		isShowMarginArrows,
	} = useViewController<ITippingCardController>(Bindings.TippingCardController, {tournamentId});

	if (!tournament) {
		return null;
	}

	const debouncedChangeHandler = debounce(changeHandler, 300, {
		leading: true,
		trailing: false,
	});

	const {date, homeOdds, awayOdds} = tournament;

	return (
		<Wrapper>
			<CardBanner type={gameBannerType} />

			<CardWrapper className={className}>
				<DateSection>{date}</DateSection>

				<Container>
					<CardPredictor
						tournament={tournament}
						changeHandler={debouncedChangeHandler}
						statsClickHandler={openStatsHandler}
						StatsComponent={CardPredictorStats}
					/>
				</Container>

				<Exist when={isShowOdds}>
					<Container>
						<Odds awayOdds={awayOdds?.price} homeOdds={homeOdds?.price} />
					</Container>
				</Exist>
			</CardWrapper>

			<Exist when={isShowMargin}>
				<MarginWrapper>
					<MarginContainer>
						<Exist when={isShowMarginArrows}>
							<ArrowButton
								disabled={isLeftMarginArrowBlocked}
								onClick={partial(changeMargin, "left")}
								aria-label="Lower Margin">
								<ArrowLeft />
							</ArrowButton>
						</Exist>

						<Margin onClick={onClickMargin}>
							<Exist when={tournament.isScheduled}>
								<div>{i18n.t("tips.margin.select", "Select Your Margin")}</div>
							</Exist>

							<Exist when={!tournament.isScheduled}>
								<div>{i18n.t("tips.margin.selected", "Your Margin")}</div>
							</Exist>

							<MarginValue
								ref={inputRef}
								disabled={isMarginDisabled}
								type="text"
								value={margin}
								onChange={onChangeMargin}
								placeholder="-"
								maxLength={2}
							/>
						</Margin>

						<Exist when={isShowMarginArrows}>
							<ArrowButton
								onClick={partial(changeMargin, "right")}
								disabled={isRightMarginArrowBlocked}
								aria-label="Higher Margin">
								<ArrowRight />
							</ArrowButton>
						</Exist>
					</MarginContainer>
				</MarginWrapper>
			</Exist>
		</Wrapper>
	);
});
