import React, {PropsWithChildren} from "react";
import {Container} from "inversify";

export const InjectionContext = React.createContext<{container: Container | null}>({
	container: null,
});

interface IProps {
	container: Container;
}

export const InjectionProvider: React.FC<PropsWithChildren<IProps>> = ({children, container}) => (
	<InjectionContext.Provider value={{container}}>{children}</InjectionContext.Provider>
);
