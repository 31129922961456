import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {MatchStatus, ModalType, TournamentIconType} from "data/enums";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {IModalTournamentData} from "data/types/game";
import {type ITournamentStore} from "data/stores/tournament/tournament.store";
import {type IStreakPredictionsStore} from "data/stores/streak_predictions/streak_predictions.store";

export interface IModalStreakPredictionsSavedController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get roundId(): number;
	get tournaments(): IModalTournamentData[];

	closeHandler: () => void;
}

@injectable()
export class ModalStreakPredictionsSavedController
	implements IModalStreakPredictionsSavedController
{
	get isOpen() {
		return this._modalsStore.modal === ModalType.STREAK_PREDICTIONS_SAVED;
	}

	get roundId() {
		return this._roundsStore.selectedRound?.id || 1;
	}

	get tournaments() {
		const predictions = this._streakPredictionsStore.predictions;
		const tournaments = this._roundsStore.selectedRound?.tournaments || [];

		return tournaments
			.map((tournament) => {
				const prediction = predictions.find(
					({tournamentId}) => tournamentId === tournament.id
				);
				const awayIconType = this._tournamentStore.getStreakTournamentIconType(
					tournament,
					tournament.awaySquadId,
					prediction
				);
				const homeIconType = this._tournamentStore.getStreakTournamentIconType(
					tournament,
					tournament.homeSquadId,
					prediction
				);

				return {
					...tournament,
					awayIconType,
					homeIconType,
					isSelectedHomeSquad: prediction?.squadId === tournament.homeSquadId,
					isSelectedAwaySquad: prediction?.squadId === tournament.awaySquadId,
					className: homeIconType === TournamentIconType.SKIPPED ? "skipped" : "",
					hasPrediction: Boolean(prediction?.squadId),
				};
			})
			.filter(
				({hasPrediction, status}) => !(status !== MatchStatus.Complete && !hasPrediction)
			);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.StreakPredictionsStore)
		private _streakPredictionsStore: IStreakPredictionsStore,
		@inject(Bindings.TournamentStore) private _tournamentStore: ITournamentStore
	) {
		makeAutoObservable(this);
	}

	closeHandler = () => {
		this._modalsStore.hideModal();
	};
}
