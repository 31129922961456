import {identity, isEqual, pickBy} from "lodash";
import {AxiosError} from "axios";
import {inject, injectable} from "inversify";
import type {ChangeEvent, SyntheticEvent} from "react";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {RequestState} from "data/enums";
import {LOGOUT_LINK, MAX_FILE_SIZE} from "data/constants";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {toFormData} from "data/utils/toFormData";
import {getUserAvatarUrl} from "data/utils/avatar";
import type {IWebViewGateStore} from "data/stores/web_view_gate/web_view_gate.store";

interface IMyAccountForm extends HTMLFormElement {
	displayName: HTMLInputElement;
	avatar: HTMLInputElement;
	notification: HTMLInputElement;
	push: HTMLInputElement;
}

export interface IMyAccountController extends ViewController {
	i18n: ILocalizationStore;

	get isFormDisabled(): boolean;
	get error(): Record<string, string> | null;
	get user(): IUser;
	get isUpdateDisabled(): boolean;
	get avatar(): File | null;
	get isShowUpdatedButton(): boolean;
	get avatarIcon(): string | undefined;
	get isFromAPP(): boolean;

	handleFormSubmit: (event: SyntheticEvent<IMyAccountForm>) => void;
	handleFormChange: (event: ChangeEvent<IMyAccountForm>) => void;
	handleAvatarChange: (e: ChangeEvent<HTMLInputElement>) => void;
	logoutHandler: () => void;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _isFormChanged = false;
	@observable private _avatar: File | null = null;
	@observable private _timerId?: ReturnType<typeof setTimeout>;

	get isFromAPP() {
		return Boolean(this._webViewGateStore.isFromApp);
	}

	get isUpdateDisabled() {
		return this.isFormDisabled || !this._isFormChanged || Boolean(this._errorMsg);
	}

	get user() {
		return this._userStore.user!;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isShowUpdatedButton() {
		return isEqual(this._requestState, RequestState.SUCCESS);
	}

	get avatar() {
		return this._avatar;
	}

	get avatarIcon() {
		if (!this.user.avatarVersion) {
			return;
		}

		return getUserAvatarUrl(this.user.id, this.user.avatarVersion);
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.WebViewGateStore) private _webViewGateStore: IWebViewGateStore
	) {
		makeAutoObservable(this);
	}

	@action
	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action
	private clearState() {
		this._errorMsg = null;
		this._errorPlace = "";
		this._requestState = RequestState.IDLE;
		clearTimeout(this._timerId);
	}

	@action handleFormSubmit = (event: SyntheticEvent<IMyAccountForm>) => {
		event.preventDefault();

		// const {displayName, notification, push} = event.currentTarget;
		const {displayName, notification} = event.currentTarget;

		const payload = pickBy(
			{
				displayName: displayName.value,
				avatar: this._avatar,
			},
			identity
		) as Record<string, string | File>;

		const formData = toFormData({
			...payload,
			isNotificationsEnabled: notification.checked,
			// isPushEnabled: push.checked,
		});

		this._requestState = RequestState.PENDING;
		this._userStore
			.update(formData)
			.then(this.onSuccess)
			.then(() =>
				runInAction(() => {
					this._isFormChanged = false;
				})
			)
			.catch(this.onError);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;

		this._timerId = setTimeout(() => {
			this._requestState = RequestState.IDLE;
		}, 10000); // 10 seconds
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	@action
	handleFormChange = (event: ChangeEvent<IMyAccountForm>) => {
		this.clearState();
		this._isFormChanged = true;

		const {displayName} = event.currentTarget;

		const validateList = [
			{field: displayName, error: "account.page.display_name_error", place: "displayName"},
		];

		validateList.some(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);
	};

	@action logoutHandler = async () => {
		try {
			this._requestState = RequestState.PENDING;

			await this._userStore.logout();

			window.location.assign(LOGOUT_LINK as string);
		} catch (error) {
			this.onError(error as AxiosError<IApiResponse>);
		}
	};

	@action
	handleAvatarChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.clearState();
		e.stopPropagation();
		const target = e.target as HTMLInputElement;
		const files = target.files || [];
		const file = files[0];

		target.value = "";

		if (file?.size > MAX_FILE_SIZE) {
			this.reportError(
				this.i18n.t("league_create.step_1.avatar_error", "File is too big"),
				"avatar"
			);
			return;
		}

		this._avatar = file;
		this._isFormChanged = true;
	};

	dispose() {
		clearTimeout(this._timerId);
	}
}
