import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {INotificationBarStore} from "data/stores/notification/notification.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";

export interface INotificationBarController extends ViewController {
	i18n: ILocalizationStore;
	get isEnabled(): boolean;
	get message(): string | null;
	close: () => void;
}

@injectable()
export class NotificationBarController implements INotificationBarController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.NotificationBarStore) private _noficationBarStore: INotificationBarStore,
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider
	) {
		makeAutoObservable(this);
	}

	get isEnabled(): boolean {
		return this._noficationBarStore.isEnabled;
	}

	get message() {
		return this._noficationBarStore.notificationBar?.message;
	}

	close = () => {
		this._noficationBarStore.clear();
	};

	async init() {
		await this._noficationBarStore.fetchNotif();
	}
}
