import React from "react";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {SquadMatchResult} from "data/enums";
import {IFormGuideController} from "./form_guide.controller";

const Wrapper = styled.div`
	width: 100%;
	padding: 12px 13px;
	display: grid;
	grid-template-columns: 1fr min-content 1fr;
	align-items: center;
	border-radius: 4px;
	background: ${({theme}) => theme.palette.statsModal.statsItemBg};
	box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
	white-space: nowrap;
	font-size: 11px;
	font-weight: 500;
	line-height: 12px;

	@media (min-width: 769px) {
		grid-column-gap: 18px;
	}
`;

const List = styled.div<{homeBg: string; awayBg: string}>`
	display: flex;
	gap: 4px;

	&.home .${SquadMatchResult.W} {
		background: ${({homeBg}) => homeBg};
	}

	&.away {
		justify-content: flex-end;

		.${SquadMatchResult.W} {
			background: ${({awayBg}) => awayBg};
		}
	}

	@media (min-width: 769px) {
		&.home {
			justify-content: flex-end;
		}

		&.away {
			justify-content: flex-start;
		}
	}
`;

const Item = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	color: #ffffff;
	background: #b0b0b0;
`;

interface IFormGuide {
	tournamentId: number;
}

export const FormGuide: React.FC<IFormGuide> = observer(({tournamentId}) => {
	const {homeColor, awayColor, homeRecentList, awayRecentList, i18n, isShowFormGuide} =
		useViewController<IFormGuideController>(Bindings.FormGuideController, {tournamentId});

	if (!isShowFormGuide) {
		return null;
	}

	return (
		<Wrapper>
			<List className="home" homeBg={homeColor} awayBg={awayColor}>
				{homeRecentList.map((result, i) => (
					<Item key={i} className={result}>
						{result}
					</Item>
				))}
			</List>

			<div>{i18n.t("modal.game_stats.form_guide", "Form Guide")}</div>

			<List className="away" homeBg={homeColor} awayBg={awayColor}>
				{awayRecentList.map((result, i) => (
					<Item key={i} className={result}>
						{result}
					</Item>
				))}
			</List>
		</Wrapper>
	);
});
