import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ITournamentStatsStore} from "data/stores/tournament_stats/tournament_stats.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";

interface IProps {
	tournamentId: number;
}

export interface ITeamStatsController extends ViewController<IProps> {
	get i18n(): ILocalizationStore;
	get hasKeyChanges(): boolean;
}

@injectable()
export class TeamStatsController implements ITeamStatsController {
	@observable private _tournamentId: number = 0;

	get hasKeyChanges() {
		return this._tournamentStatsStore.getHasChangesById(this._tournamentId);
	}

	constructor(
		@inject(Bindings.TournamentStatsStore) private _tournamentStatsStore: ITournamentStatsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this._tournamentId = param.tournamentId;
	}
}
