import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {IGameBar, IModalTournamentData} from "data/types/game";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type IModalCompleteRoundStore} from "data/stores/modals/modal_complete_round.store";
import {type ITournamentStore} from "data/stores/tournament/tournament.store";

export interface IModalCompleteRoundController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get roundId(): number;
	get isPerfect(): boolean;
	get tournaments(): IModalTournamentData[];
	get nextRoundId(): number | undefined;
	get gameBar(): IGameBar;

	close: () => void;
	pickRoundHandler: () => void;
}

@injectable()
export class ModalCompleteRoundController implements IModalCompleteRoundController {
	get isOpen() {
		return this._modalCompleteRoundStore.isOpen;
	}

	get roundId() {
		return this._modalCompleteRoundStore.roundId || 1;
	}

	get isPerfect() {
		return this._modalCompleteRoundStore.isPerfect;
	}

	get tournaments() {
		const predictions = this._modalCompleteRoundStore.predictions;
		const tournaments = this._roundsStore.getRoundById(this.roundId)?.tournaments || [];

		return tournaments.map((tournament) => {
			const prediction = predictions.find(({tournamentId}) => tournamentId === tournament.id);
			const awayIconType = this._tournamentStore.getTournamentIconType(
				tournament,
				tournament.awaySquadId,
				prediction
			);
			const homeIconType = this._tournamentStore.getTournamentIconType(
				tournament,
				tournament.homeSquadId,
				prediction
			);

			return {
				...tournament,
				awayIconType,
				homeIconType,
				isSelectedHomeSquad: prediction?.squadId === tournament.homeSquadId,
				isSelectedAwaySquad: prediction?.squadId === tournament.awaySquadId,
			};
		});
	}

	get nextRoundId() {
		return this._roundsStore.getRoundById(this.roundId + 1)?.id;
	}

	get gameBar() {
		return this._modalCompleteRoundStore.gameBar;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalCompleteRoundStore)
		private _modalCompleteRoundStore: IModalCompleteRoundStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.TournamentStore) private _tournamentStore: ITournamentStore
	) {
		makeAutoObservable(this);
	}

	close = () => {
		this._modalCompleteRoundStore.hideModal();
	};

	pickRoundHandler = () => {
		this.close();
		this._roundsStore.setSelectedRoundId(this.nextRoundId!);
	};
}
