import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IRanking, IRankingsStore} from "data/stores/rankings/rankings.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {OrderField, SortOrder} from "data/enums";
import type {IUserStore} from "data/stores/user/user.store";
import type {ISortData} from "data/types/game";

interface IProps {
	isOverall: boolean;
	sortData: ISortData;
}

export interface IRankingsTableController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get rankings(): IRanking[];
	get userRanking(): IRanking | null;
	get isShowStickyUserRanking(): boolean;
	get className(): string;
	get nextPage(): boolean;

	getHeadCN: (filed: OrderField, cn?: string) => string;
}

@injectable()
export class RankingsTableController implements IRankingsTableController {
	@observable private _isOverall: boolean = false;
	@observable private _sortData: ISortData = {
		field: OrderField.RANK,
		order: SortOrder.ASC,
	};

	get isShowStickyUserRanking() {
		const rankingsListHasUser = this.rankings.some(
			({userId}) => userId === this._userStore.user?.id
		);

		return Boolean(this.userRanking && !rankingsListHasUser);
	}

	get rankings() {
		return this._rankingsStore.list;
	}

	get userRanking() {
		return this._rankingsStore.user;
	}

	get className() {
		return this._isOverall ? "overall" : "";
	}

	get nextPage() {
		return this._rankingsStore.nextPage;
	}

	constructor(
		@inject(Bindings.RankingsStore) private _rankingsStore: IRankingsStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this.onChange(param);
	}

	onChange(param: IProps) {
		this._isOverall = param.isOverall;
		this._sortData = param.sortData;
	}

	getHeadCN = (filed: OrderField, cn: string = "") => {
		if (this._sortData.field === filed) {
			return `with-sort ${this._sortData.order} ${cn}`;
		}

		return `with-sort ${cn}`;
	};
}
