import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import {LeagueStatus, ModalType, RequestState} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {useNavigate} from "react-router";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {isEqual} from "lodash";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

interface IParam {
	league: ILeague;
	navigate: ReturnType<typeof useNavigate>;
}

export interface IInviteLeagueCardController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get isLoading(): boolean;

	accept: () => void;
	decline: () => void;
}

@injectable()
export class InviteLeagueCardController implements IInviteLeagueCardController {
	@observable private _league: ILeague | null = null;
	@observable private _requestState = RequestState.IDLE;
	@observable private _navigate!: ReturnType<typeof useNavigate>;

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action accept = () => {
		const leagueId = this._league?.id;

		if (!leagueId) return;

		this._requestState = RequestState.PENDING;

		this._leaguesStore
			.acceptInvite({leagueId})
			.then(() => this.redirectToMyLeague())
			.catch(this.onError);
	};

	@action decline = () => {
		const leagueId = this._league?.id;

		if (!leagueId) return;

		this._requestState = RequestState.PENDING;

		this._leaguesStore.declineInvite({leagueId}).then(this.onSuccess).catch(this.onError);
	};

	private redirectToMyLeague = (replace = false) => {
		const id = this._league?.id;
		const tab = this._league?.status === LeagueStatus.SCHEDULED ? "about" : "ladder";

		if (id) this._navigate(`/comp/${id}/${tab}`, {replace});
	};

	@action init(param: IParam): void {
		this._league = param.league;
		this._navigate = param.navigate;
	}

	@action onChange(param: IParam): void {
		this._league = param.league;
		this._navigate = param.navigate;
	}
}
