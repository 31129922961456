import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILeaguesStore, IRecoverLeague} from "data/stores/leagues/leagues.store";
import {ModalType, RequestState} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {useNavigate} from "react-router";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {isEqual} from "lodash";
import type {ILocalizationStore} from "data/stores/localization/localization.store";

interface IParam {
	league: IRecoverLeague;
	navigate: ReturnType<typeof useNavigate>;
}

export interface IRegenerateLeagueCardController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;
	get isLoading(): boolean;

	accept: () => void;
	decline: () => void;
}

@injectable()
export class RegenerateLeagueCardController implements IRegenerateLeagueCardController {
	@observable private _league: IRecoverLeague | null = null;
	@observable private _requestState = RequestState.IDLE;
	@observable private _navigate!: ReturnType<typeof useNavigate>;

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action accept = () => {
		const leagueId = this._league?.id;

		if (!leagueId) return;

		this._modalsStore.showModal(ModalType.CONFIRM, {
			title: this.i18n.t("modal.regenerate.confirm_title", "Restart {{name}}?", {
				name: this._league?.name,
			}),
			message: this.i18n.t(
				"modal.regenerate.confirm_description",
				"Want to start your comp again for the new season? Press the RESTART button, and we will restart your comp and email all previous participants to let them know."
			),
			callback: () => {
				runInAction(() => {
					this._modalsStore.hideModal();
					this._requestState = RequestState.PENDING;

					this._leaguesStore
						.recover({oldLeagueId: leagueId})
						.then((league) => this.redirectToMyLeague(league.id))
						.catch(this.onError);
				});
			},
		});
	};

	@action decline = () => {
		const leagueId = this._league?.id;

		if (!leagueId) return;

		this._modalsStore.showModal(ModalType.CONFIRM, {
			title: this.i18n.t("modal.regenerate.remove_title", "Are you sure?"),
			message: this.i18n.t(
				"modal.regenerate.remove_description",
				"You are deciding not to launch {{name}} for the new season. You cannot restart this comp if you decide to do it later.",
				{name: this._league?.name}
			),
			callback: () => {
				runInAction(() => {
					this._modalsStore.hideModal();
					this._requestState = RequestState.PENDING;

					this._leaguesStore
						.recoverSkip({oldLeagueId: leagueId})
						.then(this.onSuccess)
						.catch(this.onError);
				});
			},
		});
	};

	private redirectToMyLeague = (id: number, replace = false) => {
		this._navigate(`/comp/${id}/`, {replace});
	};

	@action init(param: IParam): void {
		this._league = param.league;
		this._navigate = param.navigate;
	}

	@action onChange(param: IParam): void {
		this._league = param.league;
		this._navigate = param.navigate;
	}
}
