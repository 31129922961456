import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import {copyToClipboard, extractErrorMessage, share} from "data/utils";
import {LeaguePrivacy, ModalType, ShareType, SocialNetwork} from "data/enums";
import type {SyntheticEvent} from "react";
import {EMAIL_REGEXP} from "data/constants";
import {identity, isEmpty, isEqual, uniq} from "lodash";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {type IUserStore} from "data/stores/user/user.store";

const MAX_INVITES_COUNT = 20;

interface IProps {
	league: ILeague | null;
}

interface IEmailsForm extends HTMLFormElement {
	emails: HTMLInputElement;
}

export interface ILeagueInviteFormController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get isCodeCopied(): boolean;
	get isLinkCopied(): boolean;
	get isEmailsSend(): boolean;
	get isMobileShareVisible(): boolean;
	get areEmailsValid(): boolean;
	get error(): string;
	get isShowInviteByEmail(): boolean;

	copyCode: () => void;
	copyLink: () => void;
	shareLeague: () => void;
	handleInvite: (e: SyntheticEvent<IEmailsForm>) => void;
	validateEmailsInput: (event: SyntheticEvent<IEmailsForm>) => string[];
}

@injectable()
export class LeagueInviteFormController implements ILeagueInviteFormController {
	@observable private _isNarrowScreen: boolean = false;
	@observable private _league: ILeague | null = null;
	@observable private _isCodeCopied = false;
	@observable private _isLinkCopied = false;
	@observable private _isEmailsSend = false;
	@observable private _areEmailsValid: boolean = false;
	@observable private _copyCodeTextTimeout?: ReturnType<typeof setTimeout>;
	@observable private _copyLinkTextTimeout?: ReturnType<typeof setTimeout>;
	@observable private _emailsSendTextTimeout?: ReturnType<typeof setTimeout>;
	@observable private _error: string = "";

	get isCodeCopied() {
		return this._isCodeCopied;
	}

	get isLinkCopied() {
		return this._isLinkCopied;
	}

	get isEmailsSend() {
		return this._isEmailsSend;
	}

	get areEmailsValid() {
		return this._areEmailsValid;
	}

	get isMobileShareVisible() {
		return "share" in navigator && this._isNarrowScreen;
	}

	private get narrowScreenWatcher() {
		return window.matchMedia("(max-width: 960px)");
	}

	get error() {
		return this._error;
	}

	get isShowInviteByEmail() {
		if (this._league?.privacy === LeaguePrivacy.PUBLIC) {
			return true;
		}

		return isEqual(this._league?.leagueManager?.userId, this._userStore.user!.id);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action private onCopyCodeState = () => {
		this._isCodeCopied = false;
	};

	@action private onCopyLinkState = () => {
		this._isLinkCopied = false;
	};

	@action private onCopyCodeCallback = (isSuccess: boolean) => {
		this._isCodeCopied = isSuccess;

		if (isSuccess) {
			this._copyCodeTextTimeout = setTimeout(this.onCopyCodeState, 5000);
		}
	};

	@action private onCopyLinkCallback = (isSuccess: boolean) => {
		this._isLinkCopied = isSuccess;

		if (isSuccess) {
			this._copyLinkTextTimeout = setTimeout(this.onCopyLinkState, 5000);
		}
	};

	copyCode = () => {
		const code = this._league?.code ?? "";

		copyToClipboard(code).then(this.onCopyCodeCallback).catch(this.onCopyCodeCallback);
	};

	copyLink = () => {
		const link = `${window.location.origin}/comps/join/${this._league?.code ?? ""}`;

		copyToClipboard(link).then(this.onCopyLinkCallback).catch(this.onCopyLinkCallback);
	};

	private share(socialNetwork: SocialNetwork) {
		const leagueId = this._league?.id;

		if (!leagueId) return;

		share({
			leagueId,
			socialNetwork,
			type: ShareType.League,
		});
	}

	shareLeague = () => {
		if (this.isMobileShareVisible) {
			this.share(SocialNetwork.Mobile);
		} else {
			this._modalsStore.showModal(ModalType.SHARE, {
				message: this.i18n.t("modal.share.league", "Share this league with friends."),
				callback: (payload) => {
					runInAction(() => {
						const socialNetwork = payload as SocialNetwork;
						this.share(socialNetwork);
					});
				},
			});
		}
	};

	@action private updateNarrowScreenFlag = () => {
		this._isNarrowScreen = this.narrowScreenWatcher.matches;
	};

	@action init(param: IProps) {
		this._league = param.league;

		this.updateNarrowScreenFlag();
		this.narrowScreenWatcher.addEventListener("change", this.updateNarrowScreenFlag);
	}

	@action
	validateEmailsInput = (event: SyntheticEvent<IEmailsForm>) => {
		this._error = "";
		const emailRegexp = new RegExp(EMAIL_REGEXP);
		const emailsArray = uniq(
			event.currentTarget.emails.value
				.split(",")
				.map((it) => it.trim())
				.filter(identity)
		);
		const hasInvalidEmail = emailsArray.some((email) => !emailRegexp.test(email));
		const isAllowEmailsCount = emailsArray.length <= MAX_INVITES_COUNT;

		if (!isAllowEmailsCount) {
			this._error = this.i18n.t(
				"league_invite.invite_email.limit_error",
				"The maximum amount of email is 20"
			);
		}

		this._areEmailsValid = !(isEmpty(emailsArray) || hasInvalidEmail || !isAllowEmailsCount);

		return emailsArray;
	};

	@action
	handleInvite = (event: SyntheticEvent<IEmailsForm>) => {
		event.preventDefault();

		if (!this._league) return;

		const emailsArray = this.validateEmailsInput(event);

		if (!this.areEmailsValid) return;

		this._leaguesStore
			.inviteUsersToLeague({
				leagueId: this._league.id,
				invites: emailsArray.map((email) => ({email})),
			})
			.then(this.onSuccessSendEmails)
			.then(() => {
				(event.target as HTMLFormElement).reset();
			})
			.catch((err) => {
				this._modalsStore.showModal(ModalType.ERROR, {
					message: extractErrorMessage(err as AxiosError<IApiResponse>),
				});
			});
	};

	@action private onSendEmailsState = () => {
		this._isEmailsSend = false;
	};

	@action private onSuccessSendEmails = () => {
		this._isEmailsSend = true;
		this._emailsSendTextTimeout = setTimeout(this.onSendEmailsState, 5000);
		this._modalsStore.showModal(ModalType.INVITE_VIA_EMAIL);
	};

	@action onChange(param: IProps) {
		this._league = param.league;
	}

	dispose() {
		this.narrowScreenWatcher.removeEventListener("change", this.updateNarrowScreenFlag);

		if (this._copyCodeTextTimeout) clearTimeout(this._copyCodeTextTimeout);
		if (this._emailsSendTextTimeout) clearTimeout(this._emailsSendTextTimeout);
	}
}
