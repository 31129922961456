import {inject, injectable} from "inversify";
import {AxiosResponse} from "axios";
import type {TUserResponse} from "data/providers/api/user.api.provider";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {ICheckSID, INRLIdProfile, IOldUser, IUser} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";

export interface ILoginPayload {
	code: string;
	codeVerifier: string;
	redirectUrl: string;
}

export interface ILoginBackdoorPayload {
	email: string;
	password: string;
}

export interface IRegisterBackdoorPayload {
	email: string;
	nrlId: string;
	firstName: string;
	lastName: string;
	password: string;
	displayName: string;
}

type TLoginResponse = IApiResponse<{
	user: IUser | null;
	oldUser: IOldUser | null;
	nrlIdProfile: INRLIdProfile | null;
}>;

export interface IRegistrationPayload {
	displayName: string;
	code: string;
	codeVerifier: string;
	country: string;
	state?: string;
	redirectUrl: string;
	gender?: string;
	sponsorOptIn: boolean;
	avatar?: File;
	supportedSquadId?: number;
	autopickPreference: string;
}

export interface IRegisterFromAppPayload {
	nrlSessionId: string;
	displayName: string;
	sponsorOptIn: boolean;
	supportedSquadId: number;
	autopickPreference: string;
	avatar?: File;
}

export interface INrlIdApiProvider {
	register: (params: FormData) => Promise<AxiosResponse<TUserResponse>>;
	login: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	registerApp: (params: FormData) => Promise<AxiosResponse<TUserResponse>>;
	checkSID: (params: ICheckSID) => Promise<AxiosResponse<TUserResponse>>;
}

@injectable()
export class NrlIdApiProvider implements INrlIdApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	login = (params: ILoginPayload) => this._http.post<TLoginResponse>("nrl-id/login", params);

	register = (params: FormData) => this._http.post<TUserResponse>("nrl-id/register", params);

	registerApp = (param: FormData) => this._http.post<TUserResponse>("nrl-id/register-app", param);

	checkSID = (param: ICheckSID) =>
		this._http.post<TUserResponse>("nrl-id/check-nrl-session", param);
}
