import React from "react";
import {observer} from "mobx-react";
import {IconButton} from "@mui/material";
import styled from "@emotion/styled";
import {BannerType} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {useViewController} from "data/services/locator";
import {Timer} from "views/components/timer/timer.component";
import {Exist} from "views/components/exist/exist.component";
import {BannerWrapper} from "views/components/tips_banner/tipping/game_bar_banner.component";
import {IGameBannerController} from "./game_banner.controller";
import {ReactComponent as CloseIcon} from "assets/img/icons/close.svg";

const Wrapper = styled(BannerWrapper)`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	padding: 0 4px 0 12px;

	&.live path {
		fill: ${({theme}) => theme.palette.banner.liveCloseBg};
	}

	&.timer path {
		fill: ${({theme}) => theme.palette.banner.timerCloseBg};
	}

	&.saving path {
		fill: ${({theme}) => theme.palette.banner.savingCloseBg};
	}

	&.saved path {
		fill: ${({theme}) => theme.palette.banner.savedCloseBg};
	}
`;

const CloseBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;

	button {
		padding: 6px;
	}
`;

interface IGameBanner {
	closeHandler: () => void;
}

export const GameBanner: React.FC<IGameBanner> = observer(({closeHandler}) => {
	const {
		bannerType,
		i18n,
		nextRoundStartDate,
		selectedRoundId,
		selectedRoundStartDate,
		nextRoundId,
		totalPredictionsCount,
		predictionsCount,
		className,
	} = useViewController<IGameBannerController>(Bindings.GameBannerController);

	return (
		<Wrapper className={className}>
			<Exist when={bannerType === BannerType.Live}>
				<div>{i18n.t("tips.banner.live", "Round is Live")}</div>
			</Exist>

			<Exist when={bannerType === BannerType.Timer}>
				<div>
					<Timer
						i18n={i18n}
						date={selectedRoundStartDate}
						selectedRoundId={selectedRoundId}
					/>
				</div>
			</Exist>

			<Exist when={bannerType === BannerType.NextRoundTimer}>
				<div>
					<Timer i18n={i18n} date={nextRoundStartDate} selectedRoundId={nextRoundId} />
				</div>
			</Exist>

			<Exist when={bannerType === BannerType.TipsSaving}>
				<div>
					{predictionsCount}/{totalPredictionsCount}{" "}
					{i18n.t("tips.banner.saved", "Tips saved")}
				</div>
			</Exist>

			<Exist when={bannerType === BannerType.TipsSaved}>
				<div>
					{predictionsCount}/{totalPredictionsCount}{" "}
					{i18n.t("tips.banner.saved", "Tips saved")}
				</div>
			</Exist>

			<CloseBlock>
				<IconButton onClick={closeHandler}>
					<CloseIcon />
				</IconButton>
			</CloseBlock>
		</Wrapper>
	);
});
