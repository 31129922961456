import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import type {IWebViewGateStore} from "data/stores/web_view_gate/web_view_gate.store";
import {Bindings} from "data/constants/bindings";
import {DeviceType} from "data/enums";
import {useNavigate} from "react-router-dom";

interface IController {
	searchParams: URLSearchParams;
	navigate: ReturnType<typeof useNavigate>;
}

export interface IWebViewGateController extends ViewController<IController> {
	get checked(): boolean;
}

@injectable()
export class WebViewGateController implements IWebViewGateController {
	@observable private _checked = false;

	constructor(@inject(Bindings.WebViewGateStore) private _webViewGateStore: IWebViewGateStore) {
		makeAutoObservable(this);
	}

	get checked() {
		return this._checked;
	}

	init(params: IController) {
		const device = params.searchParams.get("app") as DeviceType;

		if ([DeviceType.IOS, DeviceType.Android].includes(device)) {
			this._webViewGateStore.setDevice(device);
		}

		this._checked = true;
	}
}
