import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {AxiosError} from "axios";
import {identity, pickBy} from "lodash";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {ModalType, OrderField, RequestState, SortOrder} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import {type IStreakRankingsStore} from "data/stores/streak_rankings/streak_rankings.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type ISortData} from "data/types/game";

const RANK_LIMIT = 50;

export interface IStreakRankingsController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isLoading(): boolean;
	get hasRankings(): boolean;

	loadMoreRankings: () => void;
	getHeadCN: (filed: OrderField, cn?: string) => string;
	changeOrder: (field: OrderField, order?: SortOrder) => void;
}

@injectable()
export class StreakRankingsController implements IStreakRankingsController {
	@observable _requestState = RequestState.PENDING;
	@observable private _sortData: ISortData = {
		field: OrderField.RANK,
		order: SortOrder.ASC,
	};

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	private get rankings() {
		return this._streakRankingsStore.list;
	}

	get hasRankings() {
		return Boolean(this.rankings.length);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.StreakRankingsStore) private _streakRankingsStore: IStreakRankingsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	private get rankingParams() {
		return pickBy(
			{
				limit: RANK_LIMIT,
				sortBy: this._sortData.field,
				sortOrder: this._sortData.order,
			},
			identity
		);
	}

	async init() {
		try {
			await Promise.all([
				this._streakRankingsStore.fetchRankings(this.rankingParams),
				this._roundsStore.fetchRounds(),
			]);

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (error) {
			this.onError(error as AxiosError<IApiResponse>);
		}
	}

	loadMoreRankings = async () => {
		try {
			this._requestState = RequestState.PENDING;
			await this._streakRankingsStore.loadMoreRankings(this.rankingParams);

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (error) {
			this.onError(error as AxiosError<IApiResponse>);
		}
	};

	dispose() {
		this._streakRankingsStore.clearStore();
	}

	getHeadCN = (filed: OrderField, cn: string = "") => {
		if (this._sortData.field === filed) {
			return `with-sort ${this._sortData.order} ${cn}`;
		}

		return `with-sort ${cn}`;
	};

	changeOrder = async (field: OrderField, order: SortOrder = SortOrder.ASC) => {
		if (this._sortData.field === field) {
			this._sortData.order =
				this._sortData.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
		} else {
			this._sortData = {
				order,
				field,
			};
		}

		try {
			this._requestState = RequestState.PENDING;
			await this._streakRankingsStore.fetchRankings(this.rankingParams);

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (error) {
			this.onError(error as AxiosError<IApiResponse>);
		}
	};
}
