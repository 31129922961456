import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {SquadMatchResult} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {type IJSONProvider} from "data/providers/json/json.provider";
import {get} from "lodash";

export interface ISquadStatInOut {
	id: number;
	firstName: string;
	lastName: string;
	position: string | null;
}

export interface IInAndOuts {
	in: ISquadStatInOut[];
	out: ISquadStatInOut[];
}

interface ISquadStat {
	squadId: number;
	gamesPlayed: number;
	wins: number;
	losses: number;
	draws: number;
	formList: SquadMatchResult[];
	inAndOuts: IInAndOuts;
}

export interface ILastMeetings {
	isHome: boolean;
	awayScore: number;
	homeScore: number;
}

export interface ITournamentStat {
	home: ISquadStat;
	away: ISquadStat;
	lastMeetings: ILastMeetings[];
}

export interface IChanges {
	add: ISquadStatInOut;
	out: ISquadStatInOut;
	background: string;
}

export interface ITournamentStatsStore {
	getTournamentStats: (id: number) => ITournamentStat | undefined;
	getHasChangesById: (tournamentId: number) => boolean;
	fetchTournamentStat: (id: number) => Promise<void>;
	clearStore: () => void;
}

@injectable()
export class TournamentStatsStore implements ITournamentStatsStore {
	@observable private _list: Record<number, ITournamentStat> = {};

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	getHasChangesById(tournamentId: number) {
		const stats = this.getTournamentStats(tournamentId);
		const homePlayers = stats?.home.inAndOuts;
		const homeChanges = get(homePlayers, "in", [])
			.map((player, index) => ({
				add: player,
				out: get(homePlayers, "out", [])[index],
			}))
			.filter(({out}) => out);

		const awayPlayers = stats?.away?.inAndOuts;
		const awayChanges = get(awayPlayers, "in", [])
			.map((player, index) => ({
				add: player,
				out: get(awayPlayers, "out", [])[index],
			}))
			.filter(({out}) => out);

		return Boolean(homeChanges.length || awayChanges.length);
	}

	fetchTournamentStat = async (id: number) => {
		const {data} = await this._jsonProvider.tournamentStat(id);

		runInAction(() => {
			this._list[id] = data;
		});
	};

	getTournamentStats = (id: number) => {
		return this._list[id];
	};

	clearStore = () => {
		this._list = {};
	};
}
