import React, {FC, Fragment, useCallback, useState} from "react";
import styled from "@emotion/styled";
import {ISquadStatInOut} from "data/stores/tournament_stats/tournament_stats.store";
import {getPlayerAvatarUrl} from "data/utils/avatar";
import {Exist} from "views/components/exist/exist.component";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBackRounded";
import mockPlayer from "assets/img/mockPlayer.webp";

const Item = styled.div<{bg: string}>`
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 37px 1fr;
	grid-gap: 6px;
	background: linear-gradient(
		90deg,
		${({bg}) => bg} 0%,
		rgba(255, 255, 255, 0) 27%,
		#fff 50%,
		rgba(255, 255, 255, 0) 73%,
		${({bg}) => bg} 100%
	);
`;

const PlayerWrapper = styled.div`
	display: grid;
	grid-template-columns: 37px 1fr;
	align-items: center;
	justify-content: center;
	min-height: 52px;
	overflow: hidden;

	&.reverse {
		grid-template-columns: 1fr 37px;
		text-align: right;
	}
`;

const PlayerInfo = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;

	&.reverse {
		order: -1;
		align-items: flex-end;
	}
`;

const Name = styled.div`
	font-size: 14px;
	font-weight: 500;
	line-height: 14px;
	letter-spacing: 0.2px;
	max-width: 72px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media (min-width: 400px) {
		max-width: 92px;
	}

	@media (min-width: 500px) {
		max-width: 125px;
	}

	@media (min-width: 600px) {
		max-width: 160px;
	}

	@media (min-width: 769px) {
		max-width: 200px;
	}
`;

const Arrows = styled.div`
	display: flex;
	align-items: center;
	gap: 1px;
	border-radius: 4px;
	background: var(--NRL-Primary, #0d0d0d);
	padding: 4px 6px;
	color: #fff;

	svg {
		width: 12px;
		height: 12px;
	}
`;

const ImgWrapper = styled.div`
	position: relative;
	height: 100%;
`;

const Img = styled.img`
	height: 52px;
	max-width: 50px;
	object-fit: cover;
	position: absolute;
	right: 6px;
	top: 0;

	&.reverse {
		left: 6px;
	}
`;

interface IListItem {
	background: string;
	out?: ISquadStatInOut;
	add?: ISquadStatInOut;
}

export const ListItem: FC<IListItem> = ({background, out, add}) => (
	<Item bg={background}>
		<Player player={out} />

		<Arrows>
			<ArrowForwardIcon />
			<ArrowBackIcon />
		</Arrows>

		<Player player={add} className="reverse" />
	</Item>
);

interface IPlayer {
	player?: ISquadStatInOut;
	className?: string;
}

const Player: FC<IPlayer> = ({player, className}) => {
	if (!player) {
		return <MockPlayer className={className} />;
	}

	return (
		<PlayerWrapper className={className}>
			<PlayerImage playerId={player.id} className={className} />

			<PlayerInfo className={className}>
				<Fragment>
					<Name>
						{player.firstName?.at(0)}. {player.lastName}
					</Name>

					<Exist when={player.position}>
						<div>{player.position}</div>
					</Exist>
				</Fragment>
			</PlayerInfo>
		</PlayerWrapper>
	);
};

interface IMockPlayer {
	className?: string;
}

const MockPlayer: FC<IMockPlayer> = ({className}) => {
	return (
		<PlayerWrapper className={className}>
			<ImgWrapper>
				<Img src={mockPlayer} className={className} />
			</ImgWrapper>

			<PlayerInfo className={className}>
				<Name />
			</PlayerInfo>
		</PlayerWrapper>
	);
};

interface IPlayerImage {
	playerId: number;
	className?: string;
}

const PlayerImage: FC<IPlayerImage> = ({playerId, className}) => {
	const [src, setSrc] = useState(() => getPlayerAvatarUrl(playerId));

	const handleError = useCallback(
		(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
			e.stopPropagation();
			setSrc(getPlayerAvatarUrl(playerId, 450, "png"));
		},
		[playerId]
	);

	return (
		<ImgWrapper>
			<Img src={src} onError={handleError} className={className} />
		</ImgWrapper>
	);
};
