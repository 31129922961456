import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {get} from "lodash";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {type ITournamentStore} from "data/stores/tournament/tournament.store";
import {type ISquadsStore} from "data/stores/squads/squads.store";
import {type ITournamentStatsStore} from "data/stores/tournament_stats/tournament_stats.store";

interface IProps {
	tournamentId: number;
}

export interface ITournamentStatsCircleController extends ViewController<IProps> {
	i18n: ILocalizationStore;

	get awayColor(): string;
	get homeColor(): string;
	get percents(): number;
	get played(): number;
	get awayWins(): number;
	get homeWins(): number;
	get drawn(): number;
	get homeCN(): string;
	get awayCN(): string;
}

@injectable()
export class TournamentStatsCircleController implements ITournamentStatsCircleController {
	@observable private _tournamentId: number = 0;

	get tournament() {
		return this._tournamentStore.getTournamentDataById(this._tournamentId);
	}

	get awaySquad() {
		return this._squadsStore.getSquadById(this.tournament?.awaySquadId || 0);
	}

	get homeSquad() {
		return this._squadsStore.getSquadById(this.tournament?.homeSquadId || 0);
	}

	get awayColor() {
		return get(this.awaySquad, "color", "");
	}

	get homeColor() {
		return get(this.homeSquad, "color", "");
	}

	get percents() {
		const awaySelected = get(this.stats?.away, "wins", 0);
		const homeSelected = get(this.stats?.home, "wins", 0);

		if (awaySelected === 0 && homeSelected === 0) {
			return 50;
		}

		return (awaySelected / (awaySelected + homeSelected)) * 100;
	}

	get stats() {
		return this._tournamentStatsStore.getTournamentStats(this._tournamentId);
	}

	get played() {
		return this.stats?.home.gamesPlayed || 0;
	}

	get awayWins() {
		return this.stats?.away.wins || 0;
	}

	get homeWins() {
		return this.stats?.home.wins || 0;
	}

	get drawn() {
		return this.stats?.home.draws || 0;
	}

	get homeCN() {
		return this.homeWins > this.awayWins ? "win" : "";
	}

	get awayCN() {
		return this.awayWins > this.homeWins ? "win" : "";
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.TournamentStore) private _tournamentStore: ITournamentStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.TournamentStatsStore) private _tournamentStatsStore: ITournamentStatsStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this._tournamentId = param.tournamentId;
	}
}
