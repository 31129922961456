import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {isNumber} from "lodash";
import {ViewController} from "data/types/structure";
import {RankStatus} from "data/enums";
import {Bindings} from "data/constants/bindings";
import {type IGameBarStore} from "data/stores/game_bar/game_bar.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";

export interface IGameBarController extends ViewController {
	readonly i18n: ILocalizationStore;

	get roundPts(): number | null;
	get roundRank(): number | null;
	get overallRank(): number | null;
	get overallRankStatus(): RankStatus | null;
}

@injectable()
export class GameBarController implements IGameBarController {
	get roundPts() {
		return this._gameBarStore.gameBar.roundPoints;
	}

	get roundRank() {
		return this._gameBarStore.gameBar.roundRank;
	}

	get overallRank() {
		return this._gameBarStore.gameBar.overallRank;
	}

	private get prevOverallRank() {
		return this._gameBarStore.gameBar.prevOverallRank;
	}

	get overallRankStatus() {
		if (!isNumber(this.prevOverallRank) || !isNumber(this.overallRank)) {
			return null;
		}

		return this.prevOverallRank > this.overallRank ? RankStatus.UP : RankStatus.DOWN;
	}

	constructor(
		@inject(Bindings.GameBarStore) private _gameBarStore: IGameBarStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}
}
