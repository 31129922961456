import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {
	type IStreakRankingApiProvider,
	IStreakRankingsPayload,
} from "data/providers/api/streak_ranking.api.provider";

export interface IStreakRanking {
	userId: number;
	userName: string;
	avatarVersion: number;
	firstName: string;
	lastName?: string;
	currentStreak: number;
	longestStreak: number;
	previousLongestStreak: number;
	missedGames: number;
	rank: number | null;
}

export interface IStreakRankingsStore {
	get nextPage(): boolean;
	get user(): IStreakRanking | null;
	get list(): IStreakRanking[];

	fetchRankings: (params: IStreakRankingsPayload) => Promise<void>;
	loadMoreRankings: (params: IStreakRankingsPayload) => Promise<void>;
	clearStore: () => void;
}

@injectable()
export class StreakRankingsStore implements IStreakRankingsStore {
	@observable private _list: IStreakRanking[] = [];
	@observable private _user: IStreakRanking | null = null;
	@observable private _nextPage: boolean = false;
	private _page = 0;

	get nextPage(): boolean {
		return this._nextPage;
	}

	get user() {
		return this._user;
	}

	get list() {
		return this._list;
	}

	constructor(
		@inject(Bindings.StreakRankingApiProvider)
		private _streakRankingApi: IStreakRankingApiProvider
	) {
		makeAutoObservable(this);
	}

	fetchRankings = async (params: IStreakRankingsPayload) => {
		this.clearStore();
		const response = await this._streakRankingApi.ranking(params);

		runInAction(() => {
			this._list = response.data.success.rankings;
			this._user = response.data.success.user;
			this._nextPage = response.data.success.nextPage;
			this._page = 1;
		});
	};

	loadMoreRankings = async (params: IStreakRankingsPayload) => {
		const page = this._page + 1;

		const response = await this._streakRankingApi.ranking({
			...params,
			page,
		});

		runInAction(() => {
			this._list = this._list.concat(response.data.success.rankings);
			this._user = response.data.success.user;
			this._nextPage = response.data.success.nextPage;
			this._page = page;
		});
	};

	clearStore = () => {
		this._list = [];
		this._user = null;
		this._nextPage = false;
		this._page = 0;
	};
}
