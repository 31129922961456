import React, {FC} from "react";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBackRounded";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {IKeyChangesController} from "./key_changes.controller";
import {ListItem} from "./list_item/list_item.component";

const Wrapper = styled.div`
	width: 100%;
	padding: 12px;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	background: ${({theme}) => theme.palette.statsModal.statsItemBg};
	box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
	white-space: nowrap;
	font-size: 11px;
	font-weight: 500;
	line-height: 12px;
	gap: 12px;
	position: relative;
`;

const Head = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const HeadText = styled.div`
	display: flex;
	align-items: center;
	gap: 1px;
	font-size: 10px;
	font-weight: 500;
	line-height: 8px;
	letter-spacing: 1.25px;
	text-transform: uppercase;

	svg {
		width: 12px;
		height: 12px;
	}
`;

const List = styled.div`
	margin-top: 6px;
	display: flex;
	flex-direction: column;
	gap: 2px;
`;

interface IKeyChanges {
	tournamentId: number;
}

export const KeyChanges: FC<IKeyChanges> = observer(({tournamentId}) => {
	const {i18n, changes, hasKeyChanges} = useViewController<IKeyChangesController>(
		Bindings.KeyChangesController,
		{tournamentId}
	);

	if (!hasKeyChanges) {
		return null;
	}

	return (
		<Wrapper>
			<Head>
				<HeadText>
					<ArrowForwardIcon />
					{i18n.t("modal.game_stats.out", "out")}
				</HeadText>

				<HeadText>
					{i18n.t("modal.game_stats.in", "in")}
					<ArrowBackIcon />
				</HeadText>
			</Head>

			<List>
				{changes.homeChanges.map((player, i) => (
					<ListItem key={i} {...player} />
				))}

				{changes.awayChanges.map((player, i) => (
					<ListItem key={i} {...player} />
				))}
			</List>
		</Wrapper>
	);
});
