import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IStreakRankingApiProvider} from "data/providers/api/streak_ranking.api.provider";

export interface IStreakGameBar {
	currentStreak: number;
	longestStreak: number;
	leaderStreak: number;
	missedGames: number;
}

export interface IStreakGameBarStore {
	get gameBar(): IStreakGameBar;

	fetchGameBar: () => Promise<void>;
	clearStore: () => void;
}

@injectable()
export class StreakGameBarStore implements IStreakGameBarStore {
	@observable private _gameBar: IStreakGameBar = {
		currentStreak: 0,
		missedGames: 0,
		longestStreak: 0,
		leaderStreak: 0,
	};

	get gameBar() {
		return this._gameBar;
	}

	constructor(
		@inject(Bindings.StreakRankingApiProvider)
		private _streakRankingApi: IStreakRankingApiProvider
	) {
		makeAutoObservable(this);
	}

	fetchGameBar = async () => {
		const {data} = await this._streakRankingApi.gameBar();

		runInAction(() => {
			this._gameBar = data.success;
		});
	};

	clearStore = () => {
		this._gameBar = {
			currentStreak: 0,
			missedGames: 0,
			longestStreak: 0,
			leaderStreak: 0,
		};
	};
}
