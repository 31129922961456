/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					width: "100%",
					fontFamily: "RL2, sans-serif",
					fontWeight: "600",
					fontSize: "14px",
					borderRadius: "4px",
					height: "48px",
					textTransform: "initial",
					":hover": {
						":disabled": {
							boxShadow:
								"0px 0.1px 0.3px rgba(28, 28, 30, 0.1), 0px 1px 2px rgba(28, 28, 30, 0.2)",
						},
					},
					":disabled": {
						background: "#E6E6E6",
						color: "#B0B0B0",
						cursor: "default",
					},
				},
				contained: {
					boxShadow: "none",
					":hover": {
						boxShadow: "none",
					},
				},
				containedPrimary: {
					color: "#fff",
					background: "#000",
					border: "1px solid #000",
					":hover": {
						background: "#222",
					},
					":disabled": {
						borderColor: "#E6E6E6",
					},
					"&.Mui-disabled": {
						borderColor: "#E6E6E6",
					},
				},
				outlinedPrimary: {
					color: "#eaeaea",
					background: "transparent",
					border: "2px solid #fff",
					":hover": {
						color: "#eaeaea",
						border: "2px solid #fff",
					},
				},
				outlinedSecondary: {
					color: "#000",
					background: "#fff",
					border: "1px solid #000",
					":hover": {
						background: "#fff",
						color: "#222",
						borderColor: "#222",
					},
				},
				containedError: {
					background: "red",
					color: "#FFF",
				},
			},
			variants: [
				{
					props: {className: "byClassName"},
					style: {
						background: "yellow",
						color: "#000",
						":hover": {
							background: "blue",
						},
					},
				},
				{
					props: {className: "byClassName"},
					style: {
						background: "green",
						color: "#77c600",
						":hover": {
							background: "blue",
						},
					},
				},
			],
		},
	},
};
