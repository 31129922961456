import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {
	IStreakRanking,
	type IStreakRankingsStore,
} from "data/stores/streak_rankings/streak_rankings.store";
import {Bindings} from "data/constants/bindings";
import {type IUserStore} from "data/stores/user/user.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";

export interface IStreakRankingsTableController extends ViewController {
	readonly i18n: ILocalizationStore;

	get rankings(): IStreakRanking[];
	get userRanking(): IStreakRanking | null;
	get isShowStickyUserRanking(): boolean;
	get nextPage(): boolean;
	get isShowRankStatus(): boolean;
}

@injectable()
export class StreakRankingsTableController implements IStreakRankingsTableController {
	get isShowStickyUserRanking() {
		const rankingsListHasUser = this.rankings.some(
			({userId}) => userId === this._userStore.user?.id
		);

		return Boolean(this.userRanking && !rankingsListHasUser);
	}

	get rankings() {
		return this._streakRankingsStore.list;
	}

	get userRanking() {
		return this._streakRankingsStore.user;
	}

	get nextPage() {
		return this._streakRankingsStore.nextPage;
	}

	get isShowRankStatus() {
		return this._roundsStore.actualRound?.id !== 1;
	}

	constructor(
		@inject(Bindings.StreakRankingsStore) private _streakRankingsStore: IStreakRankingsStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}
}
