import {FC} from "react";
import sportsBetIcon from "assets/img/icons/sportsBet.svg";
import {AutoPickSponsorType, AutoPickType, TournamentIconType} from "data/enums";
import {IAutoPick} from "data/types/game";
import {ReactComponent as SelectIcon} from "assets/img/icons/tournamentStatuses/select.svg";
import {ReactComponent as LockIcon} from "assets/img/icons/tournamentStatuses/lock.svg";
import {ReactComponent as SuccessIcon} from "assets/img/icons/tournamentStatuses/success.svg";
import {ReactComponent as WrongIcon} from "assets/img/icons/tournamentStatuses/wrong.svg";
import {ReactComponent as AutoSuccessIcon} from "assets/img/icons/tournamentStatuses/autoSuccess.svg";
import {ReactComponent as AutoWrongIcon} from "assets/img/icons/tournamentStatuses/autoWrong.svg";
import {ReactComponent as DisabledIcon} from "assets/img/icons/tournamentStatuses/disabled.svg";
import {ReactComponent as CheckboxIcon} from "assets/img/icons/tournamentStatuses/checkbox.svg";
import {ReactComponent as EmptyIcon} from "assets/img/icons/tournamentStatuses/empty.svg";
import {ReactComponent as SkippedIcon} from "assets/img/icons/tournamentStatuses/skipped.svg";

export const autoPickList: IAutoPick[] = [
	{
		defaultLocoValue: "User Favourites",
		locoKey: "registration.sign_up.autopick_1",
		value: AutoPickType.USER_FAVORITES,
	},
	{
		defaultLocoValue: "Ladder Position",
		locoKey: "registration.sign_up.autopick_2",
		value: AutoPickType.LADDER_RANK,
		isSeasonStarted: true,
	},
	{
		defaultLocoValue: "Sportsbet Favourites",
		locoKey: "registration.sign_up.autopick_3",
		value: AutoPickType.SPORTS_BET,
		icon: sportsBetIcon,
		className: "sportsBet",
		sponsorType: AutoPickSponsorType.SPORTS_BET,
	},
	{
		defaultLocoValue: "Favourites",
		locoKey: "registration.sign_up.autopick_5",
		value: AutoPickType.SPORTS_BET,
		className: "sportsBet no-sponsor",
		sponsorType: AutoPickSponsorType.NO_SPONSOR,
	},
	{
		defaultLocoValue: "Home Teams",
		locoKey: "registration.sign_up.autopick_4",
		value: AutoPickType.HOME_TEAMS,
	},
];

export const tournamentIcons: Record<TournamentIconType, FC> = {
	[TournamentIconType.EMPTY]: EmptyIcon,
	[TournamentIconType.UN_CHECK]: CheckboxIcon,
	[TournamentIconType.CHECK]: SelectIcon,
	[TournamentIconType.LOCK]: LockIcon,
	[TournamentIconType.SUCCESS]: SuccessIcon,
	[TournamentIconType.WRONG]: WrongIcon,
	[TournamentIconType.AUTO_SUCCESS]: AutoSuccessIcon,
	[TournamentIconType.AUTO_WRONG]: AutoWrongIcon,
	[TournamentIconType.DISABLED]: DisabledIcon,
	[TournamentIconType.SKIPPED]: SkippedIcon,
};

export const tournamentTitle: Record<TournamentIconType, string> = {
	[TournamentIconType.EMPTY]: "tips.card_icon.un_checked",
	[TournamentIconType.UN_CHECK]: "tips.card_icon.un_checked",
	[TournamentIconType.CHECK]: "tips.card_icon.checked",
	[TournamentIconType.LOCK]: "tips.card_icon.locked",
	[TournamentIconType.SUCCESS]: "tips.card_icon.success",
	[TournamentIconType.WRONG]: "tips.card_icon.wrong",
	[TournamentIconType.AUTO_SUCCESS]: "tips.card_icon.auto_checked",
	[TournamentIconType.AUTO_WRONG]: "tips.card_icon.auto_wrong",
	[TournamentIconType.DISABLED]: "tips.card_icon.disabled",
	[TournamentIconType.SKIPPED]: "tips.card_icon.skipped",
};

export const TUTORIAL_STORAGE_KEY = "tutorial";
export const STREAK_TUTORIAL_STORAGE_KEY = "streak_tutorial";

export const AdsAgeLimit = 18;
