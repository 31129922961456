import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {useIsMobile} from "data/hooks/useIsMobile";
import {IHeaderController} from "views/components/header/header/header.controller";
import {IHeader} from "views/components/header/header/header.component";
import {ReactComponent as MyTips} from "assets/img/MyTips.svg";
import {ReactComponent as Leagues} from "assets/img/Leagues.svg";
import {ReactComponent as Rankings} from "assets/img/Rankings.svg";
import {ReactComponent as More} from "assets/img/More.svg";
import {css} from "@emotion/react";
import {ILocalizationController} from "views/controllers/localization/localization.controller";

const Wrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	position: sticky;
	bottom: 0;
	z-index: 5;
	background: ${({theme}) => theme.palette.header.bg};
	height: 84px;
	font-size: 12px;
`;

const FooterNavItem = styled.button`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5px;
	border: none;
	background-color: white;

	svg {
		width: 20px;
		height: 20px;
	}
`;

interface IStyledLinkProps {
	isActive?: boolean;
}

const FooterNavLink = styled.a<IStyledLinkProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5px;
	svg {
		width: 20px;
		height: 20px;

		path {
			fill: ${({theme}) => theme.palette.footer.inActiveLink};
		}
	}

	${(props) =>
		props.isActive &&
		css`
			svg {
				background-color: #ededed;
				border-radius: 50%;
				path {
					fill: black;
				}
			}
		`}
`;

const MoreButton = styled.span`
	background-color: white;
	border: none;
`;

export const FooterNav: React.FC<IHeader> = observer(
	({LogoIcon, subNavItems, burgerNavItems, isHideMobileMenu = false, isHideSubNav = false}) => {
		const {toggleMenu} = useViewController<IHeaderController>(Bindings.HeaderController);
		const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
		const {isMobile} = useIsMobile();

		if (!isMobile) {
			return null;
		}

		const path = window.location.pathname;

		return (
			<Wrapper>
				<FooterNavLink href="/my-tips" isActive={path === "/my-tips"}>
					<MyTips />
					{i18n.t("navigation.my_tips.copy", "My Tips")}
				</FooterNavLink>
				<FooterNavLink href="/comps" isActive={path === "/comps"}>
					<Leagues />
					{i18n.t("navigation.comps.copy", "Leagues")}
				</FooterNavLink>
				<FooterNavLink href="/rankings" isActive={path === "/rankings"}>
					<Rankings />
					{i18n.t("navigation.rankings.copy", "Rankings")}
				</FooterNavLink>
				<FooterNavItem onClick={toggleMenu}>
					<More />
					<MoreButton>{i18n.t("navigation.more.copy", "More")}</MoreButton>
				</FooterNavItem>
			</Wrapper>
		);
	}
);
