import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {ILoginBackdoorPayload, IRegisterBackdoorPayload} from "./nrl_id.api.provider";
import {IUser} from "data/stores/user/user.store";

type TLoginResponse = IApiResponse<{
	user: IUser | null;
}>;
export interface IAuthApiProvider {
	logout: () => Promise<AxiosResponse>;
	login: (params: ILoginBackdoorPayload) => Promise<AxiosResponse<TLoginResponse>>;
	register: (params: IRegisterBackdoorPayload) => Promise<AxiosResponse<TLoginResponse>>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	logout = () => this._http.post("auth/logout");

	login = (params: ILoginBackdoorPayload) =>
		this._http.post<TLoginResponse>("auth/login", params);

	register = (params: IRegisterBackdoorPayload) =>
		this._http.post<TLoginResponse>("auth/register", params);
}
