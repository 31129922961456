import React from "react";
import {GameBannerType} from "data/enums";
import styled from "@emotion/styled";
import {ReactComponent as StateOfOrigin} from "assets/img/stateOfOrigin.svg";

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px 4px 0px 0px;
	padding: 5px 10px;

	&.${GameBannerType.STATE_OF_ORIGIN} {
		background: linear-gradient(180deg, #222 0%, #000 100%);
	}
`;

interface ICardBanner {
	type: GameBannerType | null;
}

export const CardBanner: React.FC<ICardBanner> = ({type}) => {
	if (type === GameBannerType.STATE_OF_ORIGIN) {
		return (
			<Wrapper className={type}>
				<StateOfOrigin />
			</Wrapper>
		);
	}

	return null;
};
