import {sortBy} from "lodash";
import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ISquad, type ISquadsStore} from "data/stores/squads/squads.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";

export interface ILadderController extends ViewController {
	readonly i18n: ILocalizationStore;

	get squads(): ISquad[];
	get isShowPosColumn(): boolean;
	get className(): string;
}

@injectable()
export class LadderController implements ILadderController {
	get isShowPosColumn() {
		return this.squads.every(({ladder}) => ladder.position !== null);
	}

	get className() {
		return this.isShowPosColumn ? "with-pos" : "";
	}

	get squads() {
		return sortBy(this._squadsStore.regList, "ladder.position");
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}
}
