import {MutableRefObject, useRef} from "react";
import Slider from "react-slick";
import {inject, injectable} from "inversify";
import {get, isNumber} from "lodash";
import {makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {MatchStatus, ModalType} from "data/enums";
import {type IRoundsStore, ITournament} from "data/stores/rounds/rounds.store";

export interface IModalGameStatsController extends ViewController {
	i18n: ILocalizationStore;
	sliderRef: MutableRefObject<Slider | null>;

	get isOpen(): boolean;
	get index(): number;
	get tournaments(): ITournament[];
	get initialIndex(): number;

	close: () => void;
	afterChange: (currentSlide: number) => void;
}

@injectable()
export class ModalGameStatsController implements IModalGameStatsController {
	@observable _index: number | null = null;
	@observable public sliderRef = useRef<Slider>(null);
	@observable private _disposer?: ReturnType<typeof reaction>;
	@observable private _openDisposer?: ReturnType<typeof reaction>;

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.GAME_STATS;
	}

	get index() {
		return this._index || 0;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get tournaments() {
		const roundId = this.modalContent?.roundId || 0;

		return get(this._roundsStore.getRoundById(roundId), "tournaments", []).filter(
			({status}) => status === MatchStatus.Scheduled
		);
	}

	get initialIndex() {
		return this.tournaments?.findIndex(({id}) => id === this.modalContent?.tournamentId);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	init() {
		this._disposer = reaction(
			() => this._index,
			(sliderIndex) => {
				if (isNumber(sliderIndex)) {
					this.sliderRef.current?.slickGoTo(sliderIndex);
				}
			}
		);

		this._openDisposer = reaction(
			() => this.isOpen,
			(isOpen) => {
				if (isOpen) {
					runInAction(() => {
						if (isNumber(this.initialIndex) && this.initialIndex !== -1) {
							this._index = this.initialIndex;
						}
					});
				}
			}
		);
	}

	afterChange = (currentSlide: number) => {
		this._index = currentSlide;
	};

	dispose() {
		this._disposer?.();
		this._openDisposer?.();
	}

	close = () => {
		this._modalsStore.hideModal();
	};
}
