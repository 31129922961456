import React, {Fragment, useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import {Countdown} from "data/utils/countdown";
import {type ILocalizationStore} from "data/stores/localization/localization.store";

export interface ITimerProps {
	i18n: ILocalizationStore;
	date: string;
	onComplete?: () => void;
	selectedRoundId?: number;
}

export const Timer: React.FC<ITimerProps> = observer(
	({i18n, date, onComplete, selectedRoundId}) => {
		const timerRef = useRef(new Countdown(new Date(date)));

		const [{days, hours, minutes, seconds}, setTime] = useState({
			days: "00",
			hours: "00",
			minutes: "00",
			seconds: "00",
		});

		useEffect(() => {
			const timer = timerRef.current;

			timer
				.clear()
				.updateDate(new Date(date))
				.onTick((days, hours, minutes, seconds) =>
					setTime({
						days: days.toString().padStart(2, "0"),
						hours: hours.toString().padStart(2, "0"),
						minutes: minutes.toString().padStart(2, "0"),
						seconds: seconds.toString().padStart(2, "0"),
					})
				)
				.onComplete(() => onComplete?.())
				.run();

			return () => {
				timer.clear();
			};
		}, [timerRef, date, onComplete]);

		if (days !== "00") {
			return (
				<Fragment>
					{i18n.t("tips.banner.countdown", "Round {{X}} starts in", {X: selectedRoundId})}{" "}
					{days}D:{hours}H:{minutes}M
				</Fragment>
			);
		}

		return (
			<Fragment>
				{i18n.t("tips.banner.countdown", "Round {{X}} starts in", {X: selectedRoundId})}{" "}
				{hours}H:{minutes}M:{seconds}S
			</Fragment>
		);
	}
);
