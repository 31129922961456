import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {first} from "lodash";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IBannerStore} from "data/stores/banner/banner.store";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {BannerType} from "data/enums";

export interface IGameBannerController extends ViewController {
	readonly i18n: ILocalizationStore;

	get bannerType(): BannerType | null;
	get selectedRoundId(): number;
	get selectedRoundStartDate(): string;
	get nextRoundId(): number;
	get nextRoundStartDate(): string;
	get predictionsCount(): number;
	get totalPredictionsCount(): number;
	get className(): string;
}

const classNames = {
	[BannerType.Live]: "live",
	[BannerType.Timer]: "timer",
	[BannerType.NextRoundTimer]: "timer",
	[BannerType.TipsSaving]: "saving",
	[BannerType.TipsSaved]: "saved",
};

@injectable()
export class GameBannerController implements IGameBannerController {
	get bannerType() {
		return this._bannerStore.tippingBannerType;
	}

	get selectedRoundId() {
		return this._roundsStore.selectedRound?.id || 1;
	}

	get selectedRoundStartDate() {
		return (
			first(this._roundsStore.selectedRound?.tournaments)?.date || new Date().toISOString()
		);
	}

	get predictionsCount() {
		return this._predictionsStore.predictions.length;
	}

	get totalPredictionsCount() {
		return this._roundsStore.selectedRound?.tournaments.length || 0;
	}

	get nextRoundStartDate(): string {
		const firstScheduledRound = first(this._roundsStore.scheduleRounds);
		return first(firstScheduledRound?.tournaments)?.date || new Date().toISOString();
	}

	get nextRoundId() {
		return first(this._roundsStore.scheduleRounds)?.id || 1;
	}

	get className() {
		if (this.bannerType === null) {
			return "";
		}

		return classNames[this.bannerType];
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.BannerStore) private _bannerStore: IBannerStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}
}
