import {makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import {DeviceType} from "data/enums";

export interface IWebViewGateStore {
	setDevice(device: DeviceType): void;

	setHideHeader(flag: boolean): void;

	get isFromApp(): boolean;

	get hideHeader(): boolean;

	get device(): DeviceType;

	get isIos(): boolean;

	get isIAndroid(): boolean;

	sendPostMessage(message: {action: string; path: string}): void;
}

@injectable()
export class WebViewGateStore implements IWebViewGateStore {
	@observable _device = DeviceType.Web;
	@observable _hideHeader = false;

	constructor() {
		makeAutoObservable(this);
	}

	setDevice(device: DeviceType) {
		this._device = device;
	}

	setHideHeader(flag: boolean) {
		this._hideHeader = flag;
	}

	get device() {
		return this._device;
	}

	get isFromApp() {
		return [DeviceType.Android, DeviceType.IOS].includes(this._device);
	}

	get isIos() {
		return this.device === DeviceType.IOS;
	}

	get isIAndroid() {
		return this.device === DeviceType.Android;
	}

	get hideHeader() {
		return this._hideHeader;
	}

	sendPostMessage(message: {action: string; path: string}) {
		if (this.device === DeviceType.IOS) {
			window?.ios?.postMessage(message);
		}
		if (this.device === DeviceType.Android) {
			window?.android?.postMessage(JSON.stringify(message));
		}
	}
}
