import {DateTime} from "luxon";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import type {INRLIdProfile, IOldUser, IUser, IUserStore} from "data/stores/user/user.store";
import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import {
	MAX_FILE_SIZE,
	PUBLIC_URL,
	REACT_APP_SSO_REDIRECT_URI,
	SportbetAgeLimit,
} from "data/constants";
import {RequestState} from "data/enums";
import {AxiosError} from "axios";
import {useNavigate} from "react-router";
import {IApiResponse} from "data/services/http";
import type {IUserApiProvider, IUsername} from "data/providers/api/user.api.provider";
import {extractErrorMessage} from "data/utils";
import {type ICountriesStore, ICountry} from "data/stores/countries/countries.store";
import {ISquad, type ISquadsStore} from "data/stores/squads/squads.store";
import {get, identity, pickBy} from "lodash";
import React, {ChangeEvent} from "react";
import type {IState, IStatesStore} from "data/stores/states/states.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {IAutoPick} from "data/types/game";
import {
	IRegisterFromAppPayload,
	IRegistrationPayload,
} from "data/providers/api/nrl_id.api.provider";
import {toFormData} from "data/utils/toFormData";
import {type IAutoPickStore} from "data/stores/auto_pick/auto_pick.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import Cookies from "js-cookie";
import type {IWebViewGateStore} from "data/stores/web_view_gate/web_view_gate.store";

interface IRegisterForm extends HTMLFormElement {
	displayName: HTMLInputElement;
	country: HTMLSelectElement;
	state: HTMLSelectElement;
	gender: HTMLSelectElement;
	sponsorOptIn: HTMLInputElement;
	terms: HTMLInputElement;
	supportedSquad: HTMLSelectElement;
	avatar: HTMLInputElement;
	autoPick: HTMLSelectElement;
	date: HTMLInputElement;
}

interface IInit {
	code: string;
}

export interface ILoginCallbackController extends ViewController<IInit> {
	readonly i18n: ILocalizationStore;

	get state(): IFormState;
	get loginAgain(): boolean;
	get user(): IUser | null;
	get oldUser(): IOldUser | null;
	get countryList(): ICountry[];
	get states(): IState[];
	get nrlUserData(): INRLIdProfile | null;
	get error(): Record<string, string> | null;
	get isFormDisabled(): boolean;
	get teamNameChecking(): boolean;
	get isLoaded(): boolean;
	get squads(): ISquad[];
	get avatarPreview(): string;
	get autoPickList(): IAutoPick[];
	get isShowSponsor(): boolean;
	get squadInputHelpText(): string | undefined;
	get isShowBirthday(): boolean;
	get isFromAPP(): boolean;
	get showGender(): boolean;
	get showState(): boolean;

	handleFormOnChange: (name: keyof IFormState) => (e: ChangeEvent<HTMLInputElement>) => void;
	handleDateChange: () => void;
	handleFormSubmit: (event: React.SyntheticEvent<IRegisterForm>) => void;
	checkTeamName: (name: string) => void;
	fileChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
	redirectLogin: () => void;
}

interface IFormState {
	country: string;
	state: string;
	gender: string | null;
	sponsorOptIn: boolean;
	terms: boolean;
	displayName: string;
	supportedSquad: string;
	avatar: File | null;
	autoPick: string;
	date: string;
}

@injectable()
export class LoginCallbackController implements ILoginCallbackController {
	@observable private _code: string = "";
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable private _teamNameCheck: RequestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _hasCheckedLogin = false;
	@observable private _loginAgain = false;
	@observable private _state: IFormState = {
		country: "",
		state: "",
		gender: "",
		sponsorOptIn: false,
		displayName: "",
		supportedSquad: "",
		terms: false,
		avatar: null,
		autoPick: "",
		date: "",
	};
	@observable private _navigate!: ReturnType<typeof useNavigate>;

	get loginAgain() {
		return this._loginAgain;
	}

	get state() {
		return this._state;
	}

	get user() {
		return this._userStore.user;
	}

	get oldUser() {
		return this._userStore.oldUser;
	}

	get nrlUserData() {
		return this._userStore.nrlIdProfile;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get countryList() {
		return this._countriesStore.list;
	}

	get states() {
		return this._statesStore.list;
	}

	get isFormDisabled() {
		return this._requestState === RequestState.PENDING;
	}

	get teamNameChecking() {
		return [RequestState.PENDING].includes(this._teamNameCheck);
	}

	get isLoaded() {
		return this._hasCheckedLogin;
	}

	get squads() {
		return this._squadsStore.regList;
	}

	get avatarPreview() {
		if (!this._state.avatar) {
			return "";
		}

		return URL.createObjectURL(this._state.avatar);
	}

	get isShowBirthday() {
		return !this._userStore.nrlIdProfile?.birthday?.length && !this.isFromAPP;
	}

	get showGender() {
		return !this.nrlUserData?.gender && !this.isFromAPP;
	}

	get showState() {
		return this.state.country === "AU" && !this.isFromAPP;
	}

	@computed get isShowSponsor() {
		const birthday = this._userStore.nrlIdProfile?.birthday || "";
		return (
			DateTime.fromFormat(birthday, "yyyy-MM-dd").diffNow("years").years < -SportbetAgeLimit
		);
	}

	get autoPickList() {
		return this._autoPickStore.getAutoPickList(
			this._autoPickStore.registrationList,
			this.isShowSponsor
		);
	}

	get squadInputHelpText() {
		const squadError = this.error?.supportedSquad;
		const infoText = this.i18n.t(
			"registration.sign_up.squad_select_note",
			"You must select a favourite team to auto-enter in a official club championship"
		);

		return squadError || infoText;
	}

	// Hide some fields if is from APP view
	get isFromAPP() {
		return Boolean(this._webViewGateStore.isFromApp);
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider,
		@inject(Bindings.CountriesStore) private _countriesStore: ICountriesStore,
		@inject(Bindings.StatesStore) private _statesStore: IStatesStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.AutoPickStore) private _autoPickStore: IAutoPickStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.WebViewGateStore) private _webViewGateStore: IWebViewGateStore
	) {
		makeAutoObservable(this);
	}

	async init(params: IInit) {
		this._code = params.code;

		const sid = Cookies.get("NRLSID");

		if (!sid && this._webViewGateStore.isFromApp) {
			this._loginAgain = true;
		}

		if (sid && this._webViewGateStore.isFromApp) {
			this._userStore.setAppSid(sid);
		}

		if (!this._userStore.appSid && !this._webViewGateStore.isFromApp) {
			await this.login();
		}

		await this.fetchRegisterData();
	}

	redirectLogin = () => {
		this._loginAgain = false;
		sessionStorage.removeItem("redirectPath");
		window.location.href = PUBLIC_URL;
	};

	login = async () => {
		try {
			await this._userStore.login({
				code: this._code,
				codeVerifier: localStorage.getItem("code_verifier") || "",
				redirectUrl: REACT_APP_SSO_REDIRECT_URI,
			});
		} catch (error) {
			this.onError(error as AxiosError<IApiResponse>);
		}
	};

	private fetchRegisterData = async () => {
		try {
			if (!this.user) {
				const requests = [this._squadsStore.fetchSquads()];
				if (!this._userStore.appSid) {
					requests.push(
						this._countriesStore.fetchCountries(),
						this._statesStore.fetchStates(),
						this._roundsStore.fetchRounds()
					);
				}
				await Promise.all(requests);
			}

			runInAction(() => {
				this._state.displayName = get(this.oldUser, "displayName", "");
				this._state.gender = get(this.oldUser, "gender", "");
				this._state.country = this.oldUser?.country || "AU";
				this._state.state = get(this.oldUser, "state", "");
			});
		} catch (error) {
			this.onError(error as AxiosError<IApiResponse>);
		} finally {
			runInAction(() => {
				this._hasCheckedLogin = true;
			});
		}
	};

	private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._errorMsg = extractErrorMessage(error);
	};

	private clearState = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._requestState = RequestState.IDLE;
	};

	handleFormOnChange = (name: keyof IFormState) => (e: ChangeEvent<HTMLInputElement>) => {
		this.clearState();

		this._state = {
			...this._state,
			[name]: e.target.value,
		};
	};

	handleDateChange = () => {
		this.clearState();
	};

	checkTeamName = async (name: string) => {
		this._teamNameCheck = RequestState.PENDING;

		const payload: IUsername = {
			// displayName: e.target.value.trim(),
			displayName: name,
		};

		try {
			await this._userApi.checkUsername(payload);
			this._errorMsg = "";
			this._teamNameCheck = RequestState.SUCCESS;
		} catch (error) {
			const err = error as AxiosError<IApiResponse>;
			this._teamNameCheck = RequestState.ERROR;
			const msg = err.response?.data.errors[0].message || "";
			this._errorPlace = "displayName";
			this._errorMsg = msg;
		}
	};

	fileChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		this._errorMsg = "";
		this._errorPlace = "";
		this._requestState = RequestState.IDLE;

		const target = e.target as HTMLInputElement;
		const files = target.files || [];
		const file = files[0];

		target.value = "";

		if (file?.size > MAX_FILE_SIZE) {
			this.reportError(
				this.i18n.t("registration.sign_up.display_name_error", "File is too big"),
				"avatar"
			);
			return;
		}

		this._state.avatar = file;
	};

	private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	hasValidationError = (event: React.SyntheticEvent<IRegisterForm>) => {
		const {country, state, gender, displayName, terms, supportedSquad, autoPick, date} =
			event.currentTarget;

		const validateList = [
			{
				field: displayName,
				error: this.i18n.t(
					"registration.sign_up.display_name_error",
					"Please provide your username"
				),
				place: "displayName",
			},
			{
				field: terms,
				error: this.i18n.t("registration.sign_up.terms_error", "Please check terms"),
				place: "terms",
			},
			{
				field: autoPick,
				error: this.i18n.t(
					"registration.sign_up.auto_pick_error",
					"Please select Auto-pick logic"
				),
				place: "autoPick",
			},
			{
				field: supportedSquad,
				error: this.i18n.t(
					"registration.sign_up.supported_squad_error",
					"Please select team"
				),
				place: "supportedSquad",
			},
		];

		if (!this.isFromAPP) {
			validateList.push(
				{
					field: gender,
					error: this.i18n.t(
						"registration.sign_up.gender_error",
						"Please fill in your gender"
					),
					place: "gender",
				},
				{
					field: country,
					error: this.i18n.t(
						"registration.sign_up.country_error",
						"Please provide your country"
					),
					place: "country",
				}
			);

			if (country.value === "AU" && !state.value) {
				validateList.push({
					field: state,
					error: this.i18n.t(
						"registration.sign_up.state_error",
						"Please select one of the options"
					),
					place: "state",
				});
			}
		}

		if (this.isShowBirthday) {
			validateList.push({
				field: date,
				error: this.i18n.t(
					"registration.sign_up.date_picker_error",
					"Please select date of birth"
				),
				place: "date",
			});
		}

		return validateList.find(({field, error, place}) => {
			if (!field) {
				return false;
			}
			return field.checkValidity() ? false : this.reportError(error, place);
		});
	};

	@action handleFormSubmit = async (event: React.SyntheticEvent<IRegisterForm>) => {
		event.preventDefault();

		const {country, state, gender, sponsorOptIn, displayName, supportedSquad, autoPick, date} =
			event.currentTarget;

		if (this.hasValidationError(event)) {
			return;
		}

		await this.checkTeamName(displayName.value);

		this._requestState = RequestState.PENDING;

		const params = this.isFromAPP
			? ({
					nrlSessionId: this._userStore.appSid,
					avatar: this._state.avatar,
			  } as unknown as IRegisterFromAppPayload)
			: (pickBy(
					{
						code: this._code,
						codeVerifier: localStorage.getItem("code_verifier") || "",
						redirectUrl: REACT_APP_SSO_REDIRECT_URI,
						country: country.value,
						state: state?.value,
						gender: gender?.value,
						autopickPreference: autoPick.value,
						avatar: this._state.avatar,
						birthday: date?.value,
					},
					identity
			  ) as unknown as IRegistrationPayload);

		const formData = toFormData({
			...params,
			displayName: displayName.value.trim(),
			supportedSquadId: supportedSquad?.value,
			autopickPreference: autoPick.value,
			sponsorOptIn: sponsorOptIn.checked,
		});

		return this._userStore
			.register(formData)
			.then(() => {
				this._requestState = RequestState.SUCCESS;
			})
			.catch(this.onError);
	};
}
