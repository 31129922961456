import React, {Fragment} from "react";
import {observer} from "mobx-react";
import {Exist} from "views/components/exist/exist.component";
import styled from "@emotion/styled";
import {IconButton, Snackbar} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {INotificationBarController} from "views/components/notification_bar/notification_bar.controller";
import {useIsMobile} from "data/hooks/useIsMobile";

const NotificationBarWrapper = styled.div`
	display: flex;
	width: 100%;
	max-width: 1440px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	background: none;
	border-radius: 4px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
	background: #fff;
	@media (max-width: 820px) {
		display: block;
		padding: 12px 20px;
	}
	button {}
`;

const NotificationBarHeader = styled.div`
	font-size: 18px;
	font-weight: 400;
	line-height: 160%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	//color: var(--tabx-primary-tab-line-green);

	svg {
		width: 24px;
		height: 24px;
	}
`;

const NotificationBarMessage = styled.div`
	word-wrap: break-word;
	overflow-wrap: break-word;
	max-width: 85%;
	text-align: center;
	display: flex;
	flex: auto;
	justify-content: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 160%;
`;

const StyledSnackbar = styled(Snackbar)<{isMobile: boolean}>`
	width: 100%;
	padding: 10px;
	left: unset;
	right: unset;
`;

export const NotificationBar: React.FC = observer(() => {
	const {isEnabled, message, close} = useViewController<INotificationBarController>(
		Bindings.NotificationBarController
	);
	const {isMobile} = useIsMobile();

	return (
		<Fragment>
			<Exist when={isEnabled}>
				<StyledSnackbar
					isMobile={isMobile}
					anchorOrigin={{vertical: "bottom", horizontal: "center"}}
					open={isEnabled}>
					<NotificationBarWrapper>
						<NotificationBarHeader>
							<NotificationBarMessage>{message}</NotificationBarMessage>
							<IconButton aria-label="delete" onClick={close}>
								<CloseIcon />
							</IconButton>
						</NotificationBarHeader>
					</NotificationBarWrapper>
				</StyledSnackbar>
			</Exist>
		</Fragment>
	);
});
