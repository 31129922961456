import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRound, IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ChangeEvent, ReactNode} from "react";
import type {SelectChangeEvent} from "@mui/material";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {LeaguePrivacy, ModalType, RequestState} from "data/enums";
import {chain, first, identity, isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import {useNavigate} from "react-router-dom";
import {MAX_FILE_SIZE} from "data/constants";

interface IForm extends HTMLFormElement {
	leagueName: HTMLInputElement;
	startId: HTMLInputElement;
	privacy: HTMLInputElement;
}

interface IFormValue {
	avatar: File | null;
	leagueName: string;
	startId: number;
	privacy: LeaguePrivacy;
}

interface IParam {
	navigate: ReturnType<typeof useNavigate>;
}

export interface ICreateLeagueController extends ViewController<IParam> {
	readonly i18n: ILocalizationStore;

	get rounds(): IRound[];
	get isLoading(): boolean;
	get isCreateButtonDisabled(): boolean;
	get isFormDisabled(): boolean;
	get formValue(): IFormValue;
	get avatarError(): string;

	handleCreateLeague: () => void;
	handleFormChange: (event: ChangeEvent<IForm>) => void;
	handleAvatarChange: (e: ChangeEvent<HTMLInputElement>) => void;
	startRoundChange: (event: SelectChangeEvent<unknown>, _: ReactNode) => void;
}

@injectable()
export class CreateLeagueController implements ICreateLeagueController {
	@observable private _navigate?: ReturnType<typeof useNavigate>;
	@observable private _roundsDisposer?: ReturnType<typeof reaction>;
	@observable private _requestState = RequestState.PENDING;
	@observable private _formValue: IFormValue = {
		avatar: null,
		leagueName: "",
		startId: 0,
		privacy: LeaguePrivacy.PRIVATE,
	};
	@observable private _avatarError: string = "";

	get rounds() {
		return this._roundsStore.scheduleRounds;
	}

	get formValue() {
		return this._formValue;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isFormDisabled() {
		return Boolean(this.isLoading);
	}

	get isCreateButtonDisabled() {
		return this.isFormDisabled || !this.isFormValid;
	}

	@computed private get isFormValid() {
		return chain(this.formValue).omit("avatar").values().every(identity).value();
	}

	get avatarError() {
		return this._avatarError;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onSuccessLeagueCreate = (league: ILeague) => {
		this._requestState = RequestState.SUCCESS;
		this._navigate?.(`/comp/${league.id}/manage`);
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	handleCreateLeague = () => {
		this._requestState = RequestState.PENDING;

		const {leagueName, startId, privacy, avatar} = this._formValue;

		this._leaguesStore
			.createLeague({
				privacy,
				startId,
				name: leagueName,
				avatar,
			})
			.then(this.onSuccessLeagueCreate)
			.catch(this.onError);
	};

	@action handleFormChange = (event: ChangeEvent<IForm>) => {
		const {leagueName, privacy} = event.currentTarget;

		this._formValue.leagueName = leagueName.value;
		this._formValue.privacy = privacy.value as LeaguePrivacy;
	};

	@action handleAvatarChange = (e: ChangeEvent<HTMLInputElement>) => {
		this._avatarError = "";
		const target = e.target as HTMLInputElement;
		const files = target.files || [];
		const file = files[0];

		target.value = "";

		if (file?.size > MAX_FILE_SIZE) {
			this._avatarError = this.i18n.t("league_create.step_1.avatar_error", "File is too big");
			return;
		}

		this._formValue.avatar = file;
	};

	@action startRoundChange = (event: SelectChangeEvent<unknown>, _: ReactNode) => {
		this._formValue.startId = Number(event.target.value);
	};

	@action init(param: IParam) {
		this._navigate = param.navigate;
		this._roundsStore.fetchRounds().then(this.onSuccess).catch(this.onError);

		this._roundsDisposer = reaction(
			() => this.rounds,
			() => {
				const roundID = first(this.rounds)?.id;

				if (roundID) {
					this._formValue.startId = roundID;
				}
			}
		);
	}

	onChange(param: IParam): void {
		this._navigate = param.navigate;
	}

	dispose() {
		this._roundsDisposer?.();
	}
}
