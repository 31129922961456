import {observer} from "mobx-react";
import React, {Fragment, PropsWithChildren} from "react";
import {useSearchParams} from "react-router-dom";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ISecretGateController} from "views/controllers/secret_gate/secret_gate.controller";

export const SecretGate: React.FC<PropsWithChildren> = observer(({children}) => {
	const [searchParams] = useSearchParams();

	useViewController<ISecretGateController>(Bindings.SecretGateController, {searchParams});

	return <Fragment>{children}</Fragment>;
});
