import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {StreakProgressType} from "data/enums";
import {type IStreakPredictionsStore} from "data/stores/streak_predictions/streak_predictions.store";
import {type IStreakGameBarStore} from "data/stores/streak_game_bar/streak_game_bar.store";

export interface IStreakProgressController extends ViewController {
	i18n: ILocalizationStore;

	get type(): StreakProgressType;
	get currentStreak(): number;
	get leaderStreak(): number;
	get progress(): number;
	get iconProgress(): number;
}

@injectable()
export class StreakProgressController implements IStreakProgressController {
	get gameBarLiveType() {
		if (this.currentStreak === this.leaderStreak) {
			return StreakProgressType.LIVE_LEADER;
		}

		if (this.currentStreak) {
			return StreakProgressType.LIVE_WITH_PREDICTIONS;
		}

		return StreakProgressType.LIVE_WITH_OUT_PREDICTIONS;
	}

	get gameBarType() {
		if (!this._streakPredictionsStore.hasPredictions) {
			return StreakProgressType.NO_PREDICTIONS;
		}

		return StreakProgressType.HAS_PREDICTIONS;
	}

	get type() {
		if (this._streakGameBarStore.gameBar.longestStreak) {
			return this.gameBarLiveType;
		}

		return this.gameBarType;
	}

	get currentStreak() {
		return this._streakGameBarStore.gameBar.currentStreak;
	}

	get leaderStreak() {
		return this._streakGameBarStore.gameBar.leaderStreak;
	}

	get progress() {
		return (this.currentStreak / this.leaderStreak) * 100;
	}

	get iconProgress() {
		return this.progress;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.StreakPredictionsStore)
		private _streakPredictionsStore: IStreakPredictionsStore,
		@inject(Bindings.StreakGameBarStore) private _streakGameBarStore: IStreakGameBarStore
	) {
		makeAutoObservable(this);
	}
}
