import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {useLocation} from "react-router";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type ITutorialStore} from "data/stores/tutorial/tutorial.store";
import {ISubNavItem} from "data/types/navigation";

interface IProps {
	location: ReturnType<typeof useLocation>;
}

export interface ISubNavController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get isShowTutorial(): boolean;

	openTutorialHandler: () => void;
	getLinkClassName: (link: ISubNavItem) => string;
}

@injectable()
export class SubNavController implements ISubNavController {
	@observable private _location?: ReturnType<typeof useLocation>;

	get pathname() {
		return this._location?.pathname;
	}

	get isShowTippingTutorial() {
		return this.pathname === "/my-tips";
	}

	get isShowStreakTutorial() {
		return this.pathname === "/streak/my-tips";
	}

	get isShowTutorial() {
		return this.isShowTippingTutorial || this.isShowStreakTutorial;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this.onChange(param);
	}

	onChange(param: IProps) {
		this._location = param.location;
	}

	openTutorialHandler = () => {
		this._tutorialStore.setIsOpen(true);
		this._tutorialStore.setActiveStep(1);
	};

	getLinkClassName = (link: ISubNavItem) => {
		if (link.path === "/comps" && this._location?.pathname.startsWith("/comp")) {
			return "active";
		}

		return "";
	};
}
