import {inject, injectable} from "inversify";
import {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {IApiResponse, type IHttpClientService} from "data/services/http";

interface IShareEmailPayload {
	roundId: number;
}

interface IDownloadImagePayload {
	roundId: number;
}

type DownloadImageResponse = IApiResponse<{image: string}>;

export interface IShareApiProvider {
	email: (payload: IShareEmailPayload) => Promise<AxiosResponse>;
	dynamicImage: (payload: IDownloadImagePayload) => Promise<AxiosResponse<DownloadImageResponse>>;
}

@injectable()
export class ShareApiProvider implements IShareApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	email = (payload: IShareEmailPayload) =>
		this._http.post(`tipping/share-email/${payload.roundId}`);

	dynamicImage = (payload: IDownloadImagePayload) =>
		this._http.get<DownloadImageResponse>(`tipping/share-image/${payload.roundId}`);
}
