import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {Button} from "@mui/material";
import {ITournament} from "data/stores/rounds/rounds.store";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {CardPredictor} from "views/components/tipping_card/card_predictor/card_predictor.component";
import {Container, DateSection} from "views/components/tipping_card/tipping_card.component";
import {Odds} from "views/components/tipping_card/odds/odds.component";
import {StreakCardStats} from "views/pages/streak/streak_tips/streak_card/streak_card_stats/streak_card_stats.component";
import {Exist} from "views/components/exist/exist.component";
import {Preloader} from "views/components/preloader";
import {GameBanner} from "./game_banner/game_banner.component";
import {IGameStatsCardController} from "./game_stats_card.controller";
import {TeamStats} from "./team_stats/team_stats.component";
import {ReactComponent as ExternalLinkIcon} from "assets/img/icons/externalLink.svg";

const Wrapper = styled.div`
	margin: 10px;
	border-radius: 4px 4px 0 0;
	overflow-y: auto;
	position: relative;
	max-height: 80vh;
	background: ${({theme}) => theme.palette.statsModal.statsBg};
`;

const CardPredictorWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 0 8px;

	.team-section {
		gap: 8px;

		@media (min-width: 600px) {
			gap: 24px;
		}

		@media (min-width: 769px) {
			gap: 36px;
		}
	}

	.stats-section {
		@media (min-width: 769px) {
			gap: 13px;
		}
	}
`;

const DateSectionWrapper = styled.div`
	padding: 12px 0 6px;
`;

const OddsContainer = styled(Container)`
	padding: 6px 16px;
`;

const Sticky = styled.div`
	width: 100%;
	top: 0;
	position: sticky;
	background: ${({theme}) => theme.palette.statsModal.bg};
	z-index: 1;
`;

const PreloaderWrapper = styled.div`
	padding: 20px;
	background: ${({theme}) => theme.palette.statsModal.statsBg};
`;

const Actions = styled.div`
	display: flex;
	gap: 10px;
	padding: 12px 16px;
	background: ${({theme}) => theme.palette.statsModal.bg};
	position: sticky;
	bottom: 0;
	left: 0;
`;

const SButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;

	&.black path {
		fill: #000000;
	}
`;

SButton.defaultProps = {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore Add default props for <a/>
	target: "_blank",
	rel: "noreferrer noopener",
};

interface IGameStatsCard {
	tournament: ITournament;
	closeHandler: () => void;
}

export const GameStatsCard: React.FC<IGameStatsCard> = observer(({closeHandler, tournament}) => {
	const {tournamentData, isShowOdds, lastMeetings, isLoading, changeHandler, i18n, matchUpLink} =
		useViewController<IGameStatsCardController>(Bindings.GameStatsCardController, {
			tournamentId: tournament.id,
		});

	if (!tournamentData) {
		return null;
	}

	const {date, homeOdds, awayOdds, awaySquad, homeSquad} = tournamentData;

	return (
		<Wrapper>
			<Sticky>
				<GameBanner closeHandler={closeHandler} />

				<DateSectionWrapper>
					<DateSection>{date}</DateSection>
				</DateSectionWrapper>

				<CardPredictorWrapper>
					<CardPredictor
						tournament={tournamentData}
						changeHandler={changeHandler}
						StatsComponent={StreakCardStats}
					/>
				</CardPredictorWrapper>

				<Exist when={isShowOdds}>
					<OddsContainer>
						<Odds awayOdds={awayOdds?.price} homeOdds={homeOdds?.price} />
					</OddsContainer>
				</Exist>
			</Sticky>

			<Exist when={isLoading}>
				<PreloaderWrapper>
					<Preloader />
				</PreloaderWrapper>
			</Exist>

			<Exist when={!isLoading}>
				<TeamStats
					homeSquadId={homeSquad.id}
					awaySquadId={awaySquad.id}
					tournamentId={tournamentData.id}
					lastMeetings={lastMeetings}
				/>
			</Exist>

			<Actions>
				<SButton variant="contained" type="button" href={matchUpLink}>
					{i18n.t("modal.game_stats.match_up", "Matchup")}
					<ExternalLinkIcon />
				</SButton>

				<SButton
					variant="contained"
					type="button"
					color="brown"
					className="black"
					href="https://www.nrl.com/tickets/">
					{i18n.t("modal.game_stats.tickets", "Tickets")}
					<ExternalLinkIcon />
				</SButton>
			</Actions>
		</Wrapper>
	);
});
