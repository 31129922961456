import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {BannerType} from "data/enums";
import {Timer} from "views/components/timer/timer.component";
import {ITippingBannerController} from "./tipping_banner.controller";

export const BannerWrapper = styled.div`
	&.live {
		color: ${({theme}) => theme.palette.banner.liveColor};
		background: ${({theme}) => theme.palette.banner.liveBg};
	}

	&.timer {
		color: ${({theme}) => theme.palette.banner.timerColor};
		background: ${({theme}) => theme.palette.banner.timerBg};
	}

	&.saving {
		color: ${({theme}) => theme.palette.banner.savingColor};
		background: ${({theme}) => theme.palette.banner.savingBg};
	}

	&.saved {
		color: ${({theme}) => theme.palette.banner.savedColor};
		background: ${({theme}) => theme.palette.banner.savedBg};
	}
`;

const Wrapper = styled(BannerWrapper)`
	height: 48px;
	width: 100%;
	max-width: 350px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;

	@media (min-width: 769px) {
		font-size: 16px;
		height: 44px;
	}
`;

export const GameBarBanner: React.FC = observer(() => {
	const {
		i18n,
		bannerType,
		nextRoundStartDate,
		selectedRoundId,
		totalPredictionsCount,
		predictionsCount,
		onComplete,
		nextRoundId,
		selectedRoundStartDate,
	} = useViewController<ITippingBannerController>(Bindings.TippingBannerController);

	if (bannerType === BannerType.Live) {
		return <Wrapper className="live">{i18n.t("tips.banner.live", "Round is Live")}</Wrapper>;
	}

	if (bannerType === BannerType.Timer) {
		return (
			<Wrapper className="timer">
				<Timer
					i18n={i18n}
					date={selectedRoundStartDate}
					selectedRoundId={selectedRoundId}
					onComplete={onComplete}
				/>
			</Wrapper>
		);
	}

	if (bannerType === BannerType.NextRoundTimer) {
		return (
			<Wrapper className="timer">
				<Timer
					i18n={i18n}
					date={nextRoundStartDate}
					selectedRoundId={nextRoundId}
					onComplete={onComplete}
				/>
			</Wrapper>
		);
	}

	if (bannerType === BannerType.TipsSaving) {
		return (
			<Wrapper className="saving">
				{predictionsCount}/{totalPredictionsCount}{" "}
				{i18n.t("tips.banner.saved", "Tips saved")}
			</Wrapper>
		);
	}

	if (bannerType === BannerType.TipsSaved) {
		return (
			<Wrapper className="saved">
				{predictionsCount}/{totalPredictionsCount}{" "}
				{i18n.t("tips.banner.saved", "Tips saved")}
			</Wrapper>
		);
	}

	return <Wrapper />;
});
