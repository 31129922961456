import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {toFormData, type AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {LeaguePrivacy} from "data/enums";
import type {ILeague, IRecoverLeague} from "data/stores/leagues/leagues.store";

export interface ICreateLeaguePayload {
	name: string;
	startId: number; // Start round (must exist and be with the SCHEDULED status)
	privacy: LeaguePrivacy;
	avatar?: File | null;
}

export interface ILeagueIdPayload {
	leagueId: number;
}

export interface IOldLeagueIdPayload {
	oldLeagueId: number;
}

export interface IPaginationPayload {
	page?: number;
	limit?: number;
	search?: string;
}

export type IUpdateLeaguePayload = Partial<ICreateLeaguePayload> & ILeagueIdPayload;

export interface ILeaguesForJoinPayload extends IPaginationPayload {
	search?: string;
}

export interface ILeagueInvitesPayload extends ILeagueIdPayload {
	invites: {email: string}[];
}

export interface IRemoveUserFromLeaguePayload extends ILeagueIdPayload {
	userId: number;
}

export interface ILeagueCodePayload {
	code: string;
}

export type ILeagueUsersPayload = ILeagueIdPayload & IPaginationPayload;

export interface ILeaguesPayload extends IPaginationPayload {
	search?: string;
	privacy?: LeaguePrivacy;
}

export interface ILeagueManager {
	userId: number | null;
	displayName: string;
}

export interface ILeagueUser extends ILeagueManager {
	userId: number;
	firstName: string;
	lastName: string;
	avatarVersion: number;
}

type ILeagueResponse = IApiResponse<{league: ILeague}>;
type ILeagueByCodeResponse = IApiResponse<ILeague>;
type ILeaguesListResponse = IApiResponse<{leagues: ILeague[]; nextPage: boolean}>;
type ILeagueUserListResponse = IApiResponse<{users: ILeagueUser[]; nextPage: boolean}>;
type IRecoverLeagueListResponse = IApiResponse<{leagues: IRecoverLeague[]}>;

export interface ILeaguesApiProvider {
	createLeague: (params: ICreateLeaguePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	updateLeague: (params: IUpdateLeaguePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeague: (params: ILeagueIdPayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeaguesForJoin: (
		params: ILeaguesForJoinPayload
	) => Promise<AxiosResponse<ILeaguesListResponse>>;
	leaveLeague: (params: ILeagueIdPayload) => Promise<AxiosResponse<void>>;
	removeLeague: (params: ILeagueIdPayload) => Promise<AxiosResponse<void>>;
	inviteUsersToLeague: (params: ILeagueInvitesPayload) => Promise<AxiosResponse<void>>;
	removeUserFromLeague: (params: IRemoveUserFromLeaguePayload) => Promise<AxiosResponse<void>>;
	joinToLeague: (params: ILeagueCodePayload) => Promise<AxiosResponse<ILeagueResponse>>;
	fetchLeagueUsers: (
		params: ILeagueUsersPayload
	) => Promise<AxiosResponse<ILeagueUserListResponse>>;
	fetchMyLeagues: (params: ILeaguesPayload) => Promise<AxiosResponse<ILeaguesListResponse>>;
	fetchLeagueByCode: (
		params: ILeagueCodePayload
	) => Promise<AxiosResponse<ILeagueByCodeResponse>>;
	fetchLeagueInvites: (
		params: IPaginationPayload
	) => Promise<AxiosResponse<ILeaguesListResponse>>;
	acceptInvite: (params: ILeagueIdPayload) => Promise<AxiosResponse<void>>;
	declineInvite: (params: ILeagueIdPayload) => Promise<AxiosResponse<void>>;
	fetchRecoverLeagues: () => Promise<AxiosResponse<IRecoverLeagueListResponse>>;
	recoverSkip: (params: IOldLeagueIdPayload) => Promise<AxiosResponse<void>>;
	recover: (params: IOldLeagueIdPayload) => Promise<AxiosResponse<ILeagueResponse>>;
}

@injectable()
export class LeaguesApiProvider implements ILeaguesApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	createLeague = (params: ICreateLeaguePayload) =>
		this._http.post<ILeagueResponse>("tipping/league", toFormData(params));

	updateLeague = ({leagueId, ...params}: IUpdateLeaguePayload) =>
		this._http.post<ILeagueResponse>(`tipping/league/${leagueId}`, toFormData(params));

	fetchLeague = ({leagueId}: ILeagueIdPayload) =>
		this._http.get<ILeagueResponse>(`tipping/league/${leagueId}`);

	fetchLeaguesForJoin = (params: ILeaguesForJoinPayload) =>
		this._http.get<ILeaguesListResponse>("tipping/league/show-for-join", params);

	leaveLeague = ({leagueId}: ILeagueIdPayload) =>
		this._http.post<void>(`tipping/league/${leagueId}/leave`);

	removeLeague = ({leagueId}: ILeagueIdPayload) =>
		this._http.get<void>(`tipping/league/${leagueId}/delete`);

	inviteUsersToLeague = ({leagueId, ...params}: ILeagueInvitesPayload) =>
		this._http.post<void>(`tipping/league/${leagueId}/invite`, params);

	removeUserFromLeague = ({leagueId, userId}: IRemoveUserFromLeaguePayload) =>
		this._http.post<void>(`tipping/league/${leagueId}/user/${userId}`);

	joinToLeague = ({code}: ILeagueCodePayload) =>
		this._http.post<ILeagueResponse>(`tipping/league/${code}/join`);

	fetchLeagueUsers = ({leagueId, ...params}: ILeagueUsersPayload) =>
		this._http.get<ILeagueUserListResponse>(`tipping/league/${leagueId}/league-users`, params);

	fetchMyLeagues = (params: ILeaguesPayload) =>
		this._http.get<ILeaguesListResponse>("tipping/leagues", params);

	fetchLeagueByCode = ({code}: ILeagueCodePayload) =>
		this._http.get<ILeagueByCodeResponse>(`tipping/league/show-by-code/${code}`);

	fetchLeagueInvites = (params: IPaginationPayload) =>
		this._http.get<ILeaguesListResponse>("tipping/invites", params);

	acceptInvite = ({leagueId}: ILeagueIdPayload) =>
		this._http.get<void>(`tipping/league/${leagueId}/invite/accept`);

	declineInvite = ({leagueId}: ILeagueIdPayload) =>
		this._http.get<void>(`tipping/league/${leagueId}/invite/decline`);

	fetchRecoverLeagues = () =>
		this._http.get<IRecoverLeagueListResponse>("tipping/league/recover/list");

	recoverSkip = ({oldLeagueId}: IOldLeagueIdPayload) =>
		this._http.post<void>(`tipping/league/recover/skip/${oldLeagueId}`);

	recover = ({oldLeagueId}: IOldLeagueIdPayload) =>
		this._http.post<ILeagueResponse>(`tipping/league/recover/${oldLeagueId}`);
}
