import React from "react";
import {observer} from "mobx-react";
import {Button, Stack} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ReactComponent as ModalIcon} from "assets/img/icons/modalGreen.svg";
import {Exist} from "views/components/exist/exist.component";
import type {IModalConfirmController} from "views/components/modals/modal_confirm/modal_confirm.controller";
import {
	ModalBase,
	ModalDesc,
	ModalTitle,
} from "views/components/modals/modal_base/modal_base.component";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 18px;
	max-width: 380px;
	width: 100%;
`;

const SModalTitle = styled(ModalTitle)`
	margin-bottom: 6px;
`;

export const ModalConfirm: React.FC = observer(() => {
	const {isOpen, modalContent, i18n, close} = useViewController<IModalConfirmController>(
		Bindings.ModalConfirmController
	);

	return (
		<ModalBase isOpen={isOpen} closeHandler={close} className="confirm-modal">
			<Container>
				<ModalIcon />

				<div>
					<SModalTitle>
						{i18n.t(modalContent?.title ?? "modal.confirm.title", "Confirm")}
					</SModalTitle>

					<Exist when={modalContent?.message}>
						<ModalDesc>{modalContent?.message}</ModalDesc>
					</Exist>
				</div>

				<Stack direction="row" justifyContent="center" gap={3} width="100%">
					<Button fullWidth variant="outlined" color="secondary" onClick={close}>
						{i18n.t("modal.confirm.cancel_button", "Cancel")}
					</Button>
					<Exist when={modalContent?.callback}>
						<Button fullWidth variant="contained" onClick={modalContent?.callback}>
							{i18n.t("modal.confirm.confirm_button", "Confirm")}
						</Button>
					</Exist>
				</Stack>
			</Container>
		</ModalBase>
	);
});
