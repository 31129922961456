import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {get} from "lodash";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import type {
	IChanges,
	ITournamentStatsStore,
	IInAndOuts,
} from "data/stores/tournament_stats/tournament_stats.store";
import {type ISquadsStore} from "data/stores/squads/squads.store";
import {type ITournamentStore} from "data/stores/tournament/tournament.store";

interface IProps {
	tournamentId: number;
}

export interface IKeyChangesController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get changes(): {
		homeChanges: IChanges[];
		awayChanges: IChanges[];
	};
	get hasKeyChanges(): boolean;
}

@injectable()
export class KeyChangesController implements IKeyChangesController {
	@observable private _tournamentId: number = 0;

	private get stats() {
		return this._tournamentStatsStore.getTournamentStats(this._tournamentId);
	}

	get tournament() {
		return this._tournamentStore.getTournamentById(this._tournamentId);
	}

	get homeColor() {
		const id = get(this.tournament, "homeSquadId", 0);
		return this._squadsStore.getSquadById(id)?.color || "#80004d";
	}

	get awayColor() {
		const id = get(this.tournament, "awaySquadId", 0);
		return this._squadsStore.getSquadById(id)?.color || "#048245";
	}

	private getChanges(background: string, inAndOuts?: IInAndOuts) {
		const inPlayers = get(inAndOuts, "in", []);
		const outPlayers = get(inAndOuts, "out", []);

		return Array.from({length: Math.max(inPlayers.length, outPlayers.length)}).map((_, i) => ({
			add: inPlayers[i],
			out: outPlayers[i],
			background,
		}));
	}

	get changes() {
		const homeChanges = this.getChanges(this.homeColor, this.stats?.home.inAndOuts);
		const awayChanges = this.getChanges(this.awayColor, this.stats?.away?.inAndOuts);

		return {
			homeChanges,
			awayChanges,
		};
	}

	get hasKeyChanges() {
		return Boolean(this.changes.homeChanges.length || this.changes.awayChanges.length);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.TournamentStatsStore) private _tournamentStatsStore: ITournamentStatsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.TournamentStore) private _tournamentStore: ITournamentStore
	) {
		makeAutoObservable(this);
	}

	init(param: IProps) {
		this._tournamentId = param.tournamentId;
	}
}
