import {makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {AxiosError} from "axios";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {ModalType, RequestState, ShareType, SocialNetwork} from "data/enums";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {IModalTournamentData} from "data/types/game";
import {type ISportsbetStore, SportsbetType} from "data/stores/sportsbet/sportsbet.store";
import {type IShareApiProvider} from "data/providers/api/share.api.provider";
import {extractErrorMessage, share} from "data/utils";
import {IApiResponse} from "data/services/http";
import {type IUserStore} from "data/stores/user/user.store";
import {type ITournamentStore} from "data/stores/tournament/tournament.store";

interface IProps {
	isMobileShare: boolean;
}

export interface IModalPredictionsSavedController extends ViewController<IProps> {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get roundId(): number;
	get tournaments(): IModalTournamentData[];
	get isShowOdds(): boolean;
	get isLoadingDownloadImage(): boolean;
	get downloadImage(): string;
	get isLoadingEmail(): boolean;
	get isIdleEmail(): boolean;
	get isSentEmail(): boolean;
	get className(): string;
	get isMobileShare(): boolean;

	closeHandler: () => void;
	shareHandler: (socialNetwork: SocialNetwork) => void;
	emailHandler: () => void;
}

@injectable()
export class ModalPredictionsSavedController implements IModalPredictionsSavedController {
	@observable _emailShareRequestState = RequestState.IDLE;
	@observable _downloadImageRequestState = RequestState.IDLE;
	@observable _dynamicShareImageUrl = "";
	@observable private _disposer?: ReturnType<typeof reaction>;
	@observable private _timerId?: ReturnType<typeof setTimeout>;
	@observable private _isMobileShare?: boolean;

	get isOpen() {
		return this._modalsStore.modal === ModalType.PREDICTIONS_SAVED;
	}

	get roundId() {
		return this._roundsStore.selectedRound?.id || 1;
	}

	get tournaments() {
		const predictions = this._predictionsStore.predictions;
		const tournaments = this._roundsStore.selectedRound?.tournaments || [];

		return tournaments.map((tournament) => {
			const prediction = predictions.find(({tournamentId}) => tournamentId === tournament.id);
			const awayIconType = this._tournamentStore.getTournamentIconType(
				tournament,
				tournament.awaySquadId,
				prediction
			);
			const homeIconType = this._tournamentStore.getTournamentIconType(
				tournament,
				tournament.homeSquadId,
				prediction
			);

			return {
				...tournament,
				awayIconType,
				homeIconType,
				isSelectedHomeSquad: prediction?.squadId === tournament.homeSquadId,
				isSelectedAwaySquad: prediction?.squadId === tournament.awaySquadId,
			};
		});
	}

	get isShowOdds() {
		return this._sportsbetStore.getIsShow(SportsbetType.MultibetCalculator);
	}

	get isLoadingDownloadImage() {
		return this._downloadImageRequestState === RequestState.PENDING;
	}

	get downloadImage() {
		return this._dynamicShareImageUrl;
	}

	get isIdleEmail() {
		return this._emailShareRequestState === RequestState.IDLE;
	}

	get isLoadingEmail() {
		return this._emailShareRequestState === RequestState.PENDING;
	}

	get isSentEmail() {
		return this._emailShareRequestState === RequestState.SUCCESS;
	}

	get isMobileShare() {
		return Boolean(this._isMobileShare);
	}

	get className() {
		return this._isMobileShare ? "mobile-share" : "";
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.SportsbetStore) private _sportsbetStore: ISportsbetStore,
		@inject(Bindings.ShareApiProvider) private _shareApiProvider: IShareApiProvider,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.TournamentStore) private _tournamentStore: ITournamentStore
	) {
		makeAutoObservable(this);
	}

	private getDownloadImage = async () => {
		try {
			this._downloadImageRequestState = RequestState.PENDING;

			const {data} = await this._shareApiProvider.dynamicImage({roundId: this.roundId});

			const response = await fetch(data.success.image);
			const blob = await response.blob();
			const imageBase64 = URL.createObjectURL(blob);

			runInAction(() => {
				this._dynamicShareImageUrl = imageBase64;
				this._downloadImageRequestState = RequestState.SUCCESS;
			});
		} catch (error) {
			runInAction(() => {
				this._downloadImageRequestState = RequestState.ERROR;
			});

			this.onError(error as AxiosError<IApiResponse>);
		}
	};

	init(param: IProps) {
		this.onChange(param);

		this._disposer = reaction(
			() => {
				if (!this.isOpen) {
					return false;
				}

				return this.roundId;
			},
			(value) => {
				if (value) {
					void this.getDownloadImage();
				}
			}
		);
	}

	onChange(param: IProps) {
		this._isMobileShare = param.isMobileShare;
	}

	private onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	closeHandler = () => {
		this._modalsStore.hideModal();
	};

	shareHandler = (socialNetwork: SocialNetwork) => {
		share({
			type: ShareType.Tips,
			socialNetwork,
			roundId: this.roundId,
			userId: this._userStore.user!.id,
			message: this.i18n.t("share.title", "I’ve picked my tips for this round, have you?"),
		});
	};

	emailHandler = async () => {
		try {
			clearTimeout(this._timerId);
			this._emailShareRequestState = RequestState.PENDING;

			await this._shareApiProvider.email({roundId: this.roundId});

			runInAction(() => {
				this._emailShareRequestState = RequestState.SUCCESS;

				this._timerId = setTimeout(() => {
					runInAction(() => {
						this._emailShareRequestState = RequestState.IDLE;
					});
				}, 3000);
			});
		} catch (error) {
			runInAction(() => {
				this._emailShareRequestState = RequestState.IDLE;
			});

			this.onError(error as AxiosError<IApiResponse>);
		}
	};

	dispose() {
		this._disposer?.();
		clearTimeout(this._timerId);
	}
}
