import {action, makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore, ILeagueUsers} from "data/stores/leagues/leagues.store";
import {isEqual, partial} from "lodash";
import {LeagueStatus, ModalType, RequestState} from "data/enums";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";

interface IProps {
	leagueId: number;
}

export interface ILeagueInviteController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get league(): ILeague | null;
	get leagueUsers(): ILeagueUsers | null;
	get isLoading(): boolean;
	get isLeaguePresenceRequestLoading(): boolean;
	get isLeagueUsersRequestLoading(): boolean;
	get isLeagueStarted(): boolean;
	get userID(): number;
	get isCommissioner(): boolean;

	loadMoreUsers: () => void;
	removeUser: (userId: number) => void;
}

@injectable()
export class LeagueInviteController implements ILeagueInviteController {
	@observable private _leagueId?: number;

	@observable private _requestStates = {
		general: RequestState.PENDING,
		leaguePresence: RequestState.IDLE,
		leagueUsers: RequestState.IDLE,
		removeUser: RequestState.IDLE,
	};

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get leagueUsers() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueUsersByLeagueId(this._leagueId);
	}

	get isLoading() {
		return isEqual(this._requestStates.general, RequestState.PENDING);
	}

	get isLeaguePresenceRequestLoading() {
		return isEqual(this._requestStates.leaguePresence, RequestState.PENDING);
	}

	get isLeagueUsersRequestLoading() {
		return isEqual(this._requestStates.leagueUsers, RequestState.PENDING);
	}

	get isLeagueStarted() {
		return !isEqual(this.league?.status, LeagueStatus.SCHEDULED);
	}

	private get leagueID() {
		return this._leagueId!;
	}

	get userID() {
		return this._userStore.user!.id;
	}

	get isCommissioner() {
		return this.league?.leagueManager?.userId === this.userID;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	private setPendingState = (type: keyof typeof this._requestStates) => {
		this._requestStates[type] = RequestState.PENDING;
	};

	private createSuccessHandlerOf = (type: keyof typeof this._requestStates) => {
		this._requestStates[type] = RequestState.SUCCESS;
	};

	private createErrorHandlerOf = (
		type: keyof typeof this._requestStates,
		error: AxiosError<IApiResponse>
	) => {
		this._requestStates[type] = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action onChange({leagueId}: IProps) {
		this._leagueId = leagueId;
	}

	@action loadMoreUsers = () => {
		this.setPendingState("leagueUsers");

		this._leaguesStore
			.fetchMoreLeagueUsers({leagueId: this.leagueID})
			.then(() => this.createSuccessHandlerOf("leagueUsers"))
			.catch(partial(this.createErrorHandlerOf, "leagueUsers"));
	};

	@action removeUser = (userId: number) => {
		this._modalsStore.showModal(ModalType.CONFIRM, {
			message: this.i18n.t(
				"league_setting.manage_user.confirm_remove",
				"Do you really want to remove this user from the league?"
			),
			callback: () => {
				this._modalsStore.hideModal();
				this.setPendingState("removeUser");

				this._leaguesStore
					.removeUserFromLeague({userId, leagueId: this.leagueID})
					.then(() => this.createSuccessHandlerOf("removeUser"))
					.catch(partial(this.createErrorHandlerOf, "removeUser"));
			},
		});
	};

	@action init({leagueId}: IProps) {
		this._leagueId = leagueId;

		Promise.all([
			this._roundsStore.fetchRounds(),
			this._leaguesStore.fetchLeagueUsers({leagueId}),
		])
			.then(() => this.createSuccessHandlerOf("general"))
			.catch(partial(this.createErrorHandlerOf, "general"));
	}
}
