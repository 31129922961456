import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {SelectChangeEvent} from "@mui/material";
import {first} from "lodash";
import type {
	ILeagueRankingsPayload,
	IRankingApiProvider,
} from "data/providers/api/ranking.api.provider";
import {Bindings} from "data/constants/bindings";
import {IRankingPrediction} from "data/stores/rankings/rankings.store";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";

export interface ILeagueRanking {
	avatarVersion: number;
	userId: number;
	userName: string;
	firstName: string;
	lastName?: string;
	leagueId: number | null;
	rank: number | null;
	points: number | null;
	overallRank: number | null;
	overallPoints: number;
	overallMargin: number;
	margin: number;
	predictions?: IRankingPrediction[];
	lastRoundData?: {
		points: number;
		rank: number;
		margin: number;
		overallRank: number;
	};
	averagePoints: number | null;
}

export interface ILeagueRankingsStore {
	get list(): ILeagueRanking[];
	get user(): ILeagueRanking | null;
	get nextPage(): boolean;
	get isViewTips(): boolean;
	get selectedRound(): string;

	fetchLeagueRankings(params: ILeagueRankingsPayload): Promise<void>;
	loadMoreLeagueRankings(params: ILeagueRankingsPayload): Promise<void>;

	clearStore: () => void;
	onChangeRound: (e: SelectChangeEvent<unknown>) => void;
	updateViewTips: (isView: boolean) => void;
}

@injectable()
export class LeagueRankingsStore implements ILeagueRankingsStore {
	@observable private _list: ILeagueRanking[] = [];
	@observable private _user: ILeagueRanking | null = null;
	@observable private _nextPage: boolean = false;
	@observable private _isViewTips: boolean = false;
	@observable private _selectedRound: string = "all";
	private _page = 0;

	get list(): ILeagueRanking[] {
		return this._list;
	}

	get user(): ILeagueRanking | null {
		return this._user;
	}

	get nextPage(): boolean {
		return this._nextPage;
	}

	get isViewTips() {
		return this._isViewTips;
	}

	get selectedRound() {
		return this._selectedRound;
	}

	constructor(
		@inject(Bindings.RankingApiProvider) private _rankingsApi: IRankingApiProvider,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore
	) {
		makeAutoObservable(this);
	}

	@action async fetchLeagueRankings(params: ILeagueRankingsPayload): Promise<void> {
		this.clearRankings();
		const response = await this._rankingsApi.league(params);

		runInAction(() => {
			this._list = response.data.success.rankings;
			this._user = response.data.success.user;
			this._nextPage = response.data.success.nextPage;
			this._page = 1;
		});
	}

	@action async loadMoreLeagueRankings(params: ILeagueRankingsPayload): Promise<void> {
		const page = this._page + 1;

		const response = await this._rankingsApi.league({
			...params,
			page,
		});

		runInAction(() => {
			this._list = this._list.concat(response.data.success.rankings);
			this._user = response.data.success.user;
			this._nextPage = response.data.success.nextPage;
			this._page = page;
		});
	}

	updateViewTips = (isView: boolean) => {
		this._isViewTips = isView;

		if (isView) {
			const roundId = first(this._roundsStore.viewTipsRounds)?.id.toString();

			if (roundId) {
				this._selectedRound = roundId;
			}
		}
	};

	onChangeRound = (e: SelectChangeEvent<unknown>) => {
		this._selectedRound = e.target.value as string;
	};

	private clearRankings = () => {
		this._list = [];
		this._user = null;
		this._nextPage = false;
		this._page = 0;
	};

	clearStore = () => {
		this.clearRankings();
		this._isViewTips = false;
		this._selectedRound = "all";
	};
}
