import React, {FC, PropsWithChildren} from "react";
import styled from "@emotion/styled";
import {IconButton, Modal} from "@mui/material";
import {ReactComponent as CloseIcon} from "assets/img/icons/close.svg";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	outline: none;
	max-width: 600px;
	width: 100%;
	background: ${({theme}) => theme.palette.modal.bg};
	border-radius: 4px;
	text-align: center;
	padding: 66px 24px 24px;

	@media screen and (max-width: 640px) {
		padding: 40px 16px 16px;
		max-width: 100%;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
	}

	&.error-modal,
	&.confirm-modal {
		padding: 48px 24px 44px;

		@media screen and (max-width: 640px) {
			padding: 40px 16px 16px;
		}
	}

	&.small-mb-pd {
		@media (max-height: 650px) {
			padding: 16px;
		}
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: absolute;
	top: 8px;
	right: 8px;

	@media (min-width: 641px) {
		top: 16px;
		right: 16px;
	}
`;

export const ModalTitle = styled.h2`
	color: ${({theme}) => theme.palette.modal.color};
	text-align: center;
	font-size: 16px;
	font-weight: 800;
	line-height: 16px;
`;

export const ModalDesc = styled.h3`
	color: ${({theme}) => theme.palette.modal.color};
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
`;

interface IModalBase {
	isOpen: boolean;
	closeHandler?: () => void;
	className?: string;
	disableEnforceFocus?: boolean;
}

export const ModalBase: FC<PropsWithChildren<IModalBase>> = ({
	children,
	closeHandler,
	isOpen,
	className,
	disableEnforceFocus = false,
}) => {
	return (
		<Modal open={isOpen} onClose={closeHandler} disableEnforceFocus={disableEnforceFocus}>
			<ModalContent className={className}>
				{closeHandler && (
					<CloseBlock>
						<IconButton onClick={closeHandler}>
							<CloseIcon />
						</IconButton>
					</CloseBlock>
				)}

				{children}
			</ModalContent>
		</Modal>
	);
};
