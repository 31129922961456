import {isEqual} from "lodash";
import type {AxiosError} from "axios";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type ISquadsStore} from "data/stores/squads/squads.store";
import {type IUserStore} from "data/stores/user/user.store";
import {type IStreakGameBarStore} from "data/stores/streak_game_bar/streak_game_bar.store";
import {type ITutorialStore} from "data/stores/tutorial/tutorial.store";
import {type ITournamentStatsStore} from "data/stores/tournament_stats/tournament_stats.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IStreakPredictionsStore} from "data/stores/streak_predictions/streak_predictions.store";
import {STREAK_TUTORIAL_STORAGE_KEY} from "data/constants/game";

export interface IStreakController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isLoading(): boolean;
	get isPredictionLoading(): boolean;
}

@injectable()
export class StreakController implements IStreakController {
	@observable _requestState = RequestState.PENDING;
	@observable _predictionRequestState = RequestState.IDLE;
	@observable _fetchPredictionDisposer?: ReturnType<typeof reaction>;

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isPredictionLoading() {
		return isEqual(this._predictionRequestState, RequestState.PENDING);
	}

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.StreakPredictionsStore)
		private _streakPredictionsStore: IStreakPredictionsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.StreakGameBarStore) private _streakGameBarStore: IStreakGameBarStore,
		@inject(Bindings.TutorialStore) private _tutorialStore: ITutorialStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.TournamentStatsStore) private _tournamentStatsStore: ITournamentStatsStore
	) {
		makeAutoObservable(this);
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private showErrorModal = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this.showErrorModal(error);
	};

	private showTutorialModal = () => {
		const userId = this._userStore.user?.id || "";

		const isUserViewedTeamTutorial = JSON.parse(
			localStorage.getItem(`${STREAK_TUTORIAL_STORAGE_KEY}-${userId}`) || "false"
		) as boolean;

		if (isUserViewedTeamTutorial) return;

		this._tutorialStore.setIsOpen(true);
		localStorage.setItem(`${STREAK_TUTORIAL_STORAGE_KEY}-${userId}`, JSON.stringify(true));
	};

	@action
	async init() {
		try {
			await Promise.all([this._squadsStore.fetchSquads(), this._roundsStore.fetchRounds()]);

			this.onSuccess();

			this._fetchPredictionDisposer = reaction(
				() => this._roundsStore.selectedRound?.id,
				(roundId) => {
					if (!roundId) return;

					// Scroll to top of page on round change
					window.scrollTo(0, 0);

					this._streakPredictionsStore.clearStore();
					this._predictionRequestState = RequestState.PENDING;

					this._streakGameBarStore.fetchGameBar().catch((error) => {
						this.showErrorModal(error as AxiosError<IApiResponse>);
					});

					Promise.all([
						this._roundsStore.fetchRounds(),
						this._streakPredictionsStore.fetchPredictions(roundId),
					])
						.then(([_, isSuccess]) => {
							if (isSuccess) {
								runInAction(() => {
									this._predictionRequestState = RequestState.SUCCESS;
								});
							}
						})
						.catch((error) => {
							this._predictionRequestState = RequestState.ERROR;
							this.showErrorModal(error as AxiosError<IApiResponse>);
						});
				},
				{fireImmediately: true}
			);

			this.showTutorialModal();
		} catch (err) {
			this.onError(err as AxiosError<IApiResponse>);
		}
	}

	dispose() {
		this._fetchPredictionDisposer?.();
		this._streakPredictionsStore.clearStore();
		this._streakGameBarStore.clearStore();
		this._tournamentStatsStore.clearStore();
		this._roundsStore.clearStore();
	}
}
