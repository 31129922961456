import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IStreakGameBarStore} from "data/stores/streak_game_bar/streak_game_bar.store";

export interface IStreakGameBarController extends ViewController {
	readonly i18n: ILocalizationStore;

	get currentStreak(): number;
	get longestStreak(): number;
	get leaderStreak(): number;
}

@injectable()
export class StreakGameBarController implements IStreakGameBarController {
	get currentStreak() {
		return this._streakGameBarStore.gameBar.currentStreak;
	}

	get longestStreak() {
		return this._streakGameBarStore.gameBar.longestStreak;
	}

	get leaderStreak() {
		return this._streakGameBarStore.gameBar.leaderStreak;
	}

	constructor(
		@inject(Bindings.StreakGameBarStore) private _streakGameBarStore: IStreakGameBarStore,
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}
}
