import {action, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {MatchStatus, ModalType, OrderField, RequestState, SortOrder} from "data/enums";
import {extractErrorMessage} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IRoundsStore, ITournament} from "data/stores/rounds/rounds.store";
import {identity, isEqual, pickBy} from "lodash";
import {SelectChangeEvent} from "@mui/material";
import type {
	ILeagueRanking,
	ILeagueRankingsStore,
} from "data/stores/league_rankings/league_rankings.store";
import {type IUserStore} from "data/stores/user/user.store";
import {type ILeagueRankingsPayload} from "data/providers/api/ranking.api.provider";
import {type ISortData} from "data/types/game";

interface IProps {
	leagueId: number;
}

export interface ILeagueTableController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get league(): ILeague | null;
	get rankings(): ILeagueRanking[];
	get selectedRound(): string;
	get isLoading(): boolean;
	get nextPage(): boolean;
	get isShowRankStatus(): boolean;
	get isOverall(): boolean;
	get isShowStickyUserRanking(): boolean;
	get userRanking(): ILeagueRanking | null;
	get isViewTips(): boolean;
	get className(): string;
	get predictionsLength(): number;
	get tournaments(): ITournament[];

	onChangeRound: (e: SelectChangeEvent<unknown>) => void;
	loadMore: () => void;
	changeOrder: (field: OrderField) => void;
	getHeadCN: (filed: OrderField, cn?: string) => string;
}

@injectable()
export class LeagueTableController implements ILeagueTableController {
	@observable private _leagueId?: number;
	@observable private _requestState = RequestState.PENDING;
	@observable private _fetchRankingsDisposer?: ReturnType<typeof reaction>;
	@observable _sortData: ISortData = {
		field: OrderField.RANK,
		order: SortOrder.ASC,
	};

	private get leagueID() {
		return this._leagueId!;
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get rankings() {
		return this._leagueRankingsStore.list;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get selectedRound() {
		return this._leagueRankingsStore.selectedRound;
	}

	get nextPage() {
		return this._leagueRankingsStore.nextPage;
	}

	get isShowRankStatus() {
		return this._roundsStore.actualRound?.id !== 1;
	}

	get isOverall() {
		return this.selectedRound === "all";
	}

	get userRanking() {
		return this._leagueRankingsStore.user;
	}

	get isShowStickyUserRanking() {
		const rankingsListHasUser = this.rankings.some(
			({userId}) => userId === this._userStore.user?.id
		);

		return Boolean(this.userRanking && !rankingsListHasUser);
	}

	private get params(): ILeagueRankingsPayload {
		const roundId = this.isOverall ? undefined : +this.selectedRound;

		return pickBy(
			{
				roundId,
				leagueId: this._leagueId!,
				sortBy: this._sortData.field,
				sortOrder: this._sortData.order,
				showPredictions: Number(this.isViewTips),
			},
			identity
		) as unknown as ILeagueRankingsPayload;
	}

	get isViewTips() {
		return this._leagueRankingsStore.isViewTips;
	}

	get className() {
		return this.isViewTips ? "view-tips" : "";
	}

	get tournaments() {
		return (
			this._roundsStore
				.getRoundById(Number(this.selectedRound))
				?.tournaments.filter(({status}) => status === MatchStatus.Complete) || []
		);
	}

	get predictionsLength() {
		return this.tournaments.length;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.LeagueRankingsStore) private _leagueRankingsStore: ILeagueRankingsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action onChangeRound = (e: SelectChangeEvent<unknown>) => {
		this._leagueRankingsStore.onChangeRound(e);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action init({leagueId}: IProps) {
		this._leagueId = leagueId;

		Promise.all([this._roundsStore.fetchRounds()]).catch(this.onError);

		this._fetchRankingsDisposer = reaction(
			() => [this.selectedRound, this.isViewTips],
			() => {
				this._requestState = RequestState.PENDING;

				void this._leagueRankingsStore
					.fetchLeagueRankings(this.params)
					.then(this.onSuccess)
					.catch(this.onError);
			},
			{fireImmediately: true}
		);
	}

	@action loadMore = () => {
		this._requestState = RequestState.PENDING;

		this._leagueRankingsStore
			.loadMoreLeagueRankings(this.params)
			.then(this.onSuccess)
			.catch(this.onError);
	};

	changeOrder = async (field: OrderField) => {
		if (this._sortData.field === field) {
			this._sortData.order =
				this._sortData.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
		} else {
			this._sortData = {
				order: SortOrder.ASC,
				field,
			};
		}

		try {
			this._requestState = RequestState.PENDING;
			await this._leagueRankingsStore.fetchLeagueRankings(this.params);

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (error) {
			this.onError(error as AxiosError<IApiResponse>);
		}
	};

	getHeadCN = (filed: OrderField, cn: string = "") => {
		if (this._sortData.field === filed) {
			return `with-sort ${this._sortData.order} ${cn}`;
		}

		return `with-sort ${cn}`;
	};

	dispose() {
		this._fetchRankingsDisposer?.();
		this._leagueRankingsStore.clearStore();
	}
}
