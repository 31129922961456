import type {AxiosResponse} from "axios";
import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {Bindings} from "data/constants/bindings";
import {IStreakPrediction} from "data/stores/streak_predictions/streak_predictions.store";

export interface IPredictionResponse {
	predictions: IStreakPrediction[];
}

export interface IGetPredictionPayload {
	roundId: number;
	signal?: AbortSignal;
}

export interface ISavePredictionPayload {
	predictions: Omit<IStreakPrediction, "id" | "isCorrect">[];
	roundId: number;
}

type TPredictionResponse = IApiResponse<IPredictionResponse>;

export interface IStreakPredictionApiProvider {
	get: (payload: IGetPredictionPayload) => Promise<AxiosResponse<TPredictionResponse>>;
	save: (payload: ISavePredictionPayload) => Promise<AxiosResponse<TPredictionResponse>>;
}

@injectable()
export class StreakPredictionApiProvider implements IStreakPredictionApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	get = ({signal, roundId}: IGetPredictionPayload) =>
		this._http.get<TPredictionResponse>(
			`tipping/streak/predictions/list/${roundId}`,
			undefined,
			{
				signal,
			}
		);

	save = (payload: ISavePredictionPayload) =>
		this._http.post<TPredictionResponse>(`tipping/streak/predictions/save`, payload);
}
