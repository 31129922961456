import {MouseEvent} from "react";
import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ILocalizationStore} from "data/stores/localization/localization.store";

export interface IBurgerNavLinkController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isOpen(): boolean;
	toggleClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

@injectable()
export class BurgerNavLinkController implements IBurgerNavLinkController {
	@observable _isOpen: boolean = false;

	get isOpen() {
		return this._isOpen;
	}

	constructor(@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	toggleClick = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		e.stopPropagation();
		this._isOpen = !this._isOpen;
	};
}
