import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {Bindings} from "data/constants/bindings";
import {SquadMatchResult, SquadType} from "data/enums";

export interface ISquad {
	id: number;
	name: string;
	abbreviation: string;
	color: string;
	type: SquadType;
	ladder: {
		position: number | null;
		gamesPlayed: number;
		wins: number;
		losses: number;
		draws: number;
		pointsDifference: number;
		competitionPoints: number;
		recentFormList: SquadMatchResult[];
	};
}

export interface ISquadsStore {
	get list(): ISquad[];
	get regList(): ISquad[];

	getSquadById(squadId?: number | null): ISquad | undefined;
	fetchSquads(): Promise<void>;
	/**
	 * This method will allow you to fetch a squads only when they have not yet been resolved
	 */
	safeFetchSquads(): Promise<void>;
}

@injectable()
export class SquadsStore implements ISquadsStore {
	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	@observable private _list: ISquad[] = [];

	get list() {
		return this._list;
	}

	get regList() {
		return this._list.filter(({type}) => type === SquadType.Reg);
	}

	getSquadById(squadId?: number | null): ISquad | undefined {
		return this._list.find((squad) => squad.id === squadId);
	}

	@action
	async fetchSquads() {
		const {data} = await this._jsonProvider.squads();

		runInAction(() => {
			this._list = data;
		});
	}

	async safeFetchSquads() {
		if (!this._list.length) {
			return this.fetchSquads();
		}
	}
}
