import {first} from "lodash";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {Bindings} from "data/constants/bindings";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IBannerStore} from "data/stores/banner/banner.store";
import {BannerType} from "data/enums";
import type {IWebViewGateStore} from "data/stores/web_view_gate/web_view_gate.store";

export interface ITippingBannerController extends ViewController {
	readonly i18n: ILocalizationStore;

	get bannerType(): BannerType | null;
	get selectedRoundId(): number;
	get selectedRoundStartDate(): string;
	get nextRoundId(): number;
	get nextRoundStartDate(): string;
	get predictionsCount(): number;
	get totalPredictionsCount(): number;
	get isFromApp(): boolean;

	onComplete: () => void;
}

@injectable()
export class TippingBannerController implements ITippingBannerController {
	get bannerType() {
		return this._bannerStore.tippingBannerType;
	}

	get selectedRoundId() {
		return this._roundsStore.selectedRound?.id || 1;
	}

	get selectedRoundStartDate() {
		return (
			first(this._roundsStore.selectedRound?.tournaments)?.date || new Date().toISOString()
		);
	}

	get predictionsCount() {
		return this._predictionsStore.predictions.length;
	}

	get totalPredictionsCount() {
		return this._roundsStore.selectedRound?.tournaments.length || 0;
	}

	get nextRoundStartDate(): string {
		const firstScheduledRound = first(this._roundsStore.scheduleRounds);
		return first(firstScheduledRound?.tournaments)?.date || new Date().toISOString();
	}

	get nextRoundId() {
		return first(this._roundsStore.scheduleRounds)?.id || 1;
	}

	get isFromApp() {
		return this._webViewGateStore.isFromApp;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.BannerStore) private _bannerStore: IBannerStore,
		@inject(Bindings.WebViewGateStore) private _webViewGateStore: IWebViewGateStore
	) {
		makeAutoObservable(this);
	}

	onComplete = () => {
		this._bannerStore.onComplete();
	};

	dispose() {
		this._bannerStore.clearStore();
	}
}
