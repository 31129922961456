import React, {FC, Fragment} from "react";
import styled from "@emotion/styled";
import {SportbetCurrency, SportsbetMultiH2HBTYB, SportsbetMultiH2HOdds} from "data/constants";
import {ReactComponent as BlueSportsBetLogo} from "assets/img/blueSportsBetLogo.svg";

const Container = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	background: ${({theme}) => theme.palette.tipsCard.oddsBg};
	color: ${({theme}) => theme.palette.tipsCard.oddsColor};
	padding: 10px;
	font-size: 14px;
	font-weight: 500;
	line-height: 12px;
	letter-spacing: 0.2px;
	max-width: 143px;
	width: 100%;

	&:last-child {
		justify-self: flex-end;
	}
`;

Container.defaultProps = {
	target: "_blank",
	rel: "noopener noreferrer",
};

const SponsorLogo = styled.a`
	display: flex;
	justify-content: center;

	svg {
		width: 95px;

		@media (min-width: 769px) {
			width: 102px;
		}
	}
`;

interface IOdds {
	homeOdds?: number;
	awayOdds?: number;
}

export const Odds: FC<IOdds> = ({homeOdds = 0, awayOdds = 0}) => {
	return (
		<Fragment>
			<Container href={SportsbetMultiH2HOdds}>
				{SportbetCurrency}
				{homeOdds.toFixed(2)}
			</Container>

			<SponsorLogo href={SportsbetMultiH2HBTYB} target="_blank">
				<BlueSportsBetLogo />
			</SponsorLogo>

			<Container href={SportsbetMultiH2HOdds}>
				{SportbetCurrency}
				{awayOdds.toFixed(2)}
			</Container>
		</Fragment>
	);
};
