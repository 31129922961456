import type {AxiosResponse} from "axios";
import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {Bindings} from "data/constants/bindings";

export interface IUsername {
	displayName: string;
}

export type TUserResponse = IApiResponse<{user: IUser}>;

export interface IUserApiProvider {
	update: (params: FormData) => Promise<AxiosResponse<TUserResponse>>;
	user: () => Promise<AxiosResponse<TUserResponse>>;
	checkUsername: (params: IUsername) => Promise<AxiosResponse>;
	deactivateAccount: () => Promise<AxiosResponse>;
}

@injectable()
export class UserApiProvider implements IUserApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	checkUsername = (params: IUsername) => this._http.post<void>("user/check-display-name", params);

	deactivateAccount = () => this._http.post<void>("user/deactivate_account");

	update = (params: FormData) => this._http.post<TUserResponse>("user/update", params);

	user = () => this._http.get<TUserResponse>("user");
}
