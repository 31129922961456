import {AxiosError} from "axios";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type IStreakPredictionApiProvider} from "data/providers/api/streak_prediction.api.provider";

export interface IStreakPrediction {
	id: number | null; // Can be null before saving prediction
	squadId: number;
	tournamentId: number;
	isCorrect: boolean | null;
}

export interface IStreakPredictionsStore {
	get predictions(): IStreakPrediction[];
	get hasPredictions(): boolean;
	get hasAllPredictions(): boolean;

	fetchPredictions: (roundId?: number) => Promise<boolean>;
	getPredictionByTournamentId: (tournamentId: number) => IStreakPrediction | undefined;
	setPredictionSquad: (tournamentId: number, squadId: number) => Promise<void>;
	removePrediction: (tournamentId: number) => Promise<void>;
	clearStore: () => void;
}

@injectable()
export class StreakPredictionsStore implements IStreakPredictionsStore {
	@observable private _abortController?: AbortController;
	@observable private _predictions: IStreakPrediction[] = [];

	get predictions() {
		return this._predictions;
	}

	get hasPredictions() {
		return Boolean(this.predictions.length);
	}

	get hasAllPredictions() {
		const tournaments = this._roundsStore.selectedRound?.tournaments || [];
		return tournaments.every(({id}) => this.getPredictionByTournamentId(id)?.squadId);
	}

	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.StreakPredictionApiProvider)
		private _predictionApi: IStreakPredictionApiProvider
	) {
		makeAutoObservable(this);
	}

	fetchPredictions = async (roundId: number = 1) => {
		try {
			if (this._abortController) {
				this._abortController.abort();
			}

			this._abortController = new AbortController();

			const {data} = await this._predictionApi.get({
				roundId,
				signal: this._abortController.signal,
			});

			runInAction(() => {
				this._predictions = data.success.predictions;
			});

			return true;
		} catch (e) {
			if ((e as AxiosError).name !== "CanceledError") {
				throw e;
			}

			return false;
		}
	};

	getPredictionByTournamentId = (tournamentId: number) => {
		return this._predictions.find((prediction) => prediction.tournamentId === tournamentId);
	};

	private addPrediction = (prediction: IStreakPrediction) => {
		this._predictions = [...this._predictions, prediction];
	};

	private get predictionsForSave() {
		return this._predictions.map(({squadId, tournamentId}) => ({
			squadId,
			tournamentId,
		}));
	}

	private savePredictions = async () => {
		const roundId = this._roundsStore.selectedRound?.id;

		if (roundId) {
			await this._predictionApi.save({predictions: this.predictionsForSave, roundId});
		}
	};

	setPredictionSquad = async (tournamentId: number, squadId: number) => {
		const prediction = this.getPredictionByTournamentId(tournamentId);

		if (!prediction) {
			this.addPrediction({
				id: null,
				tournamentId,
				squadId,
				isCorrect: null,
			});
		} else {
			prediction.squadId = squadId;
		}

		await this.savePredictions();
	};

	removePrediction = async (tournamentId: number) => {
		this._predictions = this._predictions.filter(
			(prediction) => prediction.tournamentId !== tournamentId
		);

		await this.savePredictions();
	};

	clearStore = () => {
		this._predictions = [];
	};
}
