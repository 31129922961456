import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {AxiosError} from "axios";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import {type ISportsbetStore} from "data/stores/sportsbet/sportsbet.store";
import {ModalType, RequestState} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {IApiResponse} from "data/services/http";
import {type IModalsStore} from "data/stores/modals/modals.store";

export interface ISportbetLayoutController extends ViewController {
	get isLoading(): boolean;
}

@injectable()
export class SportbetLayoutController implements ISportbetLayoutController {
	@observable private _requestState = RequestState.PENDING;

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	constructor(
		@inject(Bindings.SportsbetStore) private _sportsbetStore: ISportsbetStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	async init() {
		try {
			this._requestState = RequestState.PENDING;

			await this._sportsbetStore.fetch();

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (error) {
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});

			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error as AxiosError<IApiResponse>),
			});
		}
	}
}
