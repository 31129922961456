import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {AxiosError, AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import type {IAuthApiProvider} from "data/providers/api/auth.api.provider";
import type {IUserApiProvider} from "data/providers/api/user.api.provider";
import type {
	IForgotPasswordPayload,
	IPasswordApiProvider,
	IResetPasswordPayload,
} from "data/providers/api/password.api.provider";
import type {
	ILoginBackdoorPayload,
	ILoginPayload,
	INrlIdApiProvider,
	IRegisterBackdoorPayload,
} from "data/providers/api/nrl_id.api.provider";
import {Gender, ModalType} from "data/enums";
import Cookies from "js-cookie";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import {IApiResponse} from "data/services/http";

export interface IUser {
	id: number;
	email: string;
	isNotificationsEnabled: boolean;
	isPushEnabled: boolean;
	firstName: string;
	lastName: string;
	displayName: string;
	avatarVersion: number;
	supportedSquadId: number | null;
	recovered: boolean;
	isBettingVisible: boolean;
	birthday: string;
	gender: Gender;
}

export interface IOldUser {
	displayName: string;
	country: string;
	state: string;
	id: number;
	avatarVersion: number;
	gender: string | null;
}

export interface INRLIdProfile {
	id: number;
	email: string;
	firstName: string;
	lastName: string;
	birthday: string | null;
	supportedClub: number | null;
	gender: string | null;
}

export interface ICheckSID {
	nrlSessionId: string;
}

export interface IUserStore {
	get user(): IUser | null;
	get oldUser(): IOldUser | null;
	get nrlIdProfile(): INRLIdProfile | null;
	get isAuthorized(): boolean;
	get wasLoggedOut(): boolean;
	get appSid(): string | null;
	get isSIDValid(): boolean;

	forgotPassword(payload: IForgotPasswordPayload): Promise<AxiosResponse<void>>;
	resetPassword(payload: IResetPasswordPayload): Promise<AxiosResponse<void>>;
	register(payload: FormData): Promise<void>;
	update(payload: FormData): Promise<void>;
	deactivate(): Promise<void>;
	login(payload: ILoginPayload): Promise<void>;
	checkSID(payload: ICheckSID): Promise<void>;
	loginBackdoor(payload: ILoginBackdoorPayload): Promise<void>;
	registerBackdoor(payload: IRegisterBackdoorPayload): Promise<void>;
	logout(): Promise<void>;
	requestUser(): Promise<void>;
	setAppSid(sid: string): void;
}

@injectable()
export class UserStore implements IUserStore {
	@observable private _user: IUser | null = null;
	@observable private _oldUser: IOldUser | null = null;
	@observable private _nrlIdProfile: INRLIdProfile | null = null;
	@observable private _wasLoggedOut = false;
	@observable private _appSid: string | null = null;
	@observable private _isSIDValid = true;

	constructor(
		@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider,
		@inject(Bindings.UserApiProvider) private _userApi: IUserApiProvider,
		@inject(Bindings.PasswordApiProvider) private _passwordApi: IPasswordApiProvider,
		@inject(Bindings.NrlIdApiProvider) private _nrlIdApi: INrlIdApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get isAuthorized() {
		return Boolean(this.user);
	}

	get wasLoggedOut() {
		return this._wasLoggedOut;
	}

	get user() {
		return this._user;
	}

	get oldUser() {
		return this._oldUser;
	}

	get nrlIdProfile() {
		return this._nrlIdProfile;
	}

	get appSid() {
		return this._appSid;
	}

	get isSIDValid() {
		return this._isSIDValid;
	}

	setAppSid(sid: string) {
		this._appSid = sid;
	}

	@action
	async requestUser() {
		const response = await this._userApi.user();
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async login(payload: ILoginPayload) {
		const response = await this._nrlIdApi.login(payload);
		const {user, oldUser, nrlIdProfile} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._oldUser = oldUser;
			this._nrlIdProfile = nrlIdProfile;
			this._wasLoggedOut = false;
		});
	}

	@action
	async checkSID(payload: ICheckSID) {
		const response = await this._nrlIdApi.checkSID(payload);
		const {message} = response.data.errors[0];

		runInAction(() => {
			this._isSIDValid = !message;
		});
	}

	@action
	async loginBackdoor(payload: ILoginBackdoorPayload) {
		const response = await this._authApi.login(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async register(payload: FormData) {
		try {
			const response = this._appSid
				? await this._nrlIdApi.registerApp(payload)
				: await this._nrlIdApi.register(payload);
			const {user} = response.data.success;

			runInAction(() => {
				this._user = user;
				if (user) {
					this._wasLoggedOut = false;

					// Clean up data
					this._appSid = null;
					Cookies.remove("NRLSID");
				}
			});
		} catch (error) {
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error as AxiosError<IApiResponse>),
			});
		}
	}

	@action
	async registerBackdoor(payload: IRegisterBackdoorPayload) {
		const response = await this._authApi.register(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._wasLoggedOut = false;
		});
	}

	@action
	async update(payload: FormData) {
		const response = await this._userApi.update(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
		});
	}

	@action
	async logout() {
		await this._authApi.logout();

		runInAction(() => {
			this._user = null;
			this._wasLoggedOut = true;
		});
	}

	@action
	async deactivate() {
		await this._userApi.deactivateAccount();

		runInAction(() => {
			this._user = null;
			this._wasLoggedOut = true;
		});
	}

	forgotPassword(payload: IForgotPasswordPayload) {
		return this._passwordApi.forgotPassword(payload);
	}

	resetPassword(payload: IResetPasswordPayload) {
		return this._passwordApi.resetPassword(payload);
	}
}
