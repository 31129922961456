import {AxiosResponse} from "axios";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {IApiResponse, type IHttpClientService} from "data/services/http";
import {IStreakGameBar} from "data/stores/streak_game_bar/streak_game_bar.store";
import {IStreakRanking} from "data/stores/streak_rankings/streak_rankings.store";

export interface IStreakRankingsPayload {
	page?: number;
	limit?: number;
}

export interface IStreakRankingsResponse {
	rankings: IStreakRanking[];
	user: IStreakRanking | null;
	nextPage: boolean;
}

export type TStreakGameBarResponse = IApiResponse<IStreakGameBar>;
export type TRankingsResponse = IApiResponse<IStreakRankingsResponse>;

export interface IStreakRankingApiProvider {
	gameBar: () => Promise<AxiosResponse<TStreakGameBarResponse>>;
	ranking: (params: IStreakRankingsPayload) => Promise<AxiosResponse<TRankingsResponse>>;
}

@injectable()
export class StreakRankingApiProvider implements IStreakRankingApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	gameBar = () => {
		return this._http.get<TStreakGameBarResponse>(`tipping/streak/ranking/gamebar`);
	};

	ranking = (params: IStreakRankingsPayload) =>
		this._http.get<TRankingsResponse>("tipping/streak/ranking", params);
}
