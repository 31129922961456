import {injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";

export interface ITutorialStore extends ViewController {
	get isOpen(): boolean;
	get activeStep(): number;

	setIsOpen: (isOpen: boolean) => void;
	setActiveStep: (step: number) => void;
	clearStore: () => void;
}

@injectable()
export class TutorialStore implements ITutorialStore {
	@observable private _isOpen: boolean = false;
	@observable private _activeStep: number = 0;

	constructor() {
		makeAutoObservable(this);
	}

	get isOpen() {
		return this._isOpen;
	}

	get activeStep() {
		return this._activeStep;
	}

	setIsOpen = (isOpen: boolean) => {
		this._isOpen = isOpen;

		if (!isOpen) {
			this._activeStep = 0;
		}
	};

	setActiveStep = (step: number) => {
		this._activeStep = step;
	};

	clearStore = () => {
		this._isOpen = false;
		this._activeStep = 0;
	};
}
