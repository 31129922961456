import {action, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {LeagueStatus, ModalType, RequestState} from "data/enums";
import {isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";

interface IProps {
	leagueId: number;
}

export interface ILeagueAboutController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get league(): ILeague | null;
	get isLoading(): boolean;
	get isLeagueStarted(): boolean;
	get isShowLeaveLeague(): boolean;

	leaveLeague: () => void;
	joinLeague: () => void;
}

@injectable()
export class LeagueAboutController implements ILeagueAboutController {
	@observable private _leagueId?: number;
	@observable private _leagueDisposer?: ReturnType<typeof reaction>;
	@observable private _requestState = RequestState.IDLE;

	private get leagueID() {
		return this._leagueId!;
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isLeagueStarted() {
		return !isEqual(this.league?.status, LeagueStatus.SCHEDULED);
	}

	get isShowLeaveLeague() {
		return Boolean(this.league?.isJoined && this.league.code);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action leaveLeague = () => {
		this._modalsStore.showModal(ModalType.CONFIRM, {
			title: this.i18n.t("league.leave.confirm_title", "Are you sure?"),
			message: this.i18n.t(
				"league.leave.confirm_description",
				"You are about to leave {{name}}. This action cannot be reversed.",
				{name: this.league?.name}
			),
			callback: () => {
				runInAction(() => {
					this._modalsStore.hideModal();
					this._requestState = RequestState.PENDING;

					this._leaguesStore
						.leaveLeague({leagueId: this.leagueID})
						.then(this.onSuccess)
						.catch(this.onError);
				});
			},
		});
	};

	@action joinLeague = () => {
		const code = this.league?.code;

		if (!code) return;

		this._requestState = RequestState.PENDING;

		this._leaguesStore.joinToLeague({code}).then(this.onSuccess).catch(this.onError);
	};

	@action init({leagueId}: IProps) {
		this._leagueId = leagueId;
	}
}
