import React from "react";
import {Button} from "@mui/material";
import styled from "@emotion/styled";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {
	ModalBase,
	ModalDesc,
	ModalTitle,
} from "views/components/modals/modal_base/modal_base.component";
import {ReactComponent as ModalIcon} from "assets/img/icons/modalGreen.svg";
import {IModalInviteViaEmailController} from "./modal_invite_via_email.controller";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 18px;
	max-width: 380px;
	width: 100%;
`;

const SModalTitle = styled(ModalTitle)`
	margin-bottom: 6px;
`;

export const ModalInviteViaEmail: React.FC = observer(() => {
	const {isOpen, i18n, close} = useViewController<IModalInviteViaEmailController>(
		Bindings.ModalInviteViaEmailController
	);

	return (
		<ModalBase isOpen={isOpen} closeHandler={close}>
			<Container>
				<ModalIcon />

				<div>
					<SModalTitle>{i18n.t("modal.invite_via_email.title", "Sent")}</SModalTitle>

					<ModalDesc>
						{i18n.t("modal.invite_via_email.desc", "Invitation sent successfully")}
					</ModalDesc>
				</div>

				<Button color="secondary" variant="outlined" onClick={close}>
					{i18n.t("modal.invite_via_email.button", "Close")}
				</Button>
			</Container>
		</ModalBase>
	);
});
