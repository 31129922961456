import React from "react";
import {Outlet} from "react-router-dom";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ISportbetLayoutController} from "./sportbet_layout.controller";
import {PagePreloader} from "views/components/preloader";

export const SportbetLayout: React.FC = observer(() => {
	const {isLoading} = useViewController<ISportbetLayoutController>(
		Bindings.SportbetLayoutController
	);

	if (isLoading) {
		return <PagePreloader />;
	}

	return <Outlet />;
});
