import {IMAGES_URL} from "data/constants";

export const getUserAvatarUrl = (userId: number, avatarVersion: number) =>
	`${IMAGES_URL}avatars/user/${userId}_${avatarVersion}.png`;

export const getSquadAvatarUrl = (squadId: number) => `${IMAGES_URL}squads/${squadId}.png`;

export const getLeagueAvatarUrl = (leagueId: number, avatarVersion: number) =>
	`${IMAGES_URL}avatars/league/${leagueId}_${avatarVersion}.png`;

export const getPlayerAvatarUrl = (
	playerId: number,
	size: 100 | 450 = 450,
	format: "webp" | "png" = "webp"
) => `${IMAGES_URL}players/${playerId}_${size}.${format}`;
