import {inject, injectable} from "inversify";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {type IRankingApiProvider, IRankingsPayload} from "data/providers/api/ranking.api.provider";
import {Bindings} from "data/constants/bindings";

export interface IRankingPrediction {
	id: number;
	squadId: number;
	tournamentId: number;
	margin: number;
	isAuto: boolean;
	isCorrect: boolean | null;
}

export interface IRanking {
	avatarVersion: number;
	userId: number;
	userName: string;
	firstName: string;
	lastName?: string;
	leagueId: number | null;
	rank: number | null;
	points: number | null;
	overallRank: number;
	overallPoints: number;
	margin: number | null;
	overallMargin: number | null;
	predictions?: IRankingPrediction[];
	lastRoundData?: {
		rank: number;
		overallRank: number;
	};
	averagePoints: number | null;
}

export interface IRankingsStore {
	get nextPage(): boolean;
	get user(): IRanking | null;
	get list(): IRanking[];

	fetchRankings: (params: IRankingsPayload) => Promise<void>;
	loadMoreRankings: (params: IRankingsPayload) => Promise<void>;
	clearStore: () => void;
}

@injectable()
export class RankingsStore implements IRankingsStore {
	@observable private _list: IRanking[] = [];
	@observable private _user: IRanking | null = null;
	@observable private _nextPage: boolean = false;
	private _page = 0;

	get nextPage(): boolean {
		return this._nextPage;
	}

	get user() {
		return this._user;
	}

	get list() {
		return this._list;
	}

	constructor(@inject(Bindings.RankingApiProvider) private _rankingsApi: IRankingApiProvider) {
		makeAutoObservable(this);
	}

	fetchRankings = async (params: IRankingsPayload) => {
		this.clearStore();
		const response = await this._rankingsApi.overall(params);

		runInAction(() => {
			this._list = response.data.success.rankings;
			this._user = response.data.success.user;
			this._nextPage = response.data.success.nextPage;
			this._page = 1;
		});
	};

	loadMoreRankings = async (params: IRankingsPayload) => {
		const page = this._page + 1;

		const response = await this._rankingsApi.overall({
			...params,
			page,
		});

		runInAction(() => {
			this._list = this._list.concat(response.data.success.rankings);
			this._user = response.data.success.user;
			this._nextPage = response.data.success.nextPage;
			this._page = page;
		});
	};

	clearStore = () => {
		this._list = [];
		this._user = null;
		this._nextPage = false;
		this._page = 0;
	};
}
