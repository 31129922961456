import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";
import {retryFailLoad} from "data/utils";
import {SecretLayout} from "views/components/layouts/secret_layout/secret_layout.component";

const Secret = lazy(retryFailLoad(() => import("views/pages/secret/secret.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));

export const SecretGateRoutes: React.FC = () => (
	<Routes>
		<Route element={<SecretLayout />}>
			<Route path="help/*" element={<Help />} />
			<Route path="*" element={<Secret />} />
		</Route>
	</Routes>
);

export default SecretGateRoutes;
