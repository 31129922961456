import {AxiosError} from "axios";
import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {type IPredictionApiProvider} from "data/providers/api/prediction.api.provider";
import {type IRoundsStore} from "data/stores/rounds/rounds.store";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {AutoPickType} from "data/enums";

export interface IPrediction {
	id: number | null;
	squadId: number | null;
	tournamentId: number;
	margin?: number;
	isCorrect: boolean | null;
	isAuto: boolean;
}

export interface IPredictionsStore {
	get predictions(): IPrediction[];
	get hasPredictions(): boolean;
	get hasAllPredictions(): boolean;
	get allPredictionWereSaved(): boolean;

	fetchPredictions: (roundId?: number) => Promise<boolean>;
	setPredictionSquad: (tournamentId: number, squadId: number) => Promise<void>;
	getPredictionByTournamentId: (tournamentId?: number | null) => IPrediction | undefined;
	setPredictionMargin: (tournamentId: number, margin: number) => Promise<void>;
	autoPick: (type: AutoPickType) => Promise<void>;
	updateAllPredictionWereSaved: (roundId: number, predictions: IPrediction[]) => void;
	clearStore: () => void;
}

@injectable()
export class PredictionsStore implements IPredictionsStore {
	@observable private _predictions: IPrediction[] = [];
	@observable private _allPredictionWereSaved: boolean = false;
	@observable private _abortController?: AbortController;

	/**
	 * Only predictions with a squad id are returned.
	 * To avoid cause when the prediction has a margin, but there is no squad id yet
	 */
	get predictions() {
		return this._predictions.filter(({squadId}) => squadId);
	}

	get hasPredictions() {
		return Boolean(this.predictions.length);
	}

	get hasAllPredictions() {
		const tournaments = this._roundsStore.selectedRound?.tournaments || [];
		return tournaments.every(({id}) => this.getPredictionByTournamentId(id)?.squadId);
	}

	get allPredictionWereSaved() {
		return this._allPredictionWereSaved;
	}

	constructor(
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.PredictionApiProvider) private _predictionApi: IPredictionApiProvider,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	updateAllPredictionWereSaved = (roundId: number, predictions: IPrediction[]) => {
		const tournaments = this._roundsStore.getRoundById(roundId)?.tournaments || [];
		this._allPredictionWereSaved = predictions.length === tournaments.length;
	};

	fetchPredictions = async (roundId: number = 1) => {
		try {
			if (this._abortController) {
				this._abortController.abort();
			}

			this._abortController = new AbortController();

			const {data} = await this._predictionApi.get({
				roundId,
				signal: this._abortController.signal,
			});

			runInAction(() => {
				const predictions = data.success.predictions;
				this._predictions = predictions;
				this.updateAllPredictionWereSaved(roundId, predictions);
			});

			return true;
		} catch (e) {
			if ((e as AxiosError).name !== "CanceledError") {
				throw e;
			}

			return false;
		}
	};

	clearStore = () => {
		this._predictions = [];
	};

	getPredictionByTournamentId = (tournamentId?: number | null) => {
		return this._predictions.find((prediction) => prediction.tournamentId === tournamentId);
	};

	private get predictionsForSave() {
		return this._predictions
			.map(({squadId, margin, tournamentId}) => ({
				squadId,
				margin,
				tournamentId,
			}))
			.filter(({squadId}) => squadId);
	}

	private addPrediction = (prediction: IPrediction) => {
		this._predictions = [...this._predictions, prediction];
	};

	private savePredictions = async () => {
		const roundId = this._roundsStore.selectedRound?.id;

		if (roundId) {
			await this._predictionApi.save({predictions: this.predictionsForSave, roundId});
		}
	};

	setPredictionSquad = async (tournamentId: number, squadId: number) => {
		const prediction = this.getPredictionByTournamentId(tournamentId);

		if (!prediction) {
			this.addPrediction({
				id: null,
				tournamentId,
				squadId,
				isCorrect: null,
				isAuto: false,
			});
		} else {
			prediction.squadId = squadId;
		}

		await this.savePredictions();
	};

	setPredictionMargin = async (tournamentId: number, margin: number) => {
		const prediction = this.getPredictionByTournamentId(tournamentId);

		if (!prediction) {
			this.addPrediction({
				id: null,
				tournamentId,
				squadId: null,
				isCorrect: null,
				margin,
				isAuto: false,
			});
		} else {
			prediction.margin = margin;
		}

		if (prediction?.squadId) {
			await this.savePredictions();
		}
	};

	autoPick = async (type: AutoPickType) => {
		const roundId = this._roundsStore.selectedRound?.id;

		if (roundId) {
			const {data} = await this._predictionApi.autoPick({
				predictions: [],
				roundId,
				autopickType: type,
			});

			runInAction(() => {
				this._predictions = data.success.predictions;
				this.updateAllPredictionWereSaved(roundId, data.success.predictions);
			});
		}
	};
}
