import {AxiosError} from "axios";
import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {Bindings} from "data/constants/bindings";
import {AutoPickType, ModalType, RequestState} from "data/enums";
import {type IPredictionsStore} from "data/stores/predictions/predictions.store";
import {extractErrorMessage} from "data/utils";
import {IApiResponse} from "data/services/http";
import {IAutoPick} from "data/types/game";
import {type ISportsbetStore, SportsbetType} from "data/stores/sportsbet/sportsbet.store";
import {type IAutoPickStore} from "data/stores/auto_pick/auto_pick.store";

export interface IModalAutoPickController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get isDisabled(): boolean;
	get autoPickList(): IAutoPick[];

	isSelected: (type: AutoPickType) => boolean;
	close: () => void;
	selectHandler: (type: AutoPickType) => void;
	submitHandler: () => void;
}

@injectable()
export class ModalAutoPickController implements IModalAutoPickController {
	@observable _autoPickType: AutoPickType | null = null;
	@observable _requestState = RequestState.IDLE;

	get isDisabled() {
		return !this._autoPickType || this._requestState === RequestState.PENDING;
	}

	get isOpen() {
		return this._modalsStore.modal === ModalType.AUTO_PICK;
	}

	get autoPickList() {
		const isShowSponsor = this._sportsbetStore.getIsShow(SportsbetType.AutoPick);

		return this._autoPickStore.getAutoPickList(this._autoPickStore.autoPickList, isShowSponsor);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.PredictionsStore) private _predictionsStore: IPredictionsStore,
		@inject(Bindings.SportsbetStore) private _sportsbetStore: ISportsbetStore,
		@inject(Bindings.AutoPickStore) private _autoPickStore: IAutoPickStore
	) {
		makeAutoObservable(this);
	}

	isSelected = (type: AutoPickType) => {
		return type === this._autoPickType;
	};

	close = () => {
		this._modalsStore.hideModal();
		this._autoPickType = null;
	};

	selectHandler = (type: AutoPickType) => {
		this._autoPickType = type;
	};

	submitHandler = async () => {
		try {
			this._requestState = RequestState.PENDING;
			await this._predictionsStore.autoPick(this._autoPickType!);
			this._requestState = RequestState.SUCCESS;
			this.close();

			if (this._predictionsStore.hasAllPredictions) {
				this._modalsStore.showModal(ModalType.PREDICTIONS_SAVED);
			}
		} catch (error) {
			this._requestState = RequestState.ERROR;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error as AxiosError<IApiResponse>),
			});
		}
	};
}
