import {AxiosError} from "axios";
import {useNavigate} from "react-router";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {type IModalsStore} from "data/stores/modals/modals.store";
import {Bindings} from "data/constants/bindings";
import {ModalType, RequestState} from "data/enums";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

export interface IModalJoinLeagueInviteController extends ViewController<IProps> {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get leagueCode(): string | undefined;
	get league(): ILeague | null;
	get isDisabled(): boolean;
	get isJoined(): boolean;

	close: () => void;
	joinHandler: () => void;
	viewLadderHandler: () => void;
}

@injectable()
export class ModalJoinLeagueInviteController implements IModalJoinLeagueInviteController {
	@observable private _disposer?: ReturnType<typeof reaction>;
	@observable private _requestState = RequestState.IDLE;
	@observable private _joinRequestState = RequestState.IDLE;
	@observable private _league: ILeague | null = null;
	@observable private _navigate!: ReturnType<typeof useNavigate>;

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.JOIN_LEAGUE_INVITE;
	}

	get leagueCode() {
		return this._modalsStore.modalContent?.leagueCode;
	}

	get league() {
		return this._league;
	}

	get isDisabled() {
		return this._joinRequestState === RequestState.PENDING;
	}

	get isJoined() {
		return Boolean(this._league?.isJoined);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	@action private showErrorModal = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	init(param: IProps) {
		this._navigate = param.navigate;

		this._disposer = reaction(
			() => this._modalsStore.modalContent?.leagueCode,
			(code) => {
				if (code) {
					this._requestState = RequestState.PENDING;

					this._leaguesStore
						.fetchLeagueByCode({code})
						.then((league) => {
							runInAction(() => {
								this._league = league;
								this._requestState = RequestState.SUCCESS;
							});
						})
						.catch((err) => {
							runInAction(() => {
								this._requestState = RequestState.ERROR;
								this.showErrorModal(err as AxiosError<IApiResponse>);
							});
						});
				}
			}
		);
	}

	dispose() {
		this._disposer?.();
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	viewLadderHandler = () => {
		const id = this._league?.id;
		if (id) {
			this.close();
			this._navigate(`/comp/${id}/ladder`);
		}
	};

	private successJoinHandler = () => {
		const id = this._league?.id;

		if (id) {
			this._joinRequestState = RequestState.ERROR;
			this.close();
			this._navigate(`/comp/${id}/ladder`);
		}
	};

	joinHandler = () => {
		if (!this._league) {
			return;
		}

		const code = this._league.code!;

		this._joinRequestState = RequestState.PENDING;

		this._leaguesStore
			.joinToLeague({code})
			.then(this.successJoinHandler)
			.catch((err) => {
				runInAction(() => {
					this._joinRequestState = RequestState.ERROR;
					this.showErrorModal(err as AxiosError<IApiResponse>);
				});
			});
	};
}
