import type {AxiosResponse} from "axios";
import {inject, injectable} from "inversify";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {Bindings} from "data/constants/bindings";
import {IPrediction} from "data/stores/predictions/predictions.store";
import {AutoPickType} from "data/enums";

export interface IPredictionResponse {
	predictions: IPrediction[];
}

export interface IGetPredictionPayload {
	roundId: number;
	signal?: AbortSignal;
}

export interface ISavePredictionPayload {
	predictions: Omit<IPrediction, "id" | "isCorrect" | "isAuto">[];
	roundId: number;
}

export interface IAutoPickPayload {
	predictions: Omit<IPrediction, "id" | "isCorrect" | "isAuto">[];
	roundId: number;
	autopickType: AutoPickType;
}

type TPredictionResponse = IApiResponse<IPredictionResponse>;

export interface IPredictionApiProvider {
	get: (payload: IGetPredictionPayload) => Promise<AxiosResponse<TPredictionResponse>>;
	save: (payload: ISavePredictionPayload) => Promise<AxiosResponse<TPredictionResponse>>;
	autoPick: (payload: IAutoPickPayload) => Promise<AxiosResponse<TPredictionResponse>>;
}

@injectable()
export class PredictionApiProvider implements IPredictionApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	get = ({signal, roundId}: IGetPredictionPayload) =>
		this._http.get<TPredictionResponse>(`tipping/predictions/list/${roundId}`, undefined, {
			signal,
		});

	save = (payload: ISavePredictionPayload) =>
		this._http.post<TPredictionResponse>(`tipping/predictions/save`, payload);

	autoPick = (payload: IAutoPickPayload) =>
		this._http.post<TPredictionResponse>("tipping/predictions/autopick", payload);
}
