import React from "react";
import {isNumber} from "lodash";
import styled from "@emotion/styled";
import {MatchStatus} from "data/enums";
import {ITournamentData} from "data/stores/tournament/tournament.store";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {useIsMobile} from "data/hooks/useIsMobile";
import {Exist} from "views/components/exist/exist.component";
import {RoundProgress} from "views/components/round_progress/round_progress";
import {ILocalizationController} from "views/controllers/localization/localization.controller";

export const InfoSection = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	min-width: 100px;
	background: #fff;
	border: none;

	&.clickable {
		cursor: pointer;

		&.streak:hover .content svg {
			rect {
				fill: #00dcba;
			}

			path {
				fill: black;
			}
		}

		&.tipping:hover .content {
			background: #00dcba;
			color: #000;
		}

		&.tipping:focus .content {
			background: #64ff32;
			color: #000;
		}
	}
`;

export const InfoText = styled.p`
	color: ${({theme}) => theme.palette.tipsCard.color};
	font-size: 12px;
	font-weight: 700;
	line-height: 10px;
	min-width: 32px;
	text-align: right;

	&:last-child {
		text-align: left;
	}

	@media (min-width: 769px) {
		font-size: 16px;
		min-width: 42px;

		&.${MatchStatus.Playing} {
			color: ${({theme}) => theme.palette.tipsCard.liveBg};
		}
	}
`;

export const Live = styled.div`
	padding: 6px;
	border-radius: 4px;
	background: ${({theme}) => theme.palette.tipsCard.liveBg};
	color: ${({theme}) => theme.palette.tipsCard.liveColor};
	font-weight: 500;
	letter-spacing: 1.25px;
	font-size: 10px;
	line-height: 8px;
	margin: 0 4px;

	@media (min-width: 769px) {
		font-size: 14px;
		line-height: 11px;
		margin: 0 16px;
	}
`;

export const Complete = styled.div`
	width: 64px;
`;

export const getValue = (value: number | null) => (isNumber(value) ? value : "-");

export const getPercents = (awaySelected: number, homeSelected: number) => {
	if (awaySelected === 0 && homeSelected === 0) {
		return 50;
	}

	return awaySelected;
};

export interface ICardPredictorStats {
	clickHandler?: () => void;
	isScheduled: boolean;
	hasPrediction?: boolean;
	isPlaying: boolean;
	isComplete: boolean;
	homeSquad: ITournamentData["homeSquad"];
	awaySquad: ITournamentData["awaySquad"];
	homeScore: number | null;
	awayScore: number | null;
}

export const CardPredictorStats: React.FC<ICardPredictorStats> = ({
	clickHandler,
	homeSquad,
	awaySquad,
	isScheduled,
	isPlaying,
	isComplete,
	homeScore,
	awayScore,
}) => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	const {isMobile} = useIsMobile();
	const progressWidth = isMobile ? 40 : 55;
	const strokeWidth = isMobile ? 4 : 5;
	const className = clickHandler && isScheduled ? "clickable" : "";

	return (
		<InfoSection onClick={clickHandler} className={`stats-section tipping ${className}`}>
			<Exist when={isScheduled}>
				<InfoText>{homeSquad.selected}%</InfoText>
				<RoundProgress
					width={progressWidth}
					strokeWidth={strokeWidth}
					awayColor={awaySquad.color}
					homeColor={homeSquad.color}
					percents={getPercents(awaySquad.selected, homeSquad.selected)}
					text={i18n.t("tips.progress.stats", "STATS")}
				/>
				<InfoText>{awaySquad.selected}%</InfoText>
			</Exist>

			<Exist when={isPlaying}>
				<InfoText className={MatchStatus.Playing}>{getValue(homeScore)}</InfoText>

				<Live>{i18n.t("tips.card.live", "Live")}</Live>

				<InfoText className={MatchStatus.Playing}>{getValue(awayScore)}</InfoText>
			</Exist>

			<Exist when={isComplete}>
				<InfoText>{getValue(homeScore)}</InfoText>

				<Complete />

				<InfoText>{getValue(awayScore)}</InfoText>
			</Exist>
		</InfoSection>
	);
};
