import React from "react";
import {observer} from "mobx-react";
import styled from "@emotion/styled";
import {css} from "@emotion/react";
import {NavLink} from "react-router-dom";
import {useLocation} from "react-router";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {useIsMobile} from "data/hooks/useIsMobile";
import {ISubNavItem} from "data/types/navigation";
import {ButtonPure} from "views/components/buttons/buttons.component";
import {Exist} from "views/components/exist/exist.component";
import {ISubNavController} from "./sub_nav.controller";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	max-width: 1280px;
	margin: 0 auto;
	width: 100%;
	overflow: auto;
	height: 48px;

	/* Hide scrollbar for Chrome, Safari and Opera */

	&::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
`;

const ItemStyle = css`
	min-width: 90px;
	font-size: 14px;
	font-weight: 400;
	height: 100%;
	display: flex;
	justify-content: center;

	span {
		white-space: nowrap;
		display: flex;
		height: 45px;
		align-items: center;
		justify-content: center;
		min-width: 80px;

		@media (min-width: 1200px) {
			min-width: 96px;
		}
	}

	@media (min-width: 1200px) {
		min-width: 190px;
	}
`;

const StyledNavLink = styled(NavLink)`
	${ItemStyle};
	color: ${({theme}) => theme.palette.header.subNavLinkColor};

	&.active span {
		box-shadow: 0 3px 0px ${({theme}) => theme.palette.header.subNavLinkActiveBorderColor};
		font-weight: 500;
		color: ${({theme}) => theme.palette.header.subNavLinkActiveColor};
	}
`;

const TutorialButton = styled(ButtonPure)`
	${ItemStyle};
	color: ${({theme}) => theme.palette.header.subNavLinkColor};
`;

interface ISubNav {
	items: ISubNavItem[];
}

export const SubNav: React.FC<ISubNav> = observer(({items}) => {
	const {i18n, openTutorialHandler, isShowTutorial, getLinkClassName} =
		useViewController<ISubNavController>(Bindings.SubNavController, {
			location: useLocation(),
		});
	const {isMobile} = useIsMobile();

	return (
		<Wrapper>
			{items.map((item) => {
				if (item.isDesktopOnly && isMobile) {
					return null;
				}

				return (
					<StyledNavLink
						key={item.locoKey}
						to={item.path}
						className={getLinkClassName(item)}>
						<span>{i18n.t(item.locoKey, item.defaultValue)}</span>
					</StyledNavLink>
				);
			})}

			<Exist when={isShowTutorial}>
				<TutorialButton onClick={openTutorialHandler}>
					<span>{i18n.t("navigation.tutorial.copy", "Tutorial")}</span>
				</TutorialButton>
			</Exist>
		</Wrapper>
	);
});
